<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">확인</v-btn>

      <!-- <v-btn
        v-if="index > -1"
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">삭제</v-btn> -->

      <!-- <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn> -->

      <v-btn
        slot="star-area"
        icon
      >
        <v-icon
          v-if="!form.isStar"
          color="grey lighten-1"
        >
          mdi-star-outline
        </v-icon>
        <v-icon
          v-else
          color="yellow"
        >
          mdi-star
        </v-icon>
      </v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <!-- <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto py-2 px-3"
        :max-height="options.height"
      >
        <v-card-text> -->

      <!-- 상단 툴바를 고정시키지 않으려면 아래 주석을 푼다. 하단의 태그 끝에도 주석을 풀어야 한다. -->
      <v-card-text
        slot="content"
        class="pb-0"
      >
        <v-container fluid class="pb-0"><!-- 여기까지 적용 -->

          <v-row no-gutters>
            <v-col cols="12" xs="12">
              <v-alert
                outlined
                border="left"
                type="warning"
                icon="mdi-alert"
                dense
                class="text-subtitle-1"
              >
                수정은 해당 사건에서 가능합니다
              </v-alert>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="form.teamName"
                label="담당부서"
                class="mr-2"
                readonly
              >
                <template v-slot:prepend>
                  <v-icon
                    :color="form.teamColor"
                  >mdi-account-group</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="form.date1"
                label="일자"
                prepend-icon="event"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="form.str3"
                label="업무자"
                maxlength="50"
                counter="50"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="4">
              <v-text-field
                v-model.trim="form.str4"
                label="*시간당 업무보수"
                suffix="원"
                maxlength="8"
                style="width: 150px"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="3"  class="text-center">
              <v-text-field
                v-model="form.str5"
                label="시간"
                prepend-icon="mdi-clock-outline"
                readonly
                class="mx-2"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="1" class="text-left">
              <v-btn fab text small dark color="primary"
                class="mt-3"
              >
                <v-icon>mdi-calculator</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" xs="12" sm="4">
              <v-text-field
                v-model.trim="form.str2"
                label="*시간보수"
                background-color="grey lighten-3"
                maxlength="20"
                suffix="원"
                class="ml-3"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" class="pt-2">
              <v-text-field
                v-model="form.rInfo.name"
                label="부담자"
                prepend-icon="mdi-account-circle"
                readonly
                class="text-input-font-color__primary mr-2"
              >
                <template v-slot:prepend>
                  <v-icon color="primary">mdi-account-circle</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-textarea
                v-model="form.str1"
                outlined
                label="내용"
                placeholder="입력하세요"
                no-resize
                rows="5"
                counter="200"
                class="mt-5"
                readonly
              ></v-textarea>
            </v-col>
            <v-col cols="12" xs="12">
              <v-alert
                border="left"
                outlined
                type="info"
                dense
                class="text-body-2"
              >
                {{ viewParentInfo }}
                <v-tooltip color="info" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      small color="primary lighten-2"
                      @click="gotoDetail"
                      class="ml-1"
                    >mdi-launch</v-icon>
                  </template>
                  <span>바로가기</span>
                </v-tooltip>
              </v-alert>
            </v-col>
            <!-- <v-col cols="12" v-show="index > -1" class="py-0">
              <v-card
                tile
                elevation="0"
                class="ma-0 pa-0"
              >
                <v-card-actions class="ma-0 pa-0">
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>print</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>get_app</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>phone_iphone</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>local_post_office</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <span class="text-caption mr-2">{{ viewWname }}</span><span class="text-caption">{{ viewUpdatedAt }}</span>
                </v-card-actions>
              </v-card>
            </v-col> -->
            <v-col cols="12">
              <div class="mb-8"></div>
            </v-col>

          </v-row>

        <!-- 상단 타이틀 고정을 풀때 사용 -->
        </v-container>
      </v-card-text>

        <!-- 상단 타이틀 고정시 사용 -->
        <!-- </v-card-text>
      </v-card> -->

    </baseModal>

  </v-dialog>
</template>

<script>
// import ko from 'vee-validate/dist/locale/ko'
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

// 구분: filters
// import numberFormat from '@/filters/numberFormat' // number-format

export default {
  components: {
    baseModal
  },

  data: () => ({
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 770,
      height: 750,
      zIndex: 200
    },
    // 구분: Form 관련
    form: {
      isStar: 0,
      teamName: '',
      teamColor: '',
      clientId: 0,
      pId: 0,
      pType: 0,
      pInfo: '',
      rInfo: '',
      gubun1: '',
      status: 1,
      date1: '',
      str1: '',
      str2: '0',
      str3: '',
      str4: '0',
      str5: ''
    },
    // 구분: 바로가기에 보여줄 부모 정보
    viewParentInfo: ''
  }),

  mounted () {
  },

  watch: {
  },

  computed: {
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async initDialog () {
      this.form.isStar = 0
      this.form.teamName = ''
      this.form.teamColor = ''
      this.form.clientId = 0
      this.form.pId = 0
      this.form.pType = 0
      this.form.pInfo = '' // 정보위치(부모인 인명부의 정보 중 보여줄 정보 이름)
      this.form.rInfo = '' // 타임차지부담자
      this.form.status = 1
      this.form.date1 = '' // 업무일
      this.form.str1 = '' // 내용
      this.form.str2 = '0' // 보수(시간당 업무보수 x 시간)
      this.form.str3 = '' // 업무자
      this.form.str4 = '0' // 시간당 업무보수
      this.form.str5 = '' // 시간
    },
    async open (title, options, obj = {}) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        await this.initDialog()
        // console.log(obj)

        this.form.teamName = obj.teamName
        this.form.teamColor = obj.teamColor
        this.form.clientId = obj.clientId
        this.form.isStar = obj.isStar
        this.form.pId = obj.pId
        this.form.pType = obj.pType
        this.form.pInfo = obj.pInfo
        this.form.rInfo = JSON.parse(obj.rInfo) // 중요:[2021.8.23] rInfo 파싱
        this.form.date1 = obj.date1.substr(0, 10)
        this.form.str1 = obj.str1
        this.form.str2 = obj.str2
        this.form.str3 = obj.str3
        this.form.str4 = obj.str4
        this.form.str5 = obj.str5

        // !! 부모 정보
        if (this.form.pType === 1) {
          // 소송
          this.viewParentInfo = `${obj.pInfo.courtName} ${obj.pInfo.caseNum}${obj.pInfo.manageNum ? `/${obj.pInfo.manageNum}` : ''}`
        } else {
          // 자문
          this.viewParentInfo = `${obj.pInfo.manageNum}`
        }

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 확인
    async agree () {
      try {
        //
        await this.initDialog()
        this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 소송/자문 상세페이지 바로가기
    gotoDetail () {
      this.dialog = false
      if (this.form.pType === 1) { // 소송
        // !! 현재 경로가 case 디렉토리가 아닌 경우만 case/ 로 이동시킨다
        if (!(this.$router.history.current.path.indexOf('case') !== -1)) {
          this.$router.push(`/case/${this.form.pId}`)
        }
      } else { // 자문일때
        // !! 현재 경로가 advice 디렉토리가 아닌 경우만 advice/ 로 이동시킨다
        if (!(this.$router.history.current.path.indexOf('advice') !== -1)) {
          this.$router.push(`/advice/${this.form.pId}`)
        }
      }
    }
  }
}
</script>

<style>
.text-input-font-color__primary .v-text-field__slot input {
  color: #1976D2 !important;
}
</style>
