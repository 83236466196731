<template>
  <v-container
    fluid
    class="ma-0 pa-0"
  >
    <v-row no-gutters>
      <v-col cols="2">
        <div class="py-2 px-1">
          <v-menu
            ref="dateMenu"
            v-model="dateMenu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model.trim="date"
                label=""
                dense
                hide-details
                filled
                rounded
                readonly
                v-bind="attrs"
                v-on="on"
                @blur="blurDate"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              type="month"
              no-title
              scrollable
              locale="ko"
              @change="saveDate"
            >
            </v-date-picker>
          </v-menu>
        </div>
      </v-col>
      <v-col cols="8">
        <v-row no-gutters>
          <v-col cols="12" sm="4">
            <div class="py-2 px-1">
              <v-text-field
                ref="refAmt1"
                v-model="amt1"
                maxlength="12"
                @keypress="preventComma"
                dense
                hide-details
                filled
                rounded
                @blur="blurAmt1"
              ></v-text-field>
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <div class="py-2 px-1">
              <v-text-field
                ref="refAmt2"
                v-model="amt2"
                maxlength="12"
                @keypress="preventComma"
                dense
                hide-details
                filled
                rounded
                @blur="blurAmt2"
              ></v-text-field>
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <div class="py-2 px-1">
              <v-text-field
                ref="refAmt3"
                v-model="amt3"
                maxlength="12"
                @keypress="preventComma"
                dense
                hide-details
                filled
                rounded
                @blur="blurAmt3"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2">
        <div class="py-2 px-1 text-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon text color="primary"
                @click.stop="regRow"
              >
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </template>
            <span>등록</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon text color="error"
                @click.stop="rmRow"
              >
                <v-icon>mdi-minus-circle</v-icon>
              </v-btn>
            </template>
            <span>삭제</span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @: filters
import numberFormat from '@/filters/numberFormat' // number-format
import numberFormatR from '@/filters/numberFormatR' // reverse number-format
import preventComma from '@/filters/preventComma' // keypress event 에서 쉼표막기
import strToNumber from '@/filters/strToNumber'
import strDateFormat from '@/filters/strDateFormat'

export default {
  components: {
  },

  props: [
    'amts',
    'order'
  ],

  data: () => ({
    date: '',
    dateMenu: false,
    amt1: '0',
    amt2: '0',
    amt3: '0'
  }),

  created () {
    this.date = this.amts.date
    this.amt1 = this.amts.amt1
    this.amt2 = this.amts.amt2
    this.amt3 = this.amts.amt3
  },

  watch: {
    amt1: {
      handler: function (val, oldVal) {
        if (!val) {
          this.amt1 = '0'
          this.$refs.refAmt1.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.amt1 = val.replace(/(^0+)/, '')
          }
          this.amt1 = numberFormatR(this.amt1)
          this.amt1 = numberFormat(this.amt1)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.amt1)) {
            this.amt1 = oldVal
            this.$refs.refAmt1.lazyValue = oldVal
          }
        }
      }
    },
    amt2: {
      handler: function (val, oldVal) {
        if (!val) {
          this.amt2 = '0'
          this.$refs.refAmt2.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.amt2 = val.replace(/(^0+)/, '')
          }
          this.amt2 = numberFormatR(this.amt2)
          this.amt2 = numberFormat(this.amt2)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.amt2)) {
            this.amt2 = oldVal
            this.$refs.refAmt2.lazyValue = oldVal
          }
        }
      }
    },
    amt3: {
      handler: function (val, oldVal) {
        if (!val) {
          this.amt3 = '0'
          this.$refs.refAmt3.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.amt3 = val.replace(/(^0+)/, '')
          }
          this.amt3 = numberFormatR(this.amt3)
          this.amt3 = numberFormat(this.amt3)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.amt3)) {
            this.amt3 = oldVal
            this.$refs.refAmt3.lazyValue = oldVal
          }
        }
      }
    }
  },

  methods: {
    strDateFormat,
    numberFormat,
    numberFormatR,
    preventComma,
    strToNumber,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 부모의 값까지 같이 변경시키도록 한다.
    blurDate () {
      this.amts.date = this.date
    },
    blurAmt1 () {
      this.amts.amt1 = strToNumber(this.amt1)
    },
    blurAmt2 () {
      this.amts.amt2 = strToNumber(this.amt2)
    },
    blurAmt3 () {
      this.amts.amt3 = strToNumber(this.amt3)
    },
    // 구분: 일자 셀렉트 선택시 저장 함수
    saveDate (date) {
      this.$refs.dateMenu.save(date)
      this.amts.date = date
      this.date = date
    },
    // 구분: 현재 row 등록 -> 부모에게 토스
    async regRow () {
      try {
        // this.$emit('regRow', this.amts)
        this.$emit('regRow')
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 현재 row 삭제
    async rmRow () {
      try {
        // 부모로부터 넘겨받은 순서를 다시 부모에게 넘겨줘야 한다.
        this.$emit('rmRow', this.order)
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
