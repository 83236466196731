<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">확인</v-btn>

      <!-- <v-btn
        v-if="index > -1"
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">삭제</v-btn> -->

      <!-- <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn> -->

      <v-btn
        slot="star-area"
        icon
      >
        <v-icon
          v-if="!form.isStar"
          color="grey lighten-1"
        >
          mdi-star-outline
        </v-icon>
        <v-icon
          v-else
          color="yellow"
        >
          mdi-star
        </v-icon>
      </v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <!-- <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto py-2 px-3"
        :max-height="options.height"
      >
        <v-card-text> -->

      <!-- 상단 툴바를 고정시키지 않으려면 아래 주석을 푼다. 하단의 태그 끝에도 주석을 풀어야 한다. -->
      <v-card-text
        slot="content"
        class="pb-0"
      >
        <v-container fluid class="pb-0"><!-- 여기까지 적용 -->

          <v-row no-gutters>
            <v-col cols="12" xs="12">
              <v-alert
                outlined
                border="left"
                type="warning"
                icon="mdi-alert"
                dense
                class="text-subtitle-1"
              >
                수정은 해당 사건에서 가능합니다
              </v-alert>
            </v-col>
            <!-- 담당부서 -->
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="form.teamName"
                label="담당부서"
                readonly
                class="mr-2"
              >
                <template v-slot:prepend>
                  <v-icon
                    :color="form.teamColor"
                  >mdi-account-group</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <!-- 위임인 -->
            <v-col cols="12" xs="12">
              <v-text-field
                v-model="form.rInfo.name"
                label="위임인"
                prepend-icon="mdi-account-circle"
                readonly
                class="text-input-font-color__primary"
              >
                <template v-slot:prepend>
                  <v-icon color="primary">mdi-account-circle</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <!-- 계약번호 -->
            <v-col cols="12" xs="12" sm="6">
              <v-row
                no-gutters
                align="center"
                justify="start"
              >
                <div style="width:230px">
                  <v-text-field
                    v-model.trim="form.gubun2"
                    label="계약번호"
                    placeholder="조직내부계약번호"
                    maxlength="30"
                    readonly
                  ></v-text-field>
                </div>
              </v-row>
            </v-col>
            <!-- 수임일 -->
            <v-col cols="12" xs="12" sm="6" class="pl-5">
              <v-text-field
                v-model.trim="form.date1"
                label="수임일"
                prepend-icon="event"
                readonly
              ></v-text-field>
            </v-col>
            <!-- 착수금,상공보수,시간보수,비용청구 -->
            <v-col cols="12" xs="12">
              <v-card
                elevation="0"
                outlined
                tile
              >
                <v-row no-gutters>
                  <v-col cols="12" sm="12">
                    <v-toolbar
                      flat
                      dense
                    >
                      <v-toolbar-title class="text-subtitle-1">
                        계약금액 <span class="primary--text font-weight-bold">{{ numberFormat(form.str3) }}</span> 원
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-toolbar-title class="text-subtitle-1">
                        수금대상 <span class="primary--text font-weight-bold">{{ numberFormat(form.str2) }}</span> 원
                      </v-toolbar-title>
                    </v-toolbar>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" sm="3">
                    <v-card
                      elevation="2"
                      tile color="grey lighten-2"
                      class="pa-1 text-center"
                    >
                      착수금
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-card
                      elevation="2"
                      tile color="grey lighten-2"
                      class="pa-1 text-center"
                    >
                      성공보수
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-card
                      elevation="2"
                      tile color="grey lighten-2"
                      class="pa-1 text-center"
                    >
                      시간보수
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-card
                      elevation="2"
                      tile color="grey lighten-2"
                      class="pa-1 text-center"
                    >
                      비용청구
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                elevation="0"
                outlined
                tile
                min-height="50"
                max-height="240"
                class="overflow-y-auto ma-0 pa-0"
              >
                <v-row no-gutters>
                  <v-col cols="12" sm="3">
                    <div class="py-2 px-1">
                      <v-text-field
                        v-model="form.amts.amt1"
                        maxlength="12"
                        dense
                        hide-details
                        filled
                        rounded
                        readonly
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <div class="py-2 px-1">
                      <v-text-field
                        v-model="form.amts.amt2"
                        maxlength="12"
                        dense
                        hide-details
                        filled
                        rounded
                        readonly
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <div class="py-2 px-1">
                      <v-text-field
                        v-model="form.amts.amt3"
                        maxlength="12"
                        dense
                        hide-details
                        filled
                        rounded
                        readonly
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <div class="py-2 px-1">
                      <v-text-field
                        v-model="form.amts.amt4"
                        maxlength="12"
                        dense
                        hide-details
                        filled
                        rounded
                        readonly
                      ></v-text-field>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <!-- 특약사항 -->
            <v-col cols="12" xs="12">
              <v-textarea
                v-model="form.str1"
                outlined
                label="특약사항"
                placeholder="입력하세요"
                no-resize
                rows="3"
                counter="200"
                class="mt-5"
                readonly
              ></v-textarea>
            </v-col>
            <v-col cols="12" xs="12">
              <v-alert
                border="left"
                outlined
                type="info"
                dense
                class="text-body-2"
              >
                {{ viewParentInfo }}
                <v-tooltip color="info" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      small color="primary lighten-2"
                      @click="gotoDetail"
                      class="ml-1"
                    >mdi-launch</v-icon>
                  </template>
                  <span>바로가기</span>
                </v-tooltip>
              </v-alert>
            </v-col>
            <v-col cols="12">
              <div class="mb-8"></div>
            </v-col>

          </v-row>

        <!-- 상단 타이틀 고정을 풀때 사용 -->
        </v-container>
      </v-card-text>

        <!-- 상단 타이틀 고정시 사용 -->
        <!-- </v-card-text>
      </v-card> -->

    </baseModal>

  </v-dialog>
</template>

<script>
// import ko from 'vee-validate/dist/locale/ko'
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

// 구분: filters
import numberFormat from '@/filters/numberFormat' // number-format
import strDateFormat from '@/filters/strDateFormat'

export default {
  components: {
    baseModal
  },

  data: () => ({
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 770,
      height: 750,
      zIndex: 200
    },
    // 구분: Form 관련
    form: {
      isStar: 0,
      teamName: '',
      teamColor: '',
      pId: 0,
      pType: 1,
      pInfo: {},
      rId: 0,
      rType: 3,
      rInfo: {
        name: ''
      },
      gubun1: '',
      gubun2: '',
      date1: '',
      str1: '',
      str2: '',
      str3: '',
      amts: {
        amt1: '0', amt2: '0', amt3: '0', amt4: '0'
      }
    },
    // 구분: 바로가기에 보여줄 부모 정보
    viewParentInfo: ''
  }),

  mounted () {
  },

  watch: {
  },

  computed: {
  },

  methods: {
    numberFormat,
    strDateFormat,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async initDialog () {
      this.form.isStar = 0
      this.form.teamName = ''
      this.form.teamColor = ''
      this.form.pId = 0
      this.form.pType = 1
      this.form.pInfo = {}
      this.form.rId = 0
      this.form.rType = 3
      this.form.rInfo = {
        name: ''
      }
      this.form.gubun1 = ''
      this.form.gubun2 = ''
      this.form.date1 = ''
      this.form.str1 = ''
      this.form.str2 = ''
      this.form.str3 = ''
      this.form.amts = {
        amt1: '0', amt2: '0', amt3: '0', amt4: '0'
      }
    },
    // !![2021.5.10] 마지막 파라미터로 의뢰인객체가 들어간다
    async open (title, options, obj = {}) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        await this.initDialog()

        // 중요:
        // this.form = { ...obj } // ?? 에러가 왜나지
        this.form.isStar = obj.isStar
        this.form.teamName = obj.teamName
        this.form.teamColor = obj.teamColor
        this.form.pId = obj.pId
        this.form.pType = obj.pType
        this.form.pInfo = obj.pInfo
        this.form.rId = obj.rId
        this.form.rType = obj.rType
        this.form.gubun1 = obj.gubun1
        this.form.gubun2 = obj.gubun2
        this.form.date1 = obj.date1.substr(0, 10)
        this.form.str1 = obj.str1
        this.form.str2 = obj.str2
        this.form.str3 = obj.str3

        // !!
        if (obj.rInfo && typeof obj.rInfo === 'string') {
          this.form.rInfo = JSON.parse(obj.rInfo)
        } else if (typeof obj.rInfo === 'object') {
          this.form.rInfo = obj.rInfo
        }

        const tmpAmts = JSON.parse(obj.txt1)[0]
        this.form.amts.amt1 = numberFormat(tmpAmts.amt1)
        this.form.amts.amt2 = numberFormat(tmpAmts.amt2)
        this.form.amts.amt3 = numberFormat(tmpAmts.amt3)
        this.form.amts.amt4 = numberFormat(tmpAmts.amt4)

        // !! 부모 정보
        if (this.form.pType === 1) {
          // 소송
          this.viewParentInfo = `${obj.pInfo.courtName} ${obj.pInfo.caseNum}${obj.pInfo.manageNum ? `/${obj.pInfo.manageNum}` : ''}`
        } else {
          // 자문
          this.viewParentInfo = `${obj.pInfo.manageNum}`
        }

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 확인
    async agree () {
      try {
        //
        await this.initDialog()
        this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 소송/자문 상세페이지 바로가기
    gotoDetail () {
      this.dialog = false
      if (this.form.pType === 1) { // 소송
        // !! 현재 경로가 case 디렉토리가 아닌 경우만 case/ 로 이동시킨다
        if (!(this.$router.history.current.path.indexOf('case') !== -1)) {
          this.$router.push(`/case/${this.form.pId}`)
        }
      } else { // 자문일때
        // !! 현재 경로가 advice 디렉토리가 아닌 경우만 advice/ 로 이동시킨다
        if (!(this.$router.history.current.path.indexOf('advice') !== -1)) {
          this.$router.push(`/advice/${this.form.pId}`)
        }
      }
    }
  }
}
</script>

<style>
.text-input-font-color__primary .v-text-field__slot input {
  color: #1976D2 !important;
}
</style>
