<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      :outlined="expand"
      elevation="0"
    >
      <v-alert
        dense
        color="grey lighten-5"
        class="text-body-2 ma-0 px-2 py-1"
        @click.stop="expand = !expand"
        style="cursor: pointer;"
      >
        <v-icon :color="hover ? 'orange' : 'grey'" class="mr-1">
          {{ expand ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
        <span :class="hover ? 'blue--text text--darken-3' : 'grey--text text--darken-2'">
          {{ txtData.title }}: {{ cutString(txtData.content, cutStringCount) }}
        </span>
      </v-alert>
      <v-expand-transition>
        <div v-show="expand">
          <v-divider></v-divider>
          <v-card-text
            class="text--center grey--text text--darken-4 pa-2"
            style="font-size: 0.975rem;font-weight: 400;line-height: 1.675rem;"
            v-html="nl2br(txtData.content)"
          ></v-card-text>
        </div>
      </v-expand-transition>
    </v-card>
  </v-hover>
</template>

<script>
import cutString from '@/filters/cutString'
import nl2br from '@/filters/nl2br'

export default {
  props: [ 'txtData' ],

  data: () => ({
    expand: false
  }),

  computed: {
    cutStringCount () { // !! 넘어온 글자수가 있으면 적용 아니면 100자에서 자른다.
      return this.txtData.cutStringCount || 100
    }
  },

  methods: {
    cutString,
    nl2br
  }
}
</script>
