<template>
  <v-container fluid class="pa-0 ma-0">

    <!-- 상단 툴바 -->
    <v-toolbar dense flat>
      <v-btn text small @click="goList">
        <v-icon small>mdi-view-list</v-icon>
        목록
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        text
        small
        color="success"
        @click="editArticle">
        <v-icon small>mdi-pencil</v-icon>
        수정
      </v-btn>
      <v-btn
        text
        small
        color="error"
        @click="deleteArticle">
        <v-icon small>mdi-trash-can-outline</v-icon>
        삭제
      </v-btn>
      <v-btn text small
        class="hidden-xs-only"
        @click.stop="pdfgen"
      >
        <v-icon small>mdi-download</v-icon>
        저장
      </v-btn>
    </v-toolbar>

    <v-divider></v-divider>

    <!-- 컨텐츠 영역 -->
    <v-card
      tile
      :elevation="0"
    >
      <v-row
        no-gutters
      >
        <v-col v-if="!isId" cols="12">
          <v-alert
            prominent
            type="warning"
            class="ma-4 pa-10"
          >
            존재하지 않는 데이터 입니다.<br>삭제되었거나 팀이 변경되었을 수 있습니다.
          </v-alert>
        </v-col>
        <v-col v-else cols="12">
          <v-row
            no-gutters
          >
            <v-col
              cols="12"
            >
              <div
                class="pa-2"
              >
                <v-icon small
                  v-if="article.isStar"
                  class="pb-1"
                  color="amber darken-1"
                >mdi-star</v-icon>
                <span class="mx-1 text-h6 primary--text">
                  <v-icon
                    :color="article.isGomun ? 'purple' : 'primary'"
                    class="mr-1"
                    style="font-size: 22px;"
                  >
                    {{ article.isGomun ? 'mdi-shield-account' : 'mdi-account-circle' }}
                  </v-icon>
                  {{ article.name }}
                  <v-chip
                    v-show="article.isGomun"
                    small
                    label
                    color="grey lighten-2"
                    text-color="purple"
                    class="mb-1 ml-1 px-2"
                    style="font-size: 0.825rem !important;font-weight: 700;letter-spacing: 0.222em;"
                  >
                    고문
                  </v-chip>
                </span>
                <!-- <v-btn
                  v-show="article.isGomun"
                  x-small
                  dark rounded
                  color="purple"
                  depressed
                  class="mb-1 py-3"
                  style="font-size: 0.785rem !important;font-weight: 600;cursor: default;"
                >
                  고문
                </v-btn> -->
              </div>
              <v-divider></v-divider>
            </v-col>

            <!-- 좌측 컨텐츠 시작 --->
            <v-col
              cols="12"
              md="7"
              lg="7"
              xl="7"
            >
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="7"
                  lg="7"
                >

                  <v-row no-gutters class="ma-0 pa-0">
                    <v-col class="ma-0 px-2 pt-2">
                      <span class="text-body-2 grey--text mr-3">유형|구분</span>
                      <span class="text-body-2 primary--text font-weight-bold">{{ article.gubun1 }}</span> |
                      <span class="text-body-2">{{ article.gubun2 }}</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="ma-0 pa-0">
                    <v-col class="ma-0 px-2 pt-1">
                      <span class="text-body-2 grey--text mr-3">국적</span>
                      <span class="text-body-2">{{ article.national }}</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="ma-0 pa-0">
                    <v-col class="ma-0 px-2 pt-1">
                      <span class="text-body-2 grey--text mr-3">주소</span>
                      <span class="text-body-2">{{ article.address }}</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="ma-0 pa-0">
                    <v-col class="ma-0 px-2 pt-1">
                      <span class="text-body-2 grey--text mr-3">M</span>
                      <span class="text-body-2">{{ article.hp }}</span>
                      <!-- <v-icon small
                        v-show="article.hp"
                        class="ml-1"
                        @click="dummy"
                      >mdi-cellphone-iphone</v-icon> -->
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="ma-0 pa-0">
                    <v-col class="ma-0 px-2 pt-1">
                      <span class="text-body-2 grey--text mr-3">T</span>
                      <span class="text-body-2">{{ article.tel }}</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="ma-0 pa-0">
                    <v-col class="ma-0 px-2 pt-1">
                      <span class="text-body-2 grey--text mr-3">F</span>
                      <span class="text-body-2">{{ article.fax }}</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="ma-0 pa-0">
                    <v-col class="ma-0 px-2 py-1">
                      <v-hover v-slot:default="{ hover }">
                        <div>
                          <span class="text-body-2 grey--text mr-3">E</span>
                          <span class="text-body-2">{{ article.email }}</span>
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <v-icon
                                v-show="article.email"
                                v-on="on"
                                class="ml-1"
                                color="primary"
                                :style="hover ? 'font-size: 1.2rem;' : 'font-size: 0.9rem;'"
                                @click="writeEmail(article.email)"
                              >
                                {{ hover ? 'mdi-email' : 'mdi-email-outline' }}
                              </v-icon>
                            </template>
                            <span>메일보내기</span>
                          </v-tooltip>
                        </div>
                      </v-hover>
                    </v-col>
                  </v-row>

                </v-col>
                <v-divider vertical class="ma-0 pa-0"></v-divider>

                <!-- 좌측 상단 > 우측 -->
                <v-col>
                  <v-row no-gutters class="ma-0 pa-1">
                    <v-col class="ma-0 pa-0">
                      <div class="px-1 pt-1">
                        <span class="text-body-2 grey--text text--darken-1 mr-1">담당부서</span>
                        <span :class="`${article.teamColor}`">
                          {{ article.teamName }}
                        </span>
                      </div>
                      <div class="px-1 pt-1">
                        <span class="text-body-2 grey--text text--darken-1 mr-1">등록일</span>
                        <span class="text-body-2">
                          {{ strDateFormat(article.createdAt) }}
                        </span>
                      </div>
                      <div class="px-1 pt-1 pb-3">
                        <span class="text-body-2 grey--text text--darken-1 mr-1">관리그룹</span>
                        <span class="text-body-2">
                          {{ article.mgroupStr }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="12"
                  lg="12"
                >
                  <v-divider></v-divider>
                  <v-row
                    align="center"
                    justify="center"
                    no-gutters
                  >
                    <!-- 좌측 하단 -->
                    <!-- @: 관계인 -->
                    <v-col cols="12" class="my-0 pt-2 pb-0 px-2">
                      <v-row no-gutters>
                        <v-col cols="6">
                          <span class="text-body-2 text-left grey--text text--darken-1 mr-3">관계인</span>
                          <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                            <v-icon
                              small
                              @click="popRelPerson(null)"
                              class="pb-1"
                            >mdi-plus-box-outline</v-icon>
                          </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="4" class="text-right mr-2">
                          <span class="text-body-2">{{ relPersons.length }}</span>
                        </v-col>
                      </v-row>
                      <template v-for="(item, i) in relPersons">
                        <v-row
                          :key="i"
                          no-gutters
                        >
                          <v-col cols="12" class="mb-2">
                            <v-hover v-slot:default="{ hover }">
                              <v-card elevation="0">
                                <v-card-text class="pa-0 text--primary">
                                  <div class="px-0">
                                    <v-icon x-small
                                      v-show="item.isStar"
                                      class="mr-1"
                                      color="amber darken-1"
                                    >mdi-star</v-icon>
                                    <v-icon small left class="mr-1" color="black">mdi-account-circle</v-icon>
                                    <span class="primary--text mr-1" style="font-size: 0.875rem !important;font-weight: 500;">{{ item.str1 }}</span>
                                    <span style="font-size: 0.825rem !important;font-weight: 400;">[{{ item.str2 }}]</span>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-show="hover"
                                          v-on="on"
                                          small
                                          :color="mdiListEditButtonColor"
                                          class="ml-3"
                                          @click="popRelPerson(item)"
                                        >mdi-pencil</v-icon>
                                      </template>
                                      <span>수정</span>
                                    </v-tooltip>
                                  </div>
                                  <div v-show="item.str3 || item.str4 || item.str5 || item.str6" class="px-1">
                                    <span v-show="item.str3" class="text-body-2 grey--text mr-1">M</span>
                                    <span v-show="item.str3" class="text-body-2 mr-5">{{item.str3}}</span>
                                    <!-- <v-icon small
                                      v-show="item.str3"
                                      class="ml-1 mr-5"
                                      @click="dummy"
                                    >mdi-cellphone-iphone</v-icon> -->
                                    <span v-show="item.str4" class="text-body-2 grey--text mr-1">T</span>
                                    <span v-show="item.str4" class="text-body-2 mr-5">{{item.str4}}</span>
                                    <span v-show="item.str5" class="text-body-2 grey--text mr-1">F</span>
                                    <span v-show="item.str5" class="text-body-2 mr-5">{{item.str5}}</span>
                                    <span v-show="item.str6" class="text-body-2 grey--text mr-1">E</span>
                                    <span v-show="item.str6" class="text-body-2">{{item.str6}}</span>
                                    <v-tooltip right>
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-show="item.str6"
                                          v-on="on"
                                          class="ml-1"
                                          color="primary"
                                          :style="hover ? 'font-size: 1.2rem;' : 'font-size: 0.9rem;'"
                                          @click="writeEmail(item.str6)"
                                        >
                                          {{ hover ? 'mdi-email' : 'mdi-email-outline' }}
                                        </v-icon>
                                      </template>
                                      <span>메일보내기</span>
                                    </v-tooltip>
                                  </div>
                                </v-card-text>
                              </v-card>
                            </v-hover>
                          </v-col>
                        </v-row>
                      </template>
                    </v-col>

                    <!-- !![2021.5.7]관계인 하위에 밑줄 -->
                    <v-col cols="12" class="mt-4 mb-0 mx-0 pa-0">
                      <v-divider></v-divider>
                    </v-col>

                    <!-- @: 수금(의뢰인) -->
                    <v-col v-show="t1show" cols="12" class="my-2 pt-3 pb-0 px-2">
                      <v-row no-gutters>
                        <v-col cols="4">
                          <span class="text-body-2 text-left grey--text text--darken-1 mr-3">수금</span>
                          <!-- !!수금계약 추가 아이콘: 수금권한 필요 -->
                          <v-btn
                            v-show="getMyAuth.auth1"
                            icon small
                            :color="mdiPlusBoxOutlineButtonColor"
                            @click="popRelSugum(null)"
                          >
                            <v-icon
                              small class="pb-1"
                            >mdi-plus-box-outline</v-icon>
                          </v-btn>
                          <!-- !!pdf 리스트 아이콘: 수금권한 필요 -->
                          <v-btn
                            v-show="getMyAuth.auth1"
                            icon small
                            :color="mdiPlusBoxOutlineButtonColor"
                            @click.stop="pdfSugumList"
                            :disabled="this.relSugums.length === 0"
                          >
                            <v-icon small class="pb-1">mdi-download</v-icon>
                          </v-btn>
                          <v-tooltip
                            bottom
                            min-width="350"
                          >
                            <template v-slot:activator="{ on }">
                              <v-icon
                                v-show="getMyAuth.auth1"
                                v-on="on" color="primary" class="ml-1 mb-1" small
                              >mdi-help-circle-outline</v-icon>
                            </template>
                            <span>정확한 수금관리를 위해서는 개별사건계약의 등록이 선행되어야 합니다.</span>
                          </v-tooltip>
                        </v-col>
                        <v-col class="text-right mr-2">
                          <!-- 수금대상 차트 합계 -->
                          <span v-show="getMyAuth.auth1" class="text-body-2">
                            ￦{{ numberFormat(sugumChartSum) }}
                          </span>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon
                                v-show="getMyAuth.auth1"
                                v-on="on"
                                small color="primary" class="ml-1 mb-1"
                              >mdi-help-circle-outline</v-icon>
                            </template>
                            <span>수금대상금액 합계입니다.</span>
                          </v-tooltip>
                          <!-- 수금관리현황 차트 합계 -->
                          <span v-show="getMyAuth.auth1" class="text-body-2 ml-6">
                            ￦{{ numberFormat(sugumTotal) }}
                          </span>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon
                                v-show="getMyAuth.auth1"
                                v-on="on"
                                small color="primary" class="ml-1 mb-1"
                              >mdi-help-circle-outline</v-icon>
                            </template>
                            <span>수금에서 관리되고 있는 합계입니다.</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <!-- !![2021.6.28] 차트 영역 -->
                      <v-card
                        v-show="(sugumChartSum > 0 || sugumTotal > 0) && getMyAuth.auth1"
                        outlined
                        class="text-left ma-0 pa-0"
                      >
                        <v-row no-gutters justify="center">
                          <v-col cols="12" sm="6" xs="12">
                            <v-card
                              elevation="0"
                              class="ma-0 py-2 pl-4 pr-0"
                            >
                              <apexchart
                                type="pie"
                                width="250"
                                :options="sugumChartOption1"
                                :series="sugumSeries1"
                              ></apexchart>
                            </v-card>
                          </v-col>
                          <v-col cols="12" sm="6" xs="12">
                            <v-card
                              elevation="0"
                              class="ma-0 py-2 pl-4 pr-0"
                            >
                              <apexchart
                                type="pie"
                                width="250"
                                :options="sugumChartOption2"
                                :series="sugumSeries2"
                              ></apexchart>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card>
                      <v-alert
                        v-show="sugumTotal > 0 && getMyAuth.auth1"
                        type="info" dense text class="text-body-2 my-1"
                      >
                        수금대상금액은 사건관리상태와 무관한 결과입니다.
                      </v-alert>
                      <!-- @: 고문계약 목록 -->
                      <template v-if="!getMyAuth.auth1">
                        <v-alert
                          text dense color="blue-grey" class="text-body-2" icon="mdi-information"
                        >접근권한이 없습니다.</v-alert>
                      </template>
                      <template v-else>
                        <template v-for="(item, i) in relSugums">
                          <v-row
                            :key="i"
                            no-gutters
                          >
                            <v-col cols="12" class="mb-2">
                              <v-card elevation="0">
                                <v-card-text class="px-0 pt-0 pb-1 text--primary">
                                  <v-hover v-slot:default="{ hover }">
                                    <div>
                                      <div class="px-1">
                                        <v-icon small
                                          v-if="item.isStar"
                                          class="mr-1 pb-1"
                                          color="amber darken-1"
                                        >mdi-star</v-icon>
                                        <span style="font-size: 0.825rem !important;font-weight: 450;">{{ strDateFormat3(item.date1) }}</span>
                                        <v-chip
                                          label outlined x-small class="ml-2 mb-1 px-1"
                                          color="blue-grey darken-2"
                                          style="font-size: 0.70rem;font-weight: 600;letter-spacing: 0.08em"
                                        >
                                          {{ item.gubun1 }}
                                        </v-chip>
                                        <span v-show="item.str2" class="ml-2">￦{{ numberFormat(item.str2) }}</span>
                                        <!-- <span v-show="item.gubun2 === '미발행'" class="ml-2">{{ item.gubun2 }}</span> -->
                                        <v-chip
                                          v-show="item.gubun2 === '미발행'"
                                          label outlined x-small class="ml-2 mb-1 px-1"
                                          color="error"
                                          style="font-size: 0.70rem;font-weight: 550;letter-spacing: 0.10em"
                                        >미발행</v-chip>
                                        <span v-show="item.view1" class="ml-2 error--text">{{ item.view1 }}</span>
                                        <span v-show="item.gubun3" class="ml-2 primary--text">#{{ item.gubun3 }}</span>
                                        <v-tooltip bottom>
                                          <template v-slot:activator="{ on }">
                                            <v-icon
                                              v-show="hover"
                                              v-on="on"
                                              small
                                              :color="mdiListEditButtonColor"
                                              class="ml-3"
                                              @click="popRelSugum(item)"
                                            >mdi-pencil</v-icon>
                                          </template>
                                          <span>수정</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                          <template v-slot:activator="{ on }">
                                            <v-icon
                                              v-show="hover"
                                              v-on="on"
                                              small
                                              :color="mdiListEditButtonColor"
                                              class="ml-3"
                                              @click="doCopy(item.copyText)"
                                            >mdi-content-copy</v-icon>
                                          </template>
                                          <span>클립보드복사</span>
                                        </v-tooltip>
                                      </div>
                                    </div>
                                  </v-hover>
                                  <div style="font-size: 0.855rem !important" class="pl-1 grey--text text--darken-2">
                                    {{ item.str1 }}
                                  </div>
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </template>
                      </template>
                    </v-col>

                    <!-- @:!![2021.6.1] 고문계약(의뢰인) -->
                    <v-col v-show="t1show" cols="12" class="my-2 pt-5 pb-0 px-2">
                      <v-row no-gutters>
                        <v-col cols="6">
                          <span class="text-body-2 text-left grey--text text--darken-1 mr-3">고문계약</span>
                          <!-- !!고문계약 추가 아이콘: 계약권한 필요 -->
                          <v-btn
                            v-show="getMyAuth.auth1"
                            icon small
                            :color="mdiPlusBoxOutlineButtonColor"
                            @click.stop="setGomunContract(null)"
                          >
                            <v-icon
                              small class="pb-1"
                            >mdi-plus-box-outline</v-icon>
                          </v-btn>
                          <!-- !!pdf 리스트 아이콘: 계약권한 필요 -->
                          <v-btn
                            v-show="getMyAuth.auth1"
                            icon small
                            :color="mdiPlusBoxOutlineButtonColor"
                            @click.stop="pdfGomunContractList"
                            :disabled="this.relContracts.length === 0"
                          >
                            <v-icon small class="pb-1">mdi-download</v-icon>
                          </v-btn>
                          <v-tooltip
                            bottom
                            min-width="350"
                          >
                            <template v-slot:activator="{ on }">
                              <v-icon
                                v-show="getMyAuth.auth1"
                                v-on="on" color="primary" class="ml-1 mb-1" small
                              >mdi-help-circle-outline</v-icon>
                            </template>
                            <span>고문계약 하위의 자문사건이 포함된 결과입니다.</span>
                          </v-tooltip>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="4" class="text-right mr-2">
                          <span v-show="getMyAuth.auth1" class="text-body-2">
                            ￦{{ numberFormat(contractTotal) }}
                          </span>
                          <v-tooltip
                            bottom
                            min-width="350"
                          >
                            <template v-slot:activator="{ on }">
                              <v-icon
                                v-show="getMyAuth.auth1"
                                v-on="on" color="primary" class="ml-1 mb-1" small
                              >mdi-help-circle-outline</v-icon>
                            </template>
                            <span>사건관리상태에 상관없이 고문계약에 등록한 합계입니다.</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <!-- !![2021.6.2] 차트 영역 -->
                      <v-card
                        v-show="this.relContracts.length > 0 && getMyAuth.auth1"
                        outlined
                        class="text-left ma-0 pa-0"
                      >
                        <v-row no-gutters justify="center">
                          <v-col cols="12" sm="6" xs="12">
                            <v-card
                              elevation="0"
                              class="ma-0 py-2 pl-4 pr-0"
                            >
                              <apexchart
                                type="pie"
                                width="250"
                                :options="contractChartOption1"
                                :series="contractSeries1"
                              ></apexchart>
                            </v-card>
                          </v-col>
                          <v-col cols="12" sm="6" xs="12">
                            <v-card
                              elevation="0"
                              class="ma-0 py-2 pl-4 pr-0"
                            >
                              <apexchart
                                type="pie"
                                width="250"
                                :options="contractChartOption2"
                                :series="contractSeries2"
                              ></apexchart>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card>
                      <v-alert
                        v-show="this.relContracts.length > 0 && getMyAuth.auth1"
                        type="info" dense text class="text-body-2 my-1"
                      >
                        시간보수는 고문계약 하위의 자문사건에서 등록한  타임시트 합산이 아니라 고문계약에 등록된 금액을 의미합니다.
                      </v-alert>
                      <!-- @: 고문계약 목록 -->
                      <template v-if="!getMyAuth.auth1">
                        <v-alert
                          text dense color="blue-grey" class="text-body-2" icon="mdi-information"
                        >접근권한이 없습니다.</v-alert>
                      </template>
                      <template v-else>
                        <template
                          v-for="(item, i) in relContracts"
                        >
                          <v-row
                            :key="i"
                            no-gutters
                          >
                            <v-col cols="12" class="mb-2">
                              <v-card elevation="0">
                                <v-card-text class="px-0 pt-0 pb-2 text--primary">
                                  <v-hover v-slot:default="{ hover }">
                                    <div>
                                      <div class="px-1">
                                        <v-icon small
                                          v-if="item.isStar"
                                          class="mr-1 pb-1"
                                          color="amber darken-1"
                                        >mdi-star</v-icon>
                                        <span style="font-size: 0.825rem !important;font-weight: 450;">{{ strDateFormat3(item.date1) }}</span>
                                        <span v-show="item.gubun2" class="ml-2">{{ item.gubun2 }}</span>
                                        <span class="ml-2">{{ strDateFormat(item.date2) }}~{{ strDateFormat(item.date3) }}</span>
                                        <span class="ml-2">{{ item.subtype2 === 1 ? '월별정산' : '일괄정산' }}</span>
                                        <span class="ml-2">{{ numberFormat(item.str4) }}h</span>
                                        <!-- 사용안함: 수금대상액 뷰안함 <span v-show="item.str2" class="ml-2">￦{{ numberFormat(item.str2) }}</span> -->
                                        <v-tooltip bottom>
                                          <template v-slot:activator="{ on }">
                                            <v-icon
                                              v-show="hover"
                                              v-on="on"
                                              small
                                              :color="mdiListEditButtonColor"
                                              class="ml-3"
                                              @click="setGomunContract(item)"
                                            >mdi-pencil</v-icon>
                                          </template>
                                          <span>수정</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                          <template v-slot:activator="{ on }">
                                            <v-icon
                                              v-show="hover"
                                              v-on="on"
                                              small
                                              :color="mdiListEditButtonColor"
                                              class="ml-3"
                                              @click="doCopy(item.copyText)"
                                            >mdi-content-copy</v-icon>
                                          </template>
                                          <span>클립보드복사</span>
                                        </v-tooltip>
                                      </div>
                                    </div>
                                  </v-hover>
                                  <!-- <div style="font-size: 0.855rem !important" class="pl-1 grey--text text--darken-2">
                                    {{ item.txt2 }}
                                  </div> -->
                                  <!-- @:특약사항 -->
                                  <expand-text-content v-show="item.txt2" :txtData="{ title: '특약사항', content: item.txt2, cutStringCount: 80 }"></expand-text-content>
                                  <!-- @: 하위 고문자문 사건 리스트: 펼침 -->
                                  <expand-advice-list
                                    @parentAddGomun="addGomunAdvice"
                                    :pData="{ cnt: item.adviceCnt, ids: item.txt3 }"
                                    :contract="item"
                                  ></expand-advice-list>
                                  <!-- @: 고문계약 금액등록 리스트: 펼침 item.txt1 을 전달해야 한다. -->
                                  <expand-contract-amount
                                    @parentRefresh="parentRefresh"
                                    :cData="{
                                      gomunId: item.id, clientId: article.id, clientName: article.name, amounts: item.txt1,
                                      str2: item.str2, str3: item.str3, adviceIds: item.txt3, contractNum: item.gubun2
                                    }"
                                  ></expand-contract-amount>
                                  <!-- @: -->
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </template>
                      </template>
                    </v-col>

                    <!-- @:!![2021.6.1] 개별사건계약(의뢰인) -->
                    <v-col v-show="t1show" cols="12" class="my-2 pt-3 pb-0 px-2">
                      <v-row no-gutters>
                        <v-col cols="6">
                          <span class="text-body-2 text-left grey--text text--darken-1 mr-3">개별사건계약</span>
                          <!-- !!pdf 리스트 아이콘: 계약권한 필요 -->
                          <v-btn
                            v-show="getMyAuth.auth1"
                            icon small
                            :color="mdiPlusBoxOutlineButtonColor"
                            @click.stop="pdfCaseContractList"
                            :disabled="this.caseContracts.length === 0"
                          >
                            <v-icon small class="pb-1">mdi-download</v-icon>
                          </v-btn>
                          <v-tooltip
                            bottom
                            min-width="350"
                          >
                            <template v-slot:activator="{ on }">
                              <v-icon v-show="getMyAuth.auth1" v-on="on" color="primary" class="ml-1 mb-1" small>mdi-help-circle-outline</v-icon>
                            </template>
                            <span>고문계약 하위의 자문사건은 포함되지 않습니다.</span>
                          </v-tooltip>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="4" class="text-right mr-2">
                          <span v-show="getMyAuth.auth1" class="text-body-2">
                            ￦{{ numberFormat(caseContractTotal) }}
                          </span>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon v-show="getMyAuth.auth1" v-on="on" color="primary" class="ml-1 mb-1" small>mdi-help-circle-outline</v-icon>
                            </template>
                            <span>사건관리상태에 상관없이 개별사건계약에 등록한 합계입니다.</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <!-- @:[2021.6.21] 차트 영역 -->
                      <v-card
                        v-show="this.caseContracts.length > 0 && getMyAuth.auth1"
                        outlined
                        class="text-left ma-0 pa-0"
                      >
                        <v-row no-gutters justify="center">
                          <v-col cols="12" sm="12" xs="12">
                            <v-card
                              elevation="0"
                              class="ma-0 py-2 pl-4 pr-0"
                            >
                              <apexchart
                                type="pie"
                                width="250"
                                :options="caseConChartOption1"
                                :series="caseConSeries1"
                              ></apexchart>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card>
                      <v-alert
                        v-show="this.caseContracts.length > 0 && getMyAuth.auth1"
                        type="info" dense text class="text-body-2 my-1"
                      >
                        시간보수는 개별사건에서 등록한 타임시트 합산이 아니라 개별사건 수임계약에 등록된 금액을 의미합니다.
                      </v-alert>
                      <!-- @: 개별사건계약 목록 -->
                      <template v-if="!getMyAuth.auth1">
                        <v-alert
                          text dense color="blue-grey" class="text-body-2" icon="mdi-information"
                        >접근권한이 없습니다.</v-alert>
                      </template>
                      <template v-else>
                        <template v-for="(item, i) in caseContracts">
                          <v-row
                            :key="i"
                            no-gutters
                          >
                            <v-col cols="12" class="mt-0 mb-1">
                              <v-card elevation="0">
                                <v-card-text class="px-0 pt-0 pb-1 text--primary">
                                  <v-hover v-slot:default="{ hover }">
                                    <div>
                                      <div class="px-1">
                                        <v-icon
                                          v-show="item.isStar"
                                          small
                                          class="mr-1 pb-1"
                                          color="amber darken-1"
                                        >mdi-star</v-icon>
                                        <span style="font-size: 0.825rem !important;font-weight: 450;">{{ $moment(item.date1).format('YY.MM.DD') }}</span>
                                        <span v-show="item.gubun2" class="ml-2">{{ item.gubun2 }}</span>
                                        <span v-show="item.str3" class="mx-2">￦{{ numberFormat(item.str3) }}</span>
                                        <template v-if="item.pType === 1"><!-- 소송 -->
                                          <v-avatar size="20" color="indigo" class="mr-1">
                                            <span class="white--text" style="font-size: 0.75rem;font-weight: 600;">소</span>
                                          </v-avatar>
                                        </template>
                                        <template v-else><!-- 자문 -->
                                          <template v-if="item.pInfo.type3 === 1"><!-- 고문자문 -->
                                            <v-avatar size="20" color="teal" class="mr-1">
                                              <span class="white--text" style="font-size: 0.75rem;font-weight: 600;">고</span>
                                            </v-avatar>
                                          </template>
                                          <template v-else><!-- 일반자문 -->
                                            <v-avatar size="20" color="orange" class="mr-1">
                                              <span class="white--text" style="font-size: 0.75rem;font-weight: 600;">자</span>
                                            </v-avatar>
                                          </template>
                                        </template>
                                        <span class="text-body-2 grey--text text--darken-2" style="font-size: 0.8rem;font-weight: 400;">
                                          <template v-if="!isMyTeam(item.pInfo.teamId)">
                                            <v-tooltip bottom>
                                              <template v-slot:activator="{ on }">
                                                <span v-on="on" class="grey--text" style="font-size: 0.825rem;font-weight: 450;">
                                                  {{ item.pType === 1 ? `${item.pInfo.courtName} ` : '' }}
                                                  {{ item.pType === 1 ? `${item.pInfo.caseNum}${item.pInfo.manageNum ? `/${item.pInfo.manageNum}` : ''}` : item.pInfo.manageNum }}
                                                </span>
                                              </template>
                                              <span>접근권한이 없습니다. ({{ getTeamNameByTid(item.pInfo.teamId) }} 데이터 입니다.)</span>
                                            </v-tooltip>
                                          </template>
                                          <template v-else>
                                            <span class="text--primary" style="font-size: 0.825rem;font-weight: 450;">
                                              {{ item.pType === 1 ? `${item.pInfo.courtName} ` : '' }}
                                              {{ item.pType === 1 ? `${item.pInfo.caseNum}${item.pInfo.manageNum ? `/${item.pInfo.manageNum}` : ''}` : item.pInfo.manageNum }}
                                            </span>
                                          </template>
                                        </span>
                                        <span class="primary--text" style="font-size: 0.825rem;">
                                          {{ item.pInfo.opName ? `${item.pInfo.opType ? `[${item.pInfo.opType}]` : ''}${item.pInfo.opName}` : '' }}
                                        </span>
                                        <v-tooltip bottom>
                                          <template v-slot:activator="{ on }">
                                            <v-icon
                                              v-show="hover"
                                              v-on="on"
                                              small
                                              color="green"
                                              class="ml-3"
                                              @click="caseContractPopup(item)"
                                            >mdi-eye-outline</v-icon>
                                          </template>
                                          <span>보기</span>
                                        </v-tooltip>
                                        <!-- <v-tooltip bottom>
                                          <template v-slot:activator="{ on }">
                                            <v-icon
                                              v-show="hover"
                                              v-on="on"
                                              small
                                              :color="mdiListEditButtonColor"
                                              class="ml-3"
                                              @click="doCopy(item.copyText)"
                                            >mdi-content-copy</v-icon>
                                          </template>
                                          <span>클립보드복사</span>
                                        </v-tooltip> -->
                                        <v-tooltip bottom>
                                          <template v-slot:activator="{ on }">
                                            <v-icon
                                              v-show="hover && isMyTeam(item.pInfo.teamId)"
                                              v-on="on"
                                              small
                                              :color="mdiPlusBoxOutlineButtonColor"
                                              class="ml-3"
                                              @click.stop="gotoDetail(item.pType, item.pId)"
                                            >mdi-launch</v-icon>
                                          </template>
                                          <span>바로가기</span>
                                        </v-tooltip>
                                      </div>
                                    </div>
                                  </v-hover>
                                  <!-- <div style="font-size: 0.855rem !important" class="pl-1 grey--text text--darken-2">
                                    {{ item.str1 }}
                                  </div> -->
                                  <expand-text-content v-show="item.str1" :txtData="{ title: '특약사항', content: item.str1, cutStringCount: 80 }"></expand-text-content>
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </template>
                      </template>
                    </v-col>

                    <!-- @: 연계사건(의뢰인 + 상대방) -->
                    <v-col v-show="t1show || t2show" cols="12" class="my-2 pt-3 pb-0 px-2">
                      <v-row no-gutters>
                        <v-col cols="6">
                          <span class="text-body-2 text-left grey--text text--darken-1 mr-3">연계사건</span>
                          <v-menu
                            v-model="relCaseMenu"
                            :close-on-content-click="false"
                            :nudge-width="200"
                            offset-x
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn icon small :color="mdiPlusBoxOutlineButtonColor"
                                v-on="on"
                              >
                                <v-icon small class="pb-1">mdi-plus-box-outline</v-icon>
                              </v-btn>
                            </template>
                            <v-card>
                              <v-list dense>
                                <v-subheader>연계</v-subheader>
                                <v-list-item-group color="primary">
                                  <v-list-item
                                    @click="setRelCase(1)"
                                  >
                                    <v-list-item-icon>
                                      <v-icon>mdi-link-variant</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title>소송</v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item
                                    @click="setRelCase(2)"
                                  >
                                    <v-list-item-icon>
                                      <v-icon>mdi-link-variant</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title>자문</v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>
                            </v-card>
                          </v-menu>
                          <!-- !!pdf 리스트 아이콘: 권한 불필요 -->
                          <v-btn
                            icon small
                            :color="mdiPlusBoxOutlineButtonColor"
                            @click.stop="pdfRelCaseList"
                            :disabled="this.relCases.length === 0"
                          >
                            <v-icon small class="pb-1">mdi-download</v-icon>
                          </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="4" class="text-right mr-2">
                          <span class="text-body-2">{{ relCases.length }}</span>
                        </v-col>
                      </v-row>
                      <!-- !![2021.6.2] 차트 영역 -->
                      <v-card
                        v-show="this.relCases.length > 0"
                        outlined
                        class="text-left mx-0 mt-0 mb-2 pa-0"
                      >
                        <v-row no-gutters justify="center">
                          <v-col cols="12" sm="6" xs="12">
                            <v-card
                              elevation="0"
                              class="ma-0 py-2 pl-4 pr-0"
                            >
                              <apexchart
                                type="pie"
                                width="250"
                                :options="relCaseChartOption1"
                                :series="relCaseSeries1"
                              ></apexchart>
                            </v-card>
                          </v-col>
                          <v-col cols="12" sm="6" xs="12">
                            <v-card
                              elevation="0"
                              class="ma-0 py-2 pl-4 pr-0"
                            >
                              <apexchart
                                type="pie"
                                width="250"
                                :options="relCaseChartOption2"
                                :series="relCaseSeries2"
                              ></apexchart>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card>
                      <template v-for="(item, i) in relCases">
                        <v-row
                          :key="i"
                          no-gutters
                        >
                          <v-col cols="12" class="mt-0 mb-1">
                            <v-hover v-slot:default="{ hover }">
                              <v-card elevation="0">
                                <v-card-text class="px-0 pt-0 pb-1 text--primary">
                                  <div class="px-1">
                                    <template v-if="item.rType === 1"><!-- 소송 -->
                                      <v-avatar size="20" color="indigo" class="mr-1">
                                        <span class="white--text" style="font-size: 0.75rem;font-weight: 600;">소</span>
                                      </v-avatar>
                                    </template>
                                    <template v-else><!-- 자문 -->
                                      <template v-if="item.rInfo.type3 === 1"><!-- 고문자문 -->
                                        <v-avatar size="20" color="teal" class="mr-1">
                                          <span class="white--text" style="font-size: 0.75rem;font-weight: 600;">고</span>
                                        </v-avatar>
                                      </template>
                                      <template v-else><!-- 일반자문 -->
                                        <v-avatar size="20" color="orange" class="mr-1">
                                          <span class="white--text" style="font-size: 0.75rem;font-weight: 600;">자</span>
                                        </v-avatar>
                                      </template>
                                    </template>
                                    <v-icon
                                      v-show="item.rInfo.isStar"
                                      x-small
                                      class="mr-1 pb-1"
                                      color="amber darken-1"
                                    >mdi-star</v-icon>
                                    <v-icon
                                      v-show="item.rInfo.endDate && item.rInfo.endType"
                                      x-small
                                      color="warning"
                                      class="mr-1 pb-1"
                                    >mdi-gavel</v-icon>
                                    <v-chip
                                      label outlined x-small class="mx-1 px-1"
                                      :color="item.rInfo.status2 === '진행' ? 'primary' : 'blue-grey darken-2'"
                                      style="font-size: 0.70rem;font-weight: 600;letter-spacing: 0.08em"
                                    >{{ item.rInfo.status2 }}</v-chip>
                                    <span class="text-body-2 grey--text text--darken-2" style="font-size: 0.8rem;font-weight: 400;">
                                      {{ item.rInfo.caseName }}
                                      <template v-if="!isMyTeam(item.rInfo.teamId)">
                                        <v-tooltip bottom>
                                          <template v-slot:activator="{ on }">
                                            <span v-on="on" class="grey--text" style="font-size: 0.825rem;font-weight: 450;">
                                              {{ item.rType === 1 ? `${item.rInfo.courtName} ` : '' }}
                                              {{ item.rType === 1 ? `${item.rInfo.caseNum}${item.rInfo.manageNum ? `/${item.rInfo.manageNum}` : ''}` : item.rInfo.manageNum }}
                                            </span>
                                          </template>
                                          <span>접근권한이 없습니다. ({{ getTeamNameByTid(item.rInfo.teamId) }} 데이터 입니다.)</span>
                                        </v-tooltip>
                                      </template>
                                      <template v-else>
                                        <span class="text--primary" style="font-size: 0.825rem;font-weight: 450;">
                                          {{ item.rType === 1 ? `${item.rInfo.courtName} ` : '' }}
                                          {{ item.rType === 1 ? `${item.rInfo.caseNum}${item.rInfo.manageNum ? `/${item.rInfo.manageNum}` : ''}` : item.rInfo.manageNum }}
                                        </span>
                                      </template>
                                    </span>
                                    <span class="primary--text" style="font-size: 0.825rem;">
                                      {{ item.rInfo.opName ? `${item.rInfo.opType ? `[${item.rInfo.opType}]` : ''}${item.rInfo.opName}` : '' }}
                                    </span>
                                    <span
                                      v-show="item.rInfo.endDate && item.rInfo.endType"
                                      class="ml-1 text-body-2 error--text font-weight-medium">
                                      {{ strDateFormat(item.rInfo.endDate) }} {{ item.rInfo.endType }}
                                    </span>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-show="hover"
                                          v-on="on"
                                          small
                                          :color="mdiListEditButtonColor"
                                          class="ml-3"
                                          @click="popEditRelCase(item.rType, item)"
                                        >mdi-pencil</v-icon>
                                      </template>
                                      <span>수정</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-show="hover && isMyTeam(item.rInfo.teamId)"
                                          v-on="on"
                                          small
                                          :color="mdiPlusBoxOutlineButtonColor"
                                          class="ml-3"
                                          @click.stop="gotoDetail(item.rType, item.rId)"
                                        >mdi-launch</v-icon>
                                      </template>
                                      <span>바로가기</span>
                                    </v-tooltip>
                                  </div>
                                </v-card-text>
                              </v-card>
                            </v-hover>
                          </v-col>
                        </v-row>
                      </template>
                    </v-col>

                    <!-- @: 타임시트(의뢰인) -->
                    <v-col v-show="t1show" cols="12" class="my-2 pt-3 pb-0 px-2">
                      <v-row no-gutters>
                        <v-col cols="6">
                          <span class="text-body-2 text-left grey--text text--darken-1 mr-3">타임시트</span>
                          <!-- <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                            <v-icon
                              v-show="getMyAuth.auth3"
                              small icon
                              class="pb-1"
                              @click="popRelTimesheet(null)"
                            >mdi-plus-box-outline</v-icon>
                          </v-btn> -->
                          <!-- !!pdf 리스트 아이콘: 비용권한 필요 -->
                          <v-btn
                            v-show="getMyAuth.auth3"
                            icon small
                            :color="mdiPlusBoxOutlineButtonColor"
                            @click.stop="pdfTimesheetList"
                            :disabled="this.relTimesheets.length === 0"
                          >
                            <v-icon small class="pb-1">mdi-download</v-icon>
                          </v-btn>
                          <v-tooltip
                            bottom
                            min-width="350"
                          >
                            <template v-slot:activator="{ on }">
                              <v-icon v-show="getMyAuth.auth3" v-on="on" color="primary" class="ml-1 mb-1" small>mdi-help-circle-outline</v-icon>
                            </template>
                            <span>고문계약 하위의 자문사건도 포함된 결과입니다.</span>
                          </v-tooltip>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="4" class="text-right mr-2">
                          <span v-show="getMyAuth.auth3" class="text-body-2">
                            ￦{{ numberFormat(timesheetTotal) }}
                          </span>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon v-show="getMyAuth.auth3" v-on="on" color="primary" class="ml-1 mb-1" small>mdi-help-circle-outline</v-icon>
                            </template>
                            <span>사건관리상태에 상관없이 타임시트에 등록한 합계입니다.</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <!-- @:[2021.6.22] 차트 영역 -->
                      <v-card
                        v-show="this.relTimesheets.length > 0 && getMyAuth.auth3"
                        outlined
                        class="text-left ma-0 pa-0"
                      >
                        <v-row no-gutters justify="center">
                          <v-col cols="12" sm="6" xs="12">
                            <v-card
                              elevation="0"
                              class="ma-0 py-2 pl-4 pr-0"
                            >
                              <apexchart
                                type="pie"
                                width="280"
                                :options="timeSheetChartOption1"
                                :series="timeSheetSeries1"
                              ></apexchart>
                            </v-card>
                          </v-col>
                          <v-col cols="12" sm="6" xs="12">
                            <v-card
                              elevation="0"
                              class="ma-0 py-2 pl-4 pr-0"
                            >
                              <apexchart
                                type="pie"
                                width="280"
                                :options="timeSheetChartOption2"
                                :series="timeSheetSeries2"
                              ></apexchart>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card>
                      <v-alert
                        v-show="this.relTimesheets.length > 0 && getMyAuth.auth3"
                        type="info" dense text class="text-body-2 my-1"
                      >
                        개별사건에 의뢰인에게 부담하기로 한 타임시트내역의 결과이며, 실제 수금대상이 되는 시간보수는 각 계약에서 관리됩니다.
                      </v-alert>
                      <!-- @: 타임시트 목록 -->
                      <template v-if="!getMyAuth.auth3">
                        <v-alert
                          text dense color="blue-grey" class="text-body-2" icon="mdi-information"
                        >접근권한이 없습니다.</v-alert>
                      </template>
                      <template v-else>
                        <template v-for="(item, i) in relTimesheets">
                          <v-row
                            :key="i"
                            no-gutters
                          >
                            <v-col cols="12" class="mb-2">
                              <v-card elevation="0">
                                <v-card-text class="px-0 pt-0 pb-1 text--primary">
                                  <v-hover v-slot:default="{ hover }">
                                    <div>
                                      <div class="px-1">
                                        <v-icon small
                                          v-if="item.isStar"
                                          class="mr-1 pb-1"
                                          color="amber darken-1"
                                        >mdi-star</v-icon>
                                        <span style="font-size: 0.825rem !important;font-weight: 450;">{{ strDateFormat3(item.date1) }}</span>
                                        <!-- !![2021.4.26]업무자 -->
                                        <span v-show="item.str3" class="text-body-2 primary--text" style="font-weight: 600;">
                                          {{ item.str3 }}
                                        </span>
                                        <span class="mr-1">{{ item.str5.replace(/^0/g, '') }} / ￦{{ numberFormat(item.str2) }}</span>
                                        <template v-if="item.pType === 1"><!-- 소송 -->
                                          <v-avatar size="20" color="indigo" class="mr-1">
                                            <span class="white--text" style="font-size: 0.75rem;font-weight: 600;">소</span>
                                          </v-avatar>
                                        </template>
                                        <template v-else><!-- 자문 -->
                                          <template v-if="item.pInfo.type3 === 1"><!-- 고문자문 -->
                                            <v-avatar size="20" color="teal" class="mr-1">
                                              <span class="white--text" style="font-size: 0.75rem;font-weight: 600;">고</span>
                                            </v-avatar>
                                          </template>
                                          <template v-else><!-- 일반자문 -->
                                            <v-avatar size="20" color="orange" class="mr-1">
                                              <span class="white--text" style="font-size: 0.75rem;font-weight: 600;">자</span>
                                            </v-avatar>
                                          </template>
                                        </template>
                                        <span class="text-body-2 grey--text text--darken-2" style="font-size: 0.8rem;font-weight: 400;">
                                          <template v-if="!isMyTeam(item.pInfo.teamId)">
                                            <v-tooltip bottom>
                                              <template v-slot:activator="{ on }">
                                                <span v-on="on" class="grey--text" style="font-size: 0.825rem;font-weight: 450;">
                                                  {{ item.pType === 1 ? `${item.pInfo.courtName} ` : '' }}
                                                  {{ item.pType === 1 ? `${item.pInfo.caseNum}${item.pInfo.manageNum ? `/${item.pInfo.manageNum}` : ''}` : item.pInfo.manageNum }}
                                                </span>
                                              </template>
                                              <span>접근권한이 없습니다. ({{ getTeamNameByTid(item.pInfo.teamId) }} 데이터 입니다.)</span>
                                            </v-tooltip>
                                          </template>
                                          <template v-else>
                                            <span class="text--primary" style="font-size: 0.825rem;font-weight: 450;">
                                              {{ item.pType === 1 ? `${item.pInfo.courtName} ` : '' }}
                                              {{ item.pType === 1 ? `${item.pInfo.caseNum}${item.pInfo.manageNum ? `/${item.pInfo.manageNum}` : ''}` : item.pInfo.manageNum }}
                                            </span>
                                          </template>
                                        </span>
                                        <span class="primary--text" style="font-size: 0.825rem;">
                                          {{ item.pInfo.opName ? `${item.pInfo.opType ? `[${item.pInfo.opType}]` : ''}${item.pInfo.opName}` : '' }}
                                        </span>
                                        <v-tooltip bottom>
                                          <template v-slot:activator="{ on }">
                                            <v-icon
                                              v-show="hover && getMyAuth.auth3"
                                              v-on="on"
                                              small
                                              color="green"
                                              class="ml-3"
                                              @click="viewTimesheet(item)"
                                            >mdi-eye-outline</v-icon>
                                          </template>
                                          <span>보기</span>
                                        </v-tooltip>
                                        <!-- <v-tooltip bottom>
                                          <template v-slot:activator="{ on }">
                                            <v-icon
                                              v-show="hover"
                                              v-on="on"
                                              small
                                              :color="mdiListEditButtonColor"
                                              class="ml-3"
                                              @click="doCopy(item.copyText)"
                                            >mdi-content-copy</v-icon>
                                          </template>
                                          <span>클립보드복사</span>
                                        </v-tooltip> -->
                                        <v-tooltip bottom>
                                          <template v-slot:activator="{ on }">
                                            <v-icon
                                              v-show="hover && isMyTeam(item.pInfo.teamId)"
                                              v-on="on"
                                              small
                                              :color="mdiPlusBoxOutlineButtonColor"
                                              class="ml-3"
                                              @click.stop="gotoDetail(item.pType, item.pId)"
                                            >mdi-launch</v-icon>
                                          </template>
                                          <span>바로가기</span>
                                        </v-tooltip>
                                      </div>
                                    </div>
                                  </v-hover>
                                  <!-- <div style="font-size: 0.855rem !important" class="pl-1 grey--text text--darken-2">
                                    {{ item.str1 }}
                                  </div> -->
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </template>
                      </template>
                    </v-col>

                    <!-- @: 비용(의뢰인) -->
                    <v-col v-show="t1show" cols="12" class="my-2 pt-3 pb-0 px-2">
                      <v-row no-gutters>
                        <v-col cols="6">
                          <span class="text-body-2 text-left grey--text text--darken-1 mr-3">비용</span>
                          <!-- <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                            <v-icon
                              v-show="getMyAuth.auth2"
                              small icon
                              @click="popRelCost(null)"
                              class="pb-1"
                            >mdi-plus-box-outline</v-icon>
                          </v-btn> -->
                          <!-- !!pdf 리스트 아이콘: 비용권한 필요 -->
                          <v-btn
                            v-show="getMyAuth.auth2"
                            icon small
                            :color="mdiPlusBoxOutlineButtonColor"
                            @click.stop="pdfCostList"
                            :disabled="this.relCosts.length === 0"
                          >
                            <v-icon small class="pb-1">mdi-download</v-icon>
                          </v-btn>
                          <v-tooltip
                            bottom
                            min-width="350"
                          >
                            <template v-slot:activator="{ on }">
                              <v-icon v-show="getMyAuth.auth2" v-on="on" color="primary" class="ml-1 mb-1" small>mdi-help-circle-outline</v-icon>
                            </template>
                            <span>고문계약 하위의 자문사건도 포함된 결과입니다.</span>
                          </v-tooltip>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="4" class="text-right mr-2">
                          <span v-show="getMyAuth.auth2" class="text-body-2">
                            ￦{{ numberFormat(costTotal) }}
                          </span>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon v-show="getMyAuth.auth2" v-on="on" color="primary" class="ml-1 mb-1" small>mdi-help-circle-outline</v-icon>
                            </template>
                            <span>사건관리상태에 상관없이 비용에 등록한 합계입니다.</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <!-- @:[2021.6.22] 차트 영역 -->
                      <v-card
                        v-show="this.relCosts.length > 0 && getMyAuth.auth2"
                        outlined
                        class="text-left ma-0 pa-0"
                      >
                        <v-row no-gutters justify="center">
                          <v-col cols="12" sm="6" xs="12">
                            <v-card
                              elevation="0"
                              class="ma-0 py-2 pl-4 pr-0"
                            >
                              <apexchart
                                type="pie"
                                width="250"
                                :options="costChartOption1"
                                :series="costSeries1"
                              ></apexchart>
                            </v-card>
                          </v-col>
                          <v-col cols="12" sm="6" xs="12">
                            <v-card
                              elevation="0"
                              class="ma-0 py-2 pl-4 pr-0"
                            >
                              <apexchart
                                type="pie"
                                width="250"
                                :options="costChartOption2"
                                :series="costSeries2"
                              ></apexchart>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card>
                      <v-alert
                        v-show="this.relCosts.length > 0 && getMyAuth.auth2"
                        type="info" dense text class="text-body-2 my-1"
                      >
                        개별사건에서 의뢰인에게 부담하기로 한 비용내역의 결과입니다.
                      </v-alert>
                      <!-- @: 비용 목록 -->
                      <template v-if="!getMyAuth.auth2">
                        <v-alert
                          text dense color="blue-grey" class="text-body-2" icon="mdi-information"
                        >접근권한이 없습니다.</v-alert>
                      </template>
                      <template v-else>
                        <template v-for="(item, i) in relCosts">
                          <v-row
                            :key="i"
                            no-gutters
                          >
                            <v-col cols="12" class="mb-2">
                              <v-card elevation="0">
                                <v-card-text class="px-0 pt-0 pb-1 text--primary">
                                  <v-hover v-slot:default="{ hover }">
                                    <div>
                                      <div class="px-1">
                                        <v-icon small
                                          v-if="item.isStar"
                                          class="mr-1 pb-1"
                                          color="amber darken-1"
                                        >mdi-star</v-icon>
                                        <span style="font-size: 0.825rem !important;font-weight: 450;">{{ strDateFormat3(item.date1) }}</span>
                                        <v-chip
                                          label outlined x-small class="ml-1 mb-1 px-1"
                                          color="blue-grey darken-2"
                                          style="font-size: 0.70rem;font-weight: 600;letter-spacing: 0.08em"
                                          >
                                          {{ item.gubun1 }}
                                        </v-chip>
                                        <span class="mx-1">{{ item.str3 }} ￦{{ numberFormat(item.str2) }}</span>
                                        <template v-if="item.pType === 1"><!-- 소송 -->
                                          <v-avatar size="20" color="indigo" class="mr-1">
                                            <span class="white--text" style="font-size: 0.75rem;font-weight: 600;">소</span>
                                          </v-avatar>
                                        </template>
                                        <template v-else><!-- 자문 -->
                                          <template v-if="item.pInfo.type3 === 1"><!-- 고문자문 -->
                                            <v-avatar size="20" color="teal" class="mr-1">
                                              <span class="white--text" style="font-size: 0.75rem;font-weight: 600;">고</span>
                                            </v-avatar>
                                          </template>
                                          <template v-else><!-- 일반자문 -->
                                            <v-avatar size="20" color="orange" class="mr-1">
                                              <span class="white--text" style="font-size: 0.75rem;font-weight: 600;">자</span>
                                            </v-avatar>
                                          </template>
                                        </template>
                                        <span class="text-body-2 grey--text text--darken-2" style="font-size: 0.8rem;font-weight: 400;">
                                          <template v-if="!isMyTeam(item.pInfo.teamId)">
                                            <v-tooltip bottom>
                                              <template v-slot:activator="{ on }">
                                                <span v-on="on" class="grey--text" style="font-size: 0.825rem;font-weight: 450;">
                                                  {{ item.pType === 1 ? `${item.pInfo.courtName} ` : '' }}
                                                  {{ item.pType === 1 ? `${item.pInfo.caseNum}${item.pInfo.manageNum ? `/${item.pInfo.manageNum}` : ''}` : item.pInfo.manageNum }}
                                                </span>
                                              </template>
                                              <span>접근권한이 없습니다. ({{ getTeamNameByTid(item.pInfo.teamId) }} 데이터 입니다.)</span>
                                            </v-tooltip>
                                          </template>
                                          <template v-else>
                                            <span class="text--primary" style="font-size: 0.825rem;font-weight: 450;">
                                              {{ item.pType === 1 ? `${item.pInfo.courtName} ` : '' }}
                                              {{ item.pType === 1 ? `${item.pInfo.caseNum}${item.pInfo.manageNum ? `/${item.pInfo.manageNum}` : ''}` : item.pInfo.manageNum }}
                                            </span>
                                          </template>
                                        </span>
                                        <span class="primary--text" style="font-size: 0.825rem;">
                                          {{ item.pInfo.opName ? `${item.pInfo.opType ? `[${item.pInfo.opType}]` : ''}${item.pInfo.opName}` : '' }}
                                        </span>
                                        <v-tooltip bottom>
                                          <template v-slot:activator="{ on }">
                                            <v-icon
                                              v-show="hover && getMyAuth.auth2"
                                              v-on="on"
                                              small
                                              color="green"
                                              class="ml-3"
                                              @click="viewCost(item)"
                                            >mdi-eye-outline</v-icon>
                                          </template>
                                          <span>보기</span>
                                        </v-tooltip>
                                        <!-- <v-tooltip bottom>
                                          <template v-slot:activator="{ on }">
                                            <v-icon
                                              v-show="hover"
                                              v-on="on"
                                              small
                                              :color="mdiListEditButtonColor"
                                              class="ml-3"
                                              @click="doCopy(item.copyText)"
                                            >mdi-content-copy</v-icon>
                                          </template>
                                          <span>클립보드복사</span>
                                        </v-tooltip> -->
                                        <v-tooltip bottom>
                                          <template v-slot:activator="{ on }">
                                            <v-icon
                                              v-show="hover && isMyTeam(item.pInfo.teamId)"
                                              v-on="on"
                                              small
                                              :color="mdiPlusBoxOutlineButtonColor"
                                              class="ml-3"
                                              @click.stop="gotoDetail(item.pType, item.pId)"
                                            >mdi-launch</v-icon>
                                          </template>
                                          <span>바로가기</span>
                                        </v-tooltip>
                                      </div>
                                    </div>
                                  </v-hover>
                                  <!-- <div style="font-size: 0.855rem !important" class="pl-1 grey--text text--darken-2">
                                    {{ item.str1 }}
                                  </div> -->
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </template>
                      </template>
                    </v-col>

                    <!-- @: 하단 여백 -->
                    <v-col cols="12" class="my-12 py-2">
                      <div></div>
                    </v-col>

                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <v-divider vertical class="ma-0 pa-0"></v-divider>

            <!-- 우측 컨텐츠 시작 --->
            <v-col>
              <v-container fluid class="ma-0 pa-0">
                <v-row
                  align="center"
                  justify="center"
                  no-gutters
                >
                  <!-- @: 추가정보 -->
                  <v-col cols="12" class="pt-2 pb-0 px-2">
                    <span class="text-body-2 grey--text text--darken-1 mr-3">추가정보</span>
                    <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                      <v-icon
                        small
                        @click="editTxt1"
                        class="pb-1"
                      >mdi-plus-box-outline</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-3 px-2">
                    <v-card
                      class="overflow-y-auto text-body-2 grey--text text--darken-2 pa-1"
                      elevation="0"
                      max-height="150"
                      :outlined="article.txt1 ? true : false"
                    >
                      <div id="vue2editorViewer" v-html="article.txt1"></div>
                    </v-card>
                  </v-col>

                  <!-- @: 진행내용 + 업무(진행내용) : 2021.6.25 수정 -->
                  <v-col cols="12" class="pt-3 pb-0 px-2">
                    <v-row
                      no-gutters
                      align="center"
                      justify="center"
                    >
                      <v-col cols="6">
                        <span class="text-body-2 text-left grey--text text--darken-1 mr-3">진행내용 + 업무</span>
                        <!-- <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                          <v-icon
                            small
                            @click="popRelContent(null)"
                            class="pb-1"
                          >mdi-plus-box-outline</v-icon>
                        </v-btn> -->
                        <v-menu offset-y>
                          <template v-slot:activator="{ on }">
                            <v-btn icon small :color="mdiPlusBoxOutlineButtonColor"
                              v-on="on"
                            >
                              <v-icon
                                small
                                class="pb-1"
                              >mdi-plus-box-outline</v-icon>
                            </v-btn>
                          </template>
                          <v-card
                            class="mx-auto"
                            max-width="300"
                            tile
                          >
                            <v-list
                              dense
                              flat
                            >
                              <v-list-item
                                @click="popAutoRenewal(null)"
                              >
                                <v-list-item-action class="mx-2 pr-3">
                                  <v-icon small color="red darken-1">
                                    mdi-file-document-edit-outline
                                  </v-icon>
                                </v-list-item-action>
                                <v-list-item-content class="mr-3 pr-3">고문계약자동갱신</v-list-item-content>
                              </v-list-item>
                              <v-divider></v-divider>
                              <v-list-item
                                @click="popRelContent(null)"
                              >
                                <v-list-item-action class="mx-2 pr-3">
                                  <v-icon small color="blue-grey darken-2">
                                    mdi-content-paste
                                  </v-icon>
                                </v-list-item-action>
                                <v-list-item-content class="mr-3 pr-3">업무</v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-menu>
                      </v-col>
                      <v-col>
                        <v-row
                          align="center"
                          justify="end"
                          no-gutters
                        >
                          <v-spacer></v-spacer>
                          <v-select
                            v-model="upmuSelect"
                            :items="upmuSelects"
                            dense
                            filled
                            shaped
                            solo-inverted
                            item-color="warning"
                            flat
                            hide-details
                            style="max-width: 120px; height: 28px; font-size: 0.785rem !important"
                            class="my-0 mr-2 pa-0"
                            @change="changeUpmuSelect"
                          ></v-select>
                        </v-row>
                      </v-col>
                    </v-row>
                    <template v-for="(item, i) in relContents">
                      <v-row
                        :key="i"
                        no-gutters
                      >
                        <v-col cols="12">
                          <v-card
                            elevation="0"
                            class="mt-1"
                          >
                            <v-card-text class="pa-1 text--primary">
                              <v-hover v-slot:default="{ hover }">
                                <div class="mb-1">
                                  <div class="px-1">
                                    <v-icon x-small
                                      v-if="item.isStar"
                                      class="mr-1 pb-1"
                                      color="amber darken-1"
                                    >mdi-star</v-icon>
                                    <span style="font-size: 0.825rem !important;font-weight: 450;">{{ strDateFormat3(item.date1) }}</span>
                                    <v-chip
                                      label outlined x-small class="mx-1 px-1"
                                      color="blue-grey darken-2"
                                      style="font-size: 0.70rem;font-weight: 600;letter-spacing: 0.08em"
                                    >
                                      {{ item.gubun1 }}
                                    </v-chip>
                                    <span v-show="item.type === 22 && item.subtype === 1 && item.subtype2 === 0" class="text-body-2 primary--text" style="font-weight: 600;">
                                      {{ item.str2 }}
                                    </span>
                                    {{ item.view1 }}{{ item.view2 }}
                                    <span v-show="item.subtype === 1 && item.subtype2 === 0" class="primary--text">#{{ item.gubun2 }}</span>
                                  </div>
                                  <div style="font-size: 0.785rem !important" class="grey--text text--darken-1">
                                    <span class="ml-1">{{ strDateFormat2(item.createdAt) }} {{ item.wname }}</span>
                                    <!-- @: 수정 아이콘 -->
                                    <template v-if="item.subtype === 1 && item.subtype2 === 0">
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && item.subtype"
                                            v-on="on"
                                            small
                                            :color="mdiListEditButtonColor"
                                            class="ml-3"
                                            @click="popRelContent(item)"
                                          >mdi-pencil</v-icon>
                                        </template>
                                        <span>수정</span>
                                      </v-tooltip>
                                    </template>
                                    <template v-else>
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && item.subtype"
                                            v-on="on"
                                            small
                                            :color="mdiListEditButtonColor"
                                            class="ml-3"
                                            @click="popAutoRenewal(item)"
                                          >mdi-pencil</v-icon>
                                        </template>
                                        <span>수정</span>
                                      </v-tooltip>
                                    </template>
                                    <!-- @: 클립보드 복사 아이콘 -->
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-show="hover && item.subtype"
                                          v-on="on"
                                          small
                                          :color="mdiListEditButtonColor"
                                          class="ml-3"
                                          @click="doCopy(item.copyText)"
                                        >mdi-content-copy</v-icon>
                                      </template>
                                      <span>클립보드복사</span>
                                    </v-tooltip>
                                    <!-- @: 댓글 추가 아이콘 -->
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-show="hover && item.subtype"
                                          v-on="on"
                                          small
                                          :color="mdiListEditButtonColor"
                                          class="ml-3"
                                          :data-index="i"
                                          :data-item="JSON.stringify(item)"
                                          @click="showCommentPop"
                                        >mdi-comment-text-outline</v-icon>
                                      </template>
                                      <span>댓글쓰기</span>
                                    </v-tooltip>
                                    <!-- @: 첨부파일 추가 아이콘 -->
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-show="hover && item.subtype"
                                          v-on="on"
                                          small
                                          :color="mdiListEditButtonColor"
                                          class="ml-3"
                                          :data-index="i"
                                          :data-item="JSON.stringify(item)"
                                          @click="showFilePop"
                                        >mdi-paperclip</v-icon>
                                      </template>
                                      <span>첨부파일</span>
                                    </v-tooltip>
                                    <!-- @: 공유링크 추가 아이콘 -->
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-show="hover && item.subtype"
                                          v-on="on"
                                          small
                                          :color="mdiListEditButtonColor"
                                          class="ml-3"
                                          :data-index="i"
                                          :data-item="JSON.stringify(item)"
                                          @click="showShareLinkPop"
                                        >mdi-link-plus</v-icon>
                                      </template>
                                      <span>공유링크</span>
                                    </v-tooltip>
                                  </div>
                                </div>
                              </v-hover>
                              <!-- @: 댓글 & 첨부파일 & 공유링크 리스트 -->
                              <div
                                v-for="(cmtf, i) in item.subs"
                                :key="i"
                                class="my-1 text-body-2"
                              >
                                <v-hover v-slot:default="{ hover }" transition="fade-transition">
                                  <div class="ma-0 pa-0">
                                    <v-icon
                                      :color="cmtf.isAnotherWrite ? 'purple' : ''"
                                      x-small
                                    >
                                      {{ cmtf.isAnotherWrite ? 'mdi-ray-start-arrow' : 'mdi-subdirectory-arrow-right' }}
                                    </v-icon>
                                    <v-icon v-show="cmtf.type === 36" small :color="mdiDownloadButtonColor">mdi-paperclip</v-icon>
                                    <v-icon v-show="cmtf.type === 38" small :color="mdiLinkShareIconColor">mdi-link-plus</v-icon>
                                    <!-- !! 첨부파일, 공유링크 카테고리 태그(attach file category tag) 컴포넌트 -->
                                    <attach-file-category-tag
                                      v-show="cmtf.type === 36 || cmtf.type === 38"
                                      :caption="cmtf.gubun2"
                                    ></attach-file-category-tag>
                                    {{ cmtf.str1 }}
                                    <span class="text-caption grey--text">({{ strDateFormat2(cmtf.createdAt) }} {{ cmtf.wname }})</span>
                                    <!-- 사용안함:[2021.9.1] 첨부파일 다운로드 -->
                                    <!-- <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-show="hover && cmtf.type === 36"
                                          v-on="on"
                                          small
                                          right
                                          :color="mdiDownloadButtonColor"
                                          class="ml-3"
                                          @click="fileDownload(cmtf)"
                                        >mdi-cloud-download-outline</v-icon>
                                      </template>
                                      <span>다운로드</span>
                                    </v-tooltip> -->
                                    <!-- 첨부파일 바로보기 -->
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-show="hover && cmtf.type === 36"
                                          v-on="on"
                                          small
                                          right
                                          :color="mdiOpenFileButtonColor"
                                          class="ml-3"
                                          @click="fileDirectView(cmtf)"
                                        >mdi-eye-outline</v-icon>
                                      </template>
                                      <span>바로보기</span>
                                    </v-tooltip>
                                    <!-- 첨부파일 삭제 -->
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-show="hover && cmtf.type === 36"
                                          v-on="on"
                                          small
                                          right
                                          :color="mdiTrashCanOutlineButtonColor"
                                          class="ml-3"
                                          @click="deleteFile(cmtf)"
                                        >mdi-trash-can-outline</v-icon>
                                      </template>
                                      <span>삭제</span>
                                    </v-tooltip>
                                    <!-- 댓글 삭제 -->
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-show="hover && cmtf.type === 37"
                                          v-on="on"
                                          small
                                          right
                                          :color="mdiTrashCanOutlineButtonColor"
                                          class="ml-3"
                                          @click="deleteComment(cmtf)"
                                        >mdi-trash-can-outline</v-icon>
                                      </template>
                                      <span>삭제</span>
                                    </v-tooltip>
                                    <!-- 공유링크 바로가기 -->
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-show="hover && cmtf.type === 38"
                                          v-on="on"
                                          small
                                          right
                                          :color="mdiPlusBoxOutlineButtonColor"
                                          class="ml-3"
                                          @click="shareLinkDirectGo(cmtf)"
                                        >mdi-launch</v-icon>
                                      </template>
                                      <span>바로가기</span>
                                    </v-tooltip>
                                    <!-- 공유링크 삭제 -->
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-show="hover && cmtf.type === 38"
                                          v-on="on"
                                          small
                                          right
                                          :color="mdiTrashCanOutlineButtonColor"
                                          class="ml-3"
                                          @click="deleteShareLink(cmtf)"
                                        >mdi-trash-can-outline</v-icon>
                                      </template>
                                      <span>삭제</span>
                                    </v-tooltip>
                                  </div>
                                </v-hover>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                    <v-row
                      v-if="relContents.length <= 0"
                      no-gutters
                    >
                      <v-col cols="12" xs="12" sm="12">
                        <v-card
                          elevation="0"
                          class="ma-1"
                        >
                          <v-card-text class="text--center pa-3">
                            <p class="text-subtitle-2 grey--text">
                              데이터가 없습니다.
                            </p>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>

                  <!-- @: 전자결재 -->
                  <v-col cols="12" class="mt-5 pb-0 px-2">
                    <v-row no-gutters>
                      <v-col cols="6">
                        <span class="text-body-2 text-left grey--text text--darken-1 mr-3">전자결재</span>
                        <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                          <v-icon
                            small
                            @click="addEapproval"
                            class="pb-1"
                          >mdi-plus-box-outline</v-icon>
                        </v-btn>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="text-right mr-2">
                        <span class="text-body-2">{{ relElApprovals.length }}</span>
                      </v-col>
                    </v-row>
                    <template v-for="(item, i) in relElApprovals">
                      <v-row
                        :key="i"
                        no-gutters
                      >
                        <v-col cols="12" class="mb-2">
                          <v-card elevation="0">
                            <v-card-text class="px-0 pt-0 pb-1 text--primary">
                              <v-hover v-slot:default="{ hover }">
                                <div class="px-1">
                                  <v-icon small
                                    v-if="item.isStar"
                                    class="mr-1 pb-1"
                                    color="amber darken-1"
                                  >mdi-star</v-icon>
                                  <span style="font-size: 0.825rem !important;font-weight: 450;">{{ strDateFormat3(item.updatedAt) }}</span>
                                  <span v-show="item.gubun1" class="ml-2 primary--text">{{ item.gubun1 }}</span>
                                  <!-- 주의: 임시저장 일때는 내가 기안한 것만 보임 -->
                                  <v-tooltip
                                    v-if="item.status === 1"
                                    bottom
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-icon
                                        v-on="on"
                                        v-show="hover && item.isMine"
                                        small
                                        :color="mdiListEditButtonColor"
                                        class="ml-3"
                                        @click="goRelEapproval(item)"
                                      >mdi-launch</v-icon>
                                    </template>
                                    <span>바로가기</span>
                                  </v-tooltip>
                                  <!-- 주의: 임시저장이 아닐때는 남의것도 보임 -->
                                  <v-tooltip
                                    v-else
                                    bottom
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-icon
                                        v-on="on"
                                        v-show="hover"
                                        small
                                        :color="mdiListEditButtonColor"
                                        class="ml-3"
                                        @click="goRelEapproval(item)"
                                      >mdi-launch</v-icon>
                                    </template>
                                    <span>바로가기</span>
                                  </v-tooltip>
                                </div>
                              </v-hover>
                              <div style="font-size: 0.855rem !important" class="pl-1 grey--text text--darken-2">
                                {{ item.subject }}
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                  </v-col>

                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <!-- 첨부파일 다운로드시 오버레이 -->
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!-- 댓글 팝업 -->
    <add-comment-pop ref="addCommentPop"></add-comment-pop>
    <!-- 첨부파일 팝업 -->
    <add-file-pop ref="addFilePop"></add-file-pop>
    <!-- 공유링크 팝업 컴포넌트 -->
    <add-share-link-pop ref="addShareLinkPop"></add-share-link-pop>
    <!-- 인명부 수정 -->
    <client-add-dialog ref="clientAddDialog"></client-add-dialog>

    <!-- 관계인 -->
    <rel-person-dialog ref="relPersonDialog" :article="article"></rel-person-dialog>
    <!-- 수금 -->
    <rel-sugum-dialog ref="relSugumDialog" :article="article"></rel-sugum-dialog>

    <!-- 사용안함:[2021.6.23] 비용 등록/수정 팝업은 사용중지됨 -->
    <!-- <rel-cost-dialog ref="relCostDialog" :article="article"></rel-cost-dialog> -->
    <!-- !![2021.6.23] 비용 뷰만 하는 팝업 신설 -->
    <rel-cost-view ref="relCostView"></rel-cost-view>

    <!-- 계약 -->
    <!-- <rel-contract-dialog ref="relContractDialog" :article="article"></rel-contract-dialog> -->
    <!-- 보수약정 팝업 : 하위인 계약 팝업에서 사용 -->
    <!-- <edit-bosu-dialog ref="editBosuDialog"></edit-bosu-dialog> -->
    <!-- !![2021.6.1] 고문계약 -->
    <gomun-contract-dialog ref="gomunContractDialog" :article="article"></gomun-contract-dialog>
    <!-- !![2021.6.21] 소송/자문 개별사건계약 뷰 -->
    <rel-contract-view01 ref="relContractView01"></rel-contract-view01>

    <!-- 사용안함:[2021.6.23] 타임시트 등록/수정 팝업은 사용안한다 -->
    <!-- <rel-timesheet-dialog ref="relTimesheetDialog" :article="article"></rel-timesheet-dialog> -->
    <!-- !![2021.6.23] 타임시트 뷰만 하는 팝업 신설 -->
    <rel-timesheet-view ref="relTimesheetView"></rel-timesheet-view>

    <!-- 추가정보 -->
    <edit-txt1-dialog ref="editTxt1" :article="article"></edit-txt1-dialog>
    <!-- 연계사건(소송/자문) 등록 팝업 -->
    <rel-case-dialog ref="relCaseDialog" :article="article"></rel-case-dialog>
    <!-- 연계사건(소송/자문) 해제 팝업 -->
    <rel-case-edit-dialog ref="relCaseEditDialog" :article="article"></rel-case-edit-dialog>
    <!-- 인명부에서의 새로운 소송 등록 팝업 -->
    <add-new-case-dialog ref="addNewCaseDialog"></add-new-case-dialog>
    <!-- 인명부에서의 새로운 자문 등록 팝업 -->
    <add-new-advice-dialog ref="addNewAdviceDialog"></add-new-advice-dialog>
    <!-- !![2021.6.4] 고문계약에 묶인 고문자문사건 등록 팝업 -->
    <add-gomun-advice-dialog ref="addGomunAdviceDialog"></add-gomun-advice-dialog>

    <!-- 업무(진행내용) -->
    <rel-content-dialog ref="relContentDialog" :article="article"></rel-content-dialog>
    <!-- [2021.6.25] 업무(고문계약자동갱신) -->
    <ar-content-dialog ref="arContentDialog" :article="article"></ar-content-dialog>

  </v-container>
</template>

<script>
// @: filters
import strDateFormat from '@/filters/strDateFormat'
import strDateFormat2 from '@/filters/strDateFormat2'
import strDateFormat3 from '@/filters/strDateFormat3'
import numberFormat from '@/filters/numberFormat'
import costStatus from '@/filters/costStatus'

import cutString from '@/filters/cutString'
import nl2br from '@/filters/nl2br'

// @: dialogs
import clientAddDialog from '@/components/client/ClientAddDialog' // 인명부 수정
import editTxt1Dialog from '@/components/client/editTxt1Dialog' // 추가정보
import relPersonDialog from '@/components/client/relPersonDialog' // 관계인 팝업
import relSugumDialog from '@/components/client/relSugumDialog' // 수금 팝업

// import relCostDialog from '@/components/client/relCostDialog' // 비용 팝업
import relCostView from '@/components/client/relCostView' // 비용 뷰

// import relContractDialog from '@/components/client/relContractDialog' // 계약 팝업
// import editBosuDialog from '@/components/client/editBosuDialog' // 보수약정 팝업
import gomunContractDialog from '@/components/client/gomunContractDialog' // !![2021.6.1] 고문계약 팝업
//
import relContractView01 from '@/views/contract/relContractView01' // 소송/자문 개별사건계약 뷰

// import relTimesheetDialog from '@/components/client/relTimesheetDialog' // 타임시트 등록/수정 팝업
import relTimesheetView from '@/components/client/relTimesheetView' // 타임시트 뷰

import relCaseDialog from '@/components/client/relCaseDialog' // 연계사건(소송/자문) 등록 팝업
import relCaseEditDialog from '@/components/client/relCaseEditDialog' // 연계사건(소송/자문) 해제 팝업
import addNewCaseDialog from '@/components/client/addNewCaseDialog' // 인명부에서 소송 등록하는 팝업
import addNewAdviceDialog from '@/components/client/addNewAdviceDialog' // 인명부에서 자문 등록하는 팝업
import addGomunAdviceDialog from '@/components/client/addGomunAdviceDialog' // !![2021.6.4] 고문계약에 묶인 고문자문사건 등록 팝업

import relContentDialog from '@/components/client/relContentDialog' // 업무(진행내용) 팝업
import arContentDialog from '@/components/client/arContentDialog' // [2021.6.25] 업무(고문계약자동갱신) 팝업

// @: popups
import addCommentPop from '@/components/client/addCommentPop' // 댓글 팝업
import addFilePop from '@/components/client/addFilePop' // 첨부파일 팝업
import addShareLinkPop from '@/components/client/addShareLinkPop' // 공유링크 팝업

// @:[2021.6.2] vue-apexchart
import VueApexCharts from 'vue-apexcharts'

// @: tag components
import attachFileCategoryTag from '@/components/tags/attachFileCategoryTag' // 첨부파일,공유링크 카테고리 태그
import expandTextContent from '@/components/client/expandTextContent' // 특약사항 펼침 컴포넌트
import expandAdviceList from '@/components/client/expandAdviceList' // 고문계약에 묶인 자문사건 리스트 펼침 컴포넌트
import expandContractAmount from '@/components/client/expandContractAmount' // 고문계약에 묶인 고문료등 금액 등록폼

// @: pdfmake view
import {
  pdfMake,
  pdfViewStyle,
  menuTitle,
  rmTagReturnArray,
  viewType1,
  viewType2
} from '@/lib/pdfmakeView'

// @: pdfmake list
import { pdfListStyle } from '@/lib/pdfmakeList'

// !![2021.4.30]lwc vuex 모듈 불러오기
import { createNamespacedHelpers } from 'vuex'

// !! lwc vuex 모듈
const _Lwc_ = createNamespacedHelpers('lwc')

// 구분: 파이챠트 공통옵션
const pieChartOptions = {
  // 파이챠트 크기 조절
  // plotOptions: {
  //   pie: {
  //     customScale: 0.7
  //   }
  // },
  title: {
    text: ''
  },
  labels: [],
  // 소제목 - 차트의 왼쪽으로 정렬
  legend: {
    position: 'left'
  },
  // 분할영역 컬러지정
  // colors: [ '#EA80FC', '#42A5F5', '#9C27B0', '#4DB6AC', '#FFB74D', '#90A4AE', '#7E57C2' ],
  // 커스텀 툴팁
  tooltip: {
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      return `<div class="pa-2" style="background-color: ${w.globals.colors[seriesIndex]}">
        <span>${w.globals.labels[seriesIndex]}: ${numberFormat(series[seriesIndex])}</span>
        </div>`
    }
  }
}

// !! 현재 dir
const currDir = 'client'

export default {
  components: {
    clientAddDialog,
    editTxt1Dialog,
    relPersonDialog,
    relSugumDialog,
    // relCostDialog,
    relCostView,
    // relContractDialog,
    // editBosuDialog,
    gomunContractDialog,
    relContractView01,
    // relTimesheetDialog,
    relTimesheetView,
    relContentDialog,
    arContentDialog,
    addCommentPop,
    addFilePop,
    addShareLinkPop,
    relCaseDialog,
    relCaseEditDialog,
    addNewCaseDialog,
    addNewAdviceDialog,
    addGomunAdviceDialog,
    attachFileCategoryTag,
    apexchart: VueApexCharts,
    expandTextContent,
    expandAdviceList,
    expandContractAmount
  },

  data: () => ({
    // 구분: 아이콘 컬러
    mdiPlusBoxOutlineButtonColor: 'info darken-1',
    mdiListEditButtonColor: 'indigo accent-2',
    mdiTrashCanOutlineButtonColor: 'warning darken-1',
    mdiUploadButtonColor: 'primary lighten-1',
    mdiDownloadButtonColor: 'success lighten-1',
    mdiLinkShareIconColor: 'cyan darken-2',
    mdiOpenFileButtonColor: 'orange lighten-1',
    // 구분: 게시물 데이터
    articleId: 0,
    article: {},
    // 구분: 의뢰인/상대방/기타.. 의 유형에 따라 보여줄지 여부
    t1show: false, // 의뢰인이면 true
    t2show: false, // 상대방이면 true
    // 구분: 서브 컨텐츠 리스트
    relPersons: [], // 관계인
    relContents: [], // 진행내용
    relCommentAndFiles: [], // 댓글 + 첨부파일
    relCases: [], // 연계사건
    relContracts: [], // !![2021.6.2] 계약 => 고문계약을 의미하게 변했다.
    caseContracts: [], // !![2021.6.1] 개별사건계약
    relTimesheets: [], // 타임시트
    relCosts: [], // 비용
    relSugums: [], // 수금
    relElApprovals: [], // 전자결재
    // 구분: [2021.6.22] 연계사건의 아이디를 '1,3,4' 로 연결한 문자열
    rcIds: '',
    // 구분: 첨부파일
    overlay: false, // 첨부파일 다운로드 로딩시 오버레이
    // 구분: 각 항목 총합계 금액
    contractTotal: 0, // !![2021.6.1] 고문계약총합으로 의미가 변함
    caseContractTotal: 0, // 개별사건계약총합
    timesheetTotal: 0, // 타임시트 총합
    costTotal: 0, // 비용총합
    sugumTotal: 0, // 수금총합 - 수금관리현황 그래프의 총합
    // 구분: 업무 셀렉트
    upmuSelect: '전체',
    upmuSelects: [
      { text: '전체', value: '전체' },
      { text: '계약갱신', value: '계약갱신' },
      { text: '업무', value: '업무' }
    ],
    // 구분: 연계사건 메뉴 팝업 열기
    relCaseMenu: false,
    // 구분: pdf view
    pdfDoc: {
      styles: null,
      defaultStyle: null,
      // pageOrientation: 'landscape', // !! 리스트는 가로로
      pageSize: 'A4',
      pageMargins: [ 30, 25, 30, 25 ],
      content: []
    },
    // 구분: pdf 리스트
    pdfLst: {
      styles: null,
      defaultStyle: null,
      pageOrientation: 'landscape', // !! 리스트는 가로로
      pageSize: 'A4',
      pageMargins: [ 20, 20, 20, 20 ],
      content: []
    },
    // 구분: 존재하는 아이디 인지 여부
    isId: true,
    // 구분: 차트옵션과 시리즈
    // @: 고문계약 2개의 옵션과 시리즈
    contractChartOption1: {
      title: { text: '' },
      labels: []
    },
    contractChartOption2: {
      title: { text: '' },
      labels: []
    },
    contractSeries1: [100, 1],
    contractSeries2: [1, 50, 100],
    // @: 개별사건계약 1개의 옵션과 시리즈
    caseConChartOption1: {
      title: { text: '' },
      labels: []
    },
    caseConSeries1: [50, 100, 1],
    // @: 연계사건 2개의 옵션과 시리즈
    relCaseChartOption1: {
      title: { text: '' },
      labels: []
    },
    relCaseChartOption2: {
      title: { text: '' },
      labels: []
    },
    relCaseSeries1: [100, 1],
    relCaseSeries2: [1, 50, 100],
    // @: 타임시트용 2개의 옵션과 시리즈
    timeSheetChartOption1: {
      title: { text: '' },
      labels: []
    },
    timeSheetChartOption2: {
      title: { text: '' },
      labels: []
    },
    timeSheetSeries1: [],
    timeSheetSeries2: [],
    // @: 비용 2개의 옵션과 시리즈
    costChartOption1: {
      title: { text: '' },
      labels: []
    },
    costChartOption2: {
      title: { text: '' },
      labels: []
    },
    costSeries1: [],
    costSeries2: [],
    // @: 수금 2개의 옵션과 시리즈
    sugumChartOption1: {
      title: { text: '' },
      labels: []
    },
    sugumChartOption2: {
      title: { text: '' },
      labels: []
    },
    sugumSeries1: [10, 10, 10, 10, 10], // [1, 10, 0, 20, 50],
    sugumSeries2: [10, 10, 10, 10], // [0, 10, 30, 10],
    // 구분: 수금차트 1(수금대상)의 데이터 - 배열로 할 수 없다!(실시간 반영이 안되므로)
    smt1: 0,
    smt2: 0,
    smt3: 0,
    smt4: 0,
    smt5: 0,
    // * 구분: 수금차트 1의 모든 항목을 합한것, [2022.5.24]수금대상금액 그래프의 총합
    sugumChartSum: 0,
    // 구분: 수금차트 2(수금관리현황)의 데이터 - 배열로 할 수 없다!(실시간 반영이 안되므로)
    scd21: 0, // 완료 2022.5.24 - 완료만 남긴다. // 완료+포기
    scd22: 0, // 청구
    scd23: 0, // 미청구
    scd24: 0, // 그외
    // 구분: 차트용 변수들
    // @: 고문계약
    gomunFee: 0, // 고문료 합산값
    timeFee: 0, // 시간보수 합산값
    // @: 연계사건
    relCaseType1: 0, // 소송 갯수
    relCaseType2: 0, // 자문 갯수
    relCaseStatus1: 0, // 진행 갯수
    relCaseStatus2: 0, // 종결 갯수
    relCaseStatus99: 0, // 그외 갯수
    // 구분: 부모인 인명부에서 따로 패칭할 객체
    pInfo: {
      id: 0,
      teamId: 1,
      type: 1,
      gubun1: '의뢰인',
      gubun2: '개인',
      gubun3: '',
      mgroup1: '',
      mgroup2: '',
      mgroup3: '',
      mgroup4: '',
      mgroup5: '',
      name: '',
      national: '',
      isStar: 0,
      isGomun: 0,
      address: '',
      hp: '',
      tel: '',
      fax: '',
      email: '',
      str1: '',
      str2: '',
      wemail: '',
      wname: '',
      createdAt: null,
      updatedAt: null
    }
  }),

  watch: {
    // !!중요: /client 내부에서 라우트 변화를 감지하여 동적으로 변경해준다.
    '$route' (to, from) {
      // !! 넘겨준 파라미터가 있는 경우. 등록시엔 -R을 제거해야 한다.
      const paramIds = to.params.id.split('-')
      if (paramIds.length > 1 && paramIds[1] === 'E') {
        // !! 수정시엔 '-E' 가 붙어서 넘어온다
        this.articleId = Number(paramIds[0])
        // 다시 해당 아이디로 리다이렉트 시킨다.
        this.$router.push(`/${currDir}/${this.articleId}`)
      } else {
        // !! 수정이 아닌 경우
        this.articleId = Number(paramIds[0])
        // 넘어온 id로 해당 게시물 정보를 가져와야 한다
        this.getArticle(this.articleId)
      }
    }
  },

  computed: {
    // 중요: lwc 모듈 게터
    ..._Lwc_.mapGetters([
      'isMyTeam',
      'getTeamNameByTid',
      'getMyAuth'
    ])
  },

  mounted () {
    // 중요: 넘어온 id를 매칭시켜야 한다!
    // 등록시엔 '-R' 이 붙어오므로 분리해서 적용한다. 안그러면 뷰페이지에서 목록으로 보낼때마다 초기화 해버린다.
    const paramIds = this.$route.params.id.split('-')
    this.articleId = Number(paramIds[0])

    // 넘어온 id로 해당 게시물 정보를 가져와야 한다
    this.getArticle(this.articleId)
  },

  methods: {
    strDateFormat,
    strDateFormat2,
    strDateFormat3,
    numberFormat,
    costStatus,
    cutString,
    nl2br,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    // 목록으로 - 주의: client 보내는 것 주의
    goList () {
      this.$router.push(`/${currDir}`)
    },
    // 중요: 리스트를 리프레시 한다.
    refreshList (param = '') {
      this.$router.push(`/${currDir}/${param}`)
    },
    // 구분: 팀정보 에러시 처리하는 함수
    async popTeamError (msg) {
      try {
        this.isId = false
        this.overlay = true
        // 팝업 에러창
        const pop = await this.findParentRefs(this.$parent, 'ConfirmDialogNoCancel')
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        if (await pop.open('접근권한', msg, { color: 'warning', width: 400 })) {
          this.refreshList(`LR`)
        } else {
          this.refreshList(`LR`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: id로 게시물을 가져온다
    async getArticle (id) {
      try {
        if (!id) throw new Error('[ERR-0] 잘못된 인수형식 입니다.')

        const { data } = await this.$axios.get(`lawork/${currDir}/getClient/${id}`)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        if (data.article) {
          this.article = data.article

          // 중요: 2021.2.1 들어갈 수 없는 팀이면 에러를 내고 리스트로 백한다.
          if (this.article.teamError) {
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${this.article.teamName})</b>에 속한 데이터입니다.`)
          }

          // 관리그룹 문자열 만들기
          this.article.mgroupStr = [ data.article.mgroup1, data.article.mgroup2 ].filter(g => g).join(' | ')

          // !! 의뢰인(type=1),상대방(2),기타(3)로 나뉘는 인명부 유형에 따라 보여줄 섹션을 정한다!
          // 의뢰인은 모든 섹션을 보고
          // 상대방은 연계사건까지 보인다.
          // 기타의뢰인은 관계인만 패칭한다
          if (this.article.type === 1) { // 의뢰인
            this.t1show = true
          } else if (this.article.type === 2) { // 상대방
            this.t2show = true
          }
          // 중요: 진행내용(업무,댓글,첨부파일), 관계인, 연계사건, 수금, 계약, 전자결재 목록 패칭
          // !![2021.6.2]계약 -> 고문계약/개별사건계약으로 나뉜다.
          await this.getSubContents() // 초기엔 모든 서브 컨텐츠 패칭
          // !! 진행내용의 정렬 항목 초기화
          this.upmuSelect = '전체'

          // 사용안함:[2021.6.1 폐기] 연계사건(소송/자문)에 엮인 수금,비용,타임시트,계약의 리스트를 패칭한다!
          // await this.getRelCaseContents()

          // 중요:[2021.6.21 신설] 개별사건계약 패칭처리
          await this.getCaseContracts(id)

          // console.log(`this.rcIds: ${this.rcIds}`)
          // 중요:[2021.6.22 신설] 타임시트 패칭처리
          await this.getTimeSheets(id, this.rcIds)

          // 중요:[2021.6.22 신설] 비용 패칭처리
          await this.getCosts(id, this.rcIds)

          // 중요:[2021.6.28 신설] 수금 차트 처리
          await this.drawSugumChart()

          // 중요: 전자결재(5)는 따로 패칭해야 한다.
          await this.getRelEaByIdType()
          //
          // !![2021.3.19]pInfo 를 채우는 작업
          for (let key in this.pInfo) {
            this.pInfo[key] = this.article[key]
          }
          this.article.pInfo = this.pInfo // 구찮으니 걍 this.article 에 넣어버리자
          //
        } else {
          // !! 이미 삭제된 데이터인 경우 처리
          await this.popTeamError(`진행할 수 없습니다!<br>삭제되었거나 담당부서가 변경된 데이터입니다.`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 인명부 수정
    async editArticle () {
      try {
        const result = await this.$refs.clientAddDialog.open('인명부', { width: 650, height: 670 }, this.articleId, this.article)
        if (result) {
          if (result.isDeleted) {
            // 삭제된 데이터인 경우
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) 이미 삭제되었습니다.`)
            //
          } else if (result.teamError) {
            // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
            //
          } else {
            // 정상적으로 수정하는 경우 리프레시
            this.refreshList(`${result.affectedId}-E`)
            //
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 인명부 삭제
    async deleteArticle () {
      try {
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = (this.article.type === 1) ? '삭제시 의뢰인 정보 및 고문계약 하위의 자문사건,<br>연계된 개별수임계약도 함께 삭제됩니다.' : '정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.'
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          // !! [2021.2.4] 삭제시 더 많은 정보를 넘길 수 있게 post 형식으로 변경함.
          const form = {
            id: this.articleId,
            oriTeamId: this.article.teamId // 상세페이지에 있는 원래의 팀아이디
          }
          const { data } = await this.$axios.post(`lawork/${currDir}/deleteClient`, form)
          if (data.isDeleted) {
            // 삭제된 데이터인 경우
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) 이미 삭제되었습니다.`)
            //
          } else if (data.teamError) {
            // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 삭제불가
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${data.chInfo.info1})</b>에 속한 데이터입니다.`)
            //
          } else {
            // 정상적으로 삭제하는 경우 리프레시
            this.refreshList(`LR`)
            //
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: --- 연계 사건 ---
    // #1 등록 [2021.6.16 수정함]
    async setRelCase (type1) {
      try {
        const popTitle = type1 === 1 ? '연계소송' : '연계자문'
        const option = { width: 600, height: 480 }
        const result = await this.$refs.relCaseDialog.open(popTitle, option, type1)
        if (result) {
          if (result.isDeleted) {
            // 삭제된 데이터인 경우
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) 이미 삭제되었습니다.`)
          } else if (result.teamError) {
            // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
          } else {
            // 정상인 경우 리스트 리프레시
            await this.getSubContents(33)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // #2 해제/취소 팝업 [2021.6.16 추가]
    async popEditRelCase (type1, lwcommon) {
      try {
        // console.log(lwcommon.rInfo.type3) // 1 이면 고문자문
        const popTitle = type1 === 1 ? '연계소송' : '연계자문'
        const option = { width: 600, height: 400 }
        const result = await this.$refs.relCaseEditDialog.open(popTitle, option, type1, lwcommon)
        if (result) {
          if (result.isDeleted) {
            // 삭제된 데이터인 경우
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) 이미 삭제되었습니다.`)
          } else if (result.teamError) {
            // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
            //
          } else if (result.rsMsg === 'removed') {
            // 메시지가 해제로 넘어온 경우 상세페이지를 전체 리프레시한다
            await this.getSubContents()
            //
          } else {
            // 정상인 경우 리스트 리프레시
            await this.getSubContents(33)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: --- 추가정보 팝업 ---
    async editTxt1 () {
      try {
        const result = await this.$refs.editTxt1.open('추가정보', { width: 750 })
        if (result) {
          if (result.isDeleted) {
            // 삭제된 데이터인 경우
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) 이미 삭제되었습니다.`)
          } else if (result.teamError) {
            // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 업무(진행내용) 등록/수정/삭제 팝업
    async popRelContent (relContent) {
      try {
        if (relContent) { // 수정/삭제
          // 구분: 업무 수정/삭제.
          const result = await this.$refs.relContentDialog.open('업무', { width: 650 }, 1, relContent)
          if (result) {
            if (result.isDeleted) {
              // 부모가 이미 삭제된 데이터인 경우
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) 이미 삭제되었습니다.`)
              //
            } else if (result.teamError) {
              // 부모의 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
              //
            } else {
              // 정상인 경우 리스트 리프레시 => 삭제인 경우도 자동으로 리프레시된다.
              await this.changeUpmuSelect()
              this.refreshList(`${this.articleId}-E`)
              //
            }
          }
        } else { // 등록 모드
          // 구분: 업무 등록
          const result = await this.$refs.relContentDialog.open('업무', { width: 650 }, -1, null)
          if (result) {
            if (result.isDeleted) {
              // 삭제된 데이터인 경우
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) 이미 삭제되었습니다.`)
              //
            } else if (result.teamError) {
              // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
              //
            } else {
              // 정상인 경우 리스트 리프레시
              await this.changeUpmuSelect()
              this.refreshList(`${this.articleId}-E`) // 리스트 리프레시 - 주의: 리스트까지 리프레시 하기위해
              //
            }
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분:[2021.6.25]추가 - 고문계약자동갱신 등록/수정/삭제 팝업
    // 주의: 업무지만 기한으로 취급
    async popAutoRenewal (arContent) {
      try {
        if (arContent) { // 수정/삭제
          // 구분: 수정/삭제.
          const result = await this.$refs.arContentDialog.open('업무', { width: 650 }, 1, arContent)
          if (result) {
            if (result.isDeleted) {
              // 부모가 이미 삭제된 데이터인 경우
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) 이미 삭제되었습니다.`)
              //
            } else if (result.teamError) {
              // 부모의 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
              //
            } else {
              // 정상인 경우 리스트 리프레시 => 삭제인 경우도 자동으로 리프레시된다.
              await this.changeUpmuSelect()
              this.refreshList(`${this.articleId}-E`)
              //
            }
          }
        } else { // 등록 모드
          // 구분: 등록
          const result = await this.$refs.arContentDialog.open('업무', { width: 650 }, -1, null)
          if (result) {
            if (result.isDeleted) {
              // 삭제된 데이터인 경우
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) 이미 삭제되었습니다.`)
              //
            } else if (result.teamError) {
              // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
              //
            } else {
              // 정상인 경우 리스트 리프레시
              await this.changeUpmuSelect()
              this.refreshList(`${this.articleId}-E`) // 리스트 리프레시 - 주의: 리스트까지 리프레시 하기위해
              //
            }
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 관계인 등록/수정 팝업
    async popRelPerson (relPerson) {
      try {
        if (relPerson) { // 수정 모드
          if (await this.$refs.relPersonDialog.open('관계인', { width: 600 }, 1, relPerson)) {
            await this.getSubContents(35)
          }
        } else { // 등록 모드
          if (await this.$refs.relPersonDialog.open('관계인', { width: 600 }, -1, null)) {
            await this.getSubContents(35)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 수금 등록/수정 팝업
    async popRelSugum (relSugum) {
      try {
        if (relSugum) {
          // @: #1) 수정/삭제 모드
          const result = await this.$refs.relSugumDialog.open('수금', { width: 700 }, 1, relSugum)
          if (result) {
            if (result.isDeleted) {
              // 삭제된 데이터인 경우
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) 이미 삭제되었습니다.`)
            } else if (result.teamError) {
              // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
            } else {
              // 정상인 경우 타임시트 목록만 리프레시
              await this.getSubContents(24)
              await this.drawSugumChart()
              // setTimeout(() => {
              //   this.drawSugumChart()
              // }, 100)
              // this.sugumSeries2 = [0, 0, 0, 0]
              // this.getArticle(this.articleId)
            }
          }
        } else {
          // @: #2) 등록 모드
          const result = await this.$refs.relSugumDialog.open('수금', { width: 700 }, -1, null)
          if (result) {
            if (result.isDeleted) {
              // 삭제된 데이터인 경우
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) 이미 삭제되었습니다.`)
            } else if (result.teamError) {
              // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
            } else {
              // 정상인 경우 타임시트 목록만 리프레시
              // this.getArticle(this.articleId)
              await this.getSubContents(24)
              await this.drawSugumChart()
            }
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: --- 비용 등록/수정 팝업 ---
    // !![2021.6.22] 새롭게 연계사건에 등록된 비용을 뷰만하는 함수로 변경됨
    async viewCost (cost) {
      try {
        cost.teamName = this.article.teamName
        cost.teamColor = this.article.teamColorOrigin
        cost.clientId = this.articleId
        await this.$refs.relCostView.open('비용', { width: 600 }, cost)
      } catch (e) {
        this.sbpop(e)
      }
    },
    // [2021.6.22] 비용 목록,차트 등 패칭
    async getCosts (id, rcIds) {
      try {
        if (!id || !rcIds) return

        // [2021.6.28] 수금차트데이터 1(실비) - 비용 초기화
        this.smt5 = 0

        const params = {
          type: 23,
          rType: 3,
          rId: id || this.articleId,
          pId: rcIds
        }
        // 의뢰인과 연계사건에 묶인 타임시트를 패칭한다.
        const { data } = await this.$axios.post(`lawork/${currDir}/getCostRelClient`, params)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        this.relCosts = data.list
        if (data.list.length > 0) {
          let totalSum = 0
          // 목록 패칭
          data.list.forEach(item => {
            if (item.pInfo) {
              item.pInfo = JSON.parse(item.pInfo)
              item.dcl = item.pInfo.clName ? `${item.pInfo.clType ? `[${item.pInfo.clType}]` : ''}${item.pInfo.clName}` : ''
              item.op = item.pInfo.opName ? `${item.pInfo.opType ? `[${item.pInfo.opType}]` : ''}${item.pInfo.opName}` : ''
              item.dop = (item.dcl && item.op) ? `/${item.op}` : item.op
            }

            totalSum += Number(item.str2)
          })
          this.costTotal = totalSum

          // [2021.6.28] 수금차트데이터 1(실비) 생성
          this.smt5 = totalSum

          // !! 차트 데이터
          const { cdata1, cdata2, cdata3, cdata4 } = data

          // @: 차트 1
          this.costChartOption1 = {
            ...pieChartOptions,
            ...{
              chart: {
                width: 290,
                type: 'pie'
              },
              noData: {
                text: '데이터가 없습니다.'
              },
              title: {
                text: '항목현황',
                margin: 0,
                style: {
                  fontWeight: 'normal'
                }
              },
              labels: cdata1
            }
          }
          // 데이터 매칭
          this.$nextTick(() => {
            this.costSeries1 = cdata2
          })

          // @: 차트 2
          this.costChartOption2 = {
            ...pieChartOptions,
            ...{
              chart: {
                width: 290,
                type: 'pie'
              },
              noData: {
                text: '데이터가 없습니다.'
              },
              title: {
                text: '납부처별 현황',
                margin: 0,
                style: {
                  fontWeight: 'normal'
                }
              },
              labels: cdata3,
              colors: [ '#EA80FC', '#42A5F5', '#9C27B0', '#4DB6AC', '#FFB74D', '#90A4AE', '#7E57C2' ]
            }
          }
          // 데이터 매칭
          this.$nextTick(() => {
            this.costSeries2 = cdata4
          })
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 사용안함:[2021.6.22] 아래는 기존 등록/수정 팝업
    // async popRelCost (relCost) {
    //   try {
    //     if (relCost) {
    //       // @: #1) 수정/삭제 모드
    //       const result = await this.$refs.relCostDialog.open('비용', { width: 600 }, 1, relCost)
    //       if (result) {
    //         if (result.isDeleted) {
    //           // 삭제된 데이터인 경우
    //           await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) 이미 삭제되었습니다.`)
    //         } else if (result.teamError) {
    //           // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
    //           await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
    //         } else {
    //           // 정상인 경우 타임시트 목록만 리프레시
    //           await this.getSubContents(23)
    //         }
    //       }
    //     } else {
    //       // @: #2) 등록 모드
    //       const result = await this.$refs.relCostDialog.open('비용', { width: 600 }, -1, null)
    //       if (result) {
    //         if (result.isDeleted) {
    //           // 삭제된 데이터인 경우
    //           await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) 이미 삭제되었습니다.`)
    //         } else if (result.teamError) {
    //           // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
    //           await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
    //         } else {
    //           // 정상인 경우 타임시트 목록만 리프레시
    //           await this.getSubContents(23)
    //         }
    //       }
    //     }
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // 구분: --- 타임시트 등록/수정 팝업 ---
    // !![2021.6.22] 새롭게 연계사건에 등록된 타임시트를 뷰만하는 함수로 변경됨
    async viewTimesheet (ts) {
      try {
        // console.log(ts)
        ts.teamName = this.article.teamName
        ts.teamColor = this.article.teamColorOrigin
        ts.clientId = this.articleId
        await this.$refs.relTimesheetView.open('타임시트', { width: 600 }, ts)
      } catch (e) {
        this.sbpop(e)
      }
    },
    // [2021.6.22] 목록,차트 등 패칭
    async getTimeSheets (id, rcIds) {
      try {
        //
        if (!id || !rcIds) return

        // [2021.6.28] 수금차트데이터 1(수금대상) - 시간보수 초기화
        this.smt4 = 0

        const params = {
          type: 26,
          rType: 3,
          rId: id || this.articleId,
          pId: rcIds
        }
        // 의뢰인과 연계사건에 묶인 타임시트를 패칭한다.
        const { data } = await this.$axios.post(`lawork/${currDir}/getTimesheetRelClient`, params)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        this.relTimesheets = data.list
        if (data.list.length > 0) {
          let totalSum = 0
          // 목록 패칭
          data.list.forEach(item => {
            if (item.pInfo) {
              item.pInfo = JSON.parse(item.pInfo)
              item.dcl = item.pInfo.clName ? `${item.pInfo.clType ? `[${item.pInfo.clType}]` : ''}${item.pInfo.clName}` : ''
              item.op = item.pInfo.opName ? `${item.pInfo.opType ? `[${item.pInfo.opType}]` : ''}${item.pInfo.opName}` : ''
              item.dop = (item.dcl && item.op) ? `/${item.op}` : item.op
            }

            totalSum += Number(item.str2)
          })
          this.timesheetTotal = totalSum

          // [2021.6.28] 수금차트데이터 1(수금대상) 생성
          this.smt4 = totalSum

          // !! 차트 데이터
          const { workers, amounts, times } = data

          // @: 차트 1
          // 데이터 매칭
          this.$nextTick(() => {
            this.timeSheetSeries1 = amounts
          })
          // 옵션매칭
          this.$nextTick(() => {
            this.timeSheetChartOption1 = {
              ...pieChartOptions,
              ...{
                chart: {
                  type: 'pie',
                  width: 290
                },
                noData: {
                  text: '데이터가 없습니다.'
                },
                title: {
                  text: '업무자현황',
                  margin: 0,
                  style: {
                    fontWeight: 'normal'
                  }
                },
                labels: workers
              }
            }
            //
          })

          // @: 차트 2
          // 데이터 매칭
          this.$nextTick(() => {
            this.timeSheetSeries2 = times
          })
          // 옵션매칭
          this.$nextTick(() => {
            this.timeSheetChartOption2 = {
              ...pieChartOptions,
              ...{
                chart: {
                  type: 'pie',
                  width: 290
                },
                noData: {
                  text: '데이터가 없습니다.'
                },
                title: {
                  text: '업무자별 업무시간현황',
                  margin: 0,
                  style: {
                    fontWeight: 'normal'
                  }
                },
                labels: workers,
                colors: [ '#EA80FC', '#42A5F5', '#9C27B0', '#4DB6AC', '#FFB74D', '#90A4AE', '#7E57C2' ],
                // !! 커스텀 툴팁
                tooltip: {
                  custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    const toMinute = series[seriesIndex] // 분으로 환산된 시간
                    let _tts = `${parseInt((toMinute / 60), 10)}`
                    let _mms = `${toMinute % 60}`
                    // if (_tts.length === 1) _tts = `0${_tts}`
                    if (_mms.length === 1) _mms = `0${_mms}`
                    return `<div class="pa-2" style="background-color: ${w.globals.colors[seriesIndex]}">
                      <span>${w.globals.labels[seriesIndex]}: ${_tts}:${_mms}</span>
                      </div>`
                  }
                }
              }
            }
            //
          })
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 사용안함:[2021.6.22] 아래는 기존 등록/수정 팝업
    // async popRelTimesheet (relTimesheet) {
    //   try {
    //     if (relTimesheet) {
    //       // @: #1) 수정/삭제 모드
    //       const result = await this.$refs.relTimesheetDialog.open('타임시트', { width: 600 }, 1, relTimesheet)
    //       if (result) {
    //         if (result.isDeleted) {
    //           // 삭제된 데이터인 경우
    //           await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) 이미 삭제되었습니다.`)
    //         } else if (result.teamError) {
    //           // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
    //           await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
    //         } else {
    //           // 정상인 경우 타임시트 목록만 리프레시
    //           await this.getSubContents(26)
    //         }
    //       }
    //     } else {
    //       // @: #2) 등록 모드
    //       const result = await this.$refs.relTimesheetDialog.open('타임시트', { width: 600 }, -1, null)
    //       if (result) {
    //         if (result.isDeleted) {
    //           // 삭제된 데이터인 경우
    //           await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) 이미 삭제되었습니다.`)
    //         } else if (result.teamError) {
    //           // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
    //           await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
    //         } else {
    //           // 정상인 경우 타임시트 목록만 리프레시
    //           await this.getSubContents(26)
    //         }
    //       }
    //     }
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // 구분: --- 계약 등록/수정 팝업 ---
    // 사용안함:[2021.5.10] 기존 계약 팝업
    // async popRelContract (relContract) {
    //   try {
    //     if (relContract) { // 수정 모드
    //       if (await this.$refs.relContractDialog.open('계약', { width: 800 }, 1, relContract)) {
    //         // 자동갱신등록 때문에 업무도 갱신해야 한다
    //         await this.getSubContents(25)
    //         await this.changeUpmuSelect()
    //       }
    //     } else { // 등록 모드
    //       if (await this.$refs.relContractDialog.open('계약', { width: 800 }, -1, null)) {
    //         // 자동갱신등록 때문에 업무도 갱신해야 한다
    //         await this.getSubContents(25)
    //         await this.changeUpmuSelect()
    //       }
    //     }
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // 구분: --- [2021.6.1] 고문계약 등록/수정 팝업 ---
    async setGomunContract (contract) {
      try {
        if (contract) { // 수정/삭제
          const result = await this.$refs.gomunContractDialog.open('고문계약', { width: 800 }, 1, contract)
          if (result) {
            if (result.isDeleted) {
              // 삭제된 데이터인 경우
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) 이미 삭제되었습니다.`)
            } else if (result.teamError) {
              // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
            } else {
              // await this.getSubContents(25)
              // await this.changeUpmuSelect()
              // * 리스트에도 고문을 지우거나 나타내기 위해 리프레시 리스트를 해야함
              this.refreshList(`${this.articleId}-E`)
            }
          }
        } else { // 등록
          const result = await this.$refs.gomunContractDialog.open('고문계약', { width: 800 }, -1, null)
          if (result) {
            if (result.isDeleted) {
              // 삭제된 데이터인 경우
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) 이미 삭제되었습니다.`)
            } else if (result.teamError) {
              // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
            } else {
              // await this.getSubContents(25)
              // await this.changeUpmuSelect()
              // * 리스트에도 고문을 지우거나 나타내기 위해 리프레시 리스트를 해야함
              this.refreshList(`${this.articleId}-E`)
            }
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: --- [2021.6.1] 개별사건계약 view 팝업 ---
    // 개별사건계약은 등록이나 수정/해제가 없다. 수정 못하는 뷰만 팝업한다.
    async caseContractPopup (contract) {
      try {
        // !! 팀명과 팀컬러를 덧붙여서 보낸다.
        contract.teamName = this.article.teamName
        contract.teamColor = this.article.teamColorOrigin // 이걸 보내야 한다.
        await this.$refs.relContractView01.open('개별사건계약', { width: 800 }, contract)
      } catch (e) {
        this.sbpop(e)
      }
    },
    // [2021.6.21] 개별사건계약 목록,차트 등 패칭
    async getCaseContracts (id) {
      try {
        // !![2021.6.28] - 수금차트데이터 초기화
        this.smt1 = 0 // 착수금
        this.smt2 = 0 // 성공보수

        // console.log(this.articleId)
        const { data } = await this.$axios.get(`lawork/${currDir}/getCaseContracts/${id}`)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        this.caseContracts = data.list
        if (data.list.length > 0) {
          let cccd1 = 0 // 착수금
          let cccd2 = 0 // 성공보수
          let cccd3 = 0 // 시간보수
          // 목록 패칭
          data.list.forEach(item => {
            if (item.pInfo) {
              item.pInfo = JSON.parse(item.pInfo)
              item.dcl = item.pInfo.clName ? `${item.pInfo.clType ? `[${item.pInfo.clType}]` : ''}${item.pInfo.clName}` : ''
              item.op = item.pInfo.opName ? `${item.pInfo.opType ? `[${item.pInfo.opType}]` : ''}${item.pInfo.opName}` : ''
              item.dop = (item.dcl && item.op) ? `/${item.op}` : item.op
            }

            // 착수금,성공보수,시간보수 합산.
            if (item.txt1) {
              const amounts = JSON.parse(item.txt1)
              for (const item of amounts) {
                cccd1 += Number(item.amt1) // 착수금 합산
                cccd2 += Number(item.amt2) // 성공보수 합산
                cccd3 += Number(item.amt3) // 시간보수 합산
              }
              // 착수금, 성공보수, 시간보수를 모두 합산한 값
              this.caseContractTotal = cccd1 + cccd2 + cccd3
            }
          })

          // @: 차트 1 - 개별사건계약 > 보수현황 차트 완성
          this.caseConChartOption1 = {
            ...pieChartOptions,
            ...{
              chart: {
                width: 300, // '90%', // 주의:%로 하면 브라우저 사이즈에 유동적으로 반응하지만 리렌더링이 안된다.
                type: 'pie'
              },
              noData: {
                text: '데이터가 없습니다.'
              },
              title: {
                text: '보수현황',
                margin: 0,
                style: {
                  fontWeight: 'normal'
                }
              },
              labels: ['착수금', '성공보수', '시간보수'],
              colors: [ '#F57C00', '#7CB342', '#0277BD', '#9E9D24', '#FFB74D', '#90A4AE', '#7E57C2' ]
            }
          }
          // 보수현황 차트 데이터 매칭
          this.$nextTick(() => {
            this.caseConSeries1 = [ cccd1, cccd2, cccd3 ] // [] //  no data 적용
            this.smt1 = cccd1 // 착수금
            this.smt2 = cccd2 // 성공보수
          })
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 2021.6.28신설 - 수금차트 생성
    async drawSugumChart () {
      try {
        // @: 차트 1 - 수금대상 차트 완성
        this.sugumChartOption1 = {
          ...pieChartOptions,
          ...{
            chart: {
              width: 310, // '90%', // 주의:%로 하면 브라우저 사이즈에 유동적으로 반응하지만 리렌더링이 안된다.
              type: 'pie'
            },
            noData: {
              text: '데이터가 없습니다.'
            },
            title: {
              text: '수금대상',
              margin: 0,
              style: {
                fontWeight: 'normal'
              }
            },
            labels: ['착수금', '성공보수', '고문료', '시간보수', '비용']
          }
        }
        // 수금대상 차트 데이터 매칭
        this.$nextTick(() => {
          this.sugumSeries1 = [this.smt1, this.smt2, this.smt3, this.smt4, this.smt5] //  no data 적용

          // 수금차트 데이터 1의 항목을 합산
          let sum = 0
          for (const sugum of this.sugumSeries1) {
            sum += sugum
          }
          this.sugumChartSum = sum
        })

        // @: 차트 2 - 수금관리현황 차트 완성
        this.sugumChartOption2 = {
          ...pieChartOptions,
          ...{
            chart: {
              width: 310, // '90%', // 주의:%로 하면 브라우저 사이즈에 유동적으로 반응하지만 리렌더링이 안된다.
              type: 'pie'
            },
            noData: {
              text: '데이터가 없습니다.'
            },
            title: {
              text: '수금관리현황',
              margin: 0,
              style: {
                fontWeight: 'normal'
              }
            },
            labels: ['완료', '청구', '미청구', '그외'],
            colors: [ '#EA80FC', '#42A5F5', '#9C27B0', '#4DB6AC', '#FFB74D', '#90A4AE', '#7E57C2' ]
          }
        }
        // 수금대상 차트 데이터 매칭
        this.$nextTick(() => {
          // 순서대로: 완료, 청구, 미청구, 그외
          this.sugumSeries2 = [ this.scd21, this.scd22, this.scd23, this.scd24 ] // [] //  no data 적용
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: --- 중요: 넘어온 타입과 리스트로 리스트 정리 작업 패칭 ---
    // !![2021.6.22] 타임시트.비용 제외 .. 차후 수금도 제외되려나?
    async setSubContentList ({ type, list }) {
      try {
        // 합계금액 초기화
        // if (type === 23) this.costTotal = 0 // 비용
        // if (type === 26) this.timesheetTotal = 0 // 타임시트

        if (type === 24) {
          this.sugumTotal = 0 // 수금관리현황의 총합

          this.scd21 = 0 // 완료
          this.scd22 = 0 // 청구
          this.scd23 = 0 // 미청구
          this.scd24 = 0 // 그외
        }

        // !![2021.6.1] 고문계약/개별사건계약으로 나뉜다.
        if (type === 25) {
          this.contractTotal = 0 // 고문계약 계약금액 총합
          this.caseContractTotal = 0 // 개별사건계약 계약금액 총합

          this.gomunFee = 0 // 차트용 고문료 합산값
          this.timeFee = 0 // 시간보수 합산값

          // [2021.6.28] 수금차트데이터(고문료) 초기화
          this.smt3 = 0
        }

        if (type === 33) { // 연계사건
          this.relCaseType1 = 0
          this.relCaseType2 = 0
          this.relCaseStatus1 = 0
          this.relCaseStatus2 = 0
          this.relCaseStatus99 = 0
        }

        if (list.length > 0) {
          list.map(item => {
            // [2021.4.15] 시간 따로 빼기
            item.tm = item.date1.substr(11, 5) // 시-분 '11:30'

            if (type === 22) {
              // 구분: 업무(진행내용) - relContents
              //
              item.view1 = ''
              item.view2 = ''
              if (item.subtype !== 0) { // 등록같은 자동입력이 아닌 경우
                let view1Items = []
                // 장소 처리
                if (item.str3) view1Items.push(item.str3)
                // !![2021.4.7 수정] 시간 처리
                if (!item.noTime) { view1Items.push(item.tm) } // 시간없음이 아닌 경우
                // view1 = (장소 + 시간)
                if (view1Items.length > 0) {
                  item.view1 = `(${view1Items.join(' ')})`
                }

                // view2 = 내용 처리
                if (item.subtype2 === 1) {
                  // !![2021.6.25] 고문계약자동갱신기준일인 경우
                  item.view2 = item.str1
                } else {
                  item.view2 = item.str1 ? ` - ${item.str1} ` : ''
                }
              } else {
                // 등록같은 자동입력인 경우
                item.view1 = '인명부등록'
              }

              // 클립보드에 복사할 텍스트 만들기
              item.copyText = `${this.article.name} ${strDateFormat3(item.date1)} [${item.gubun1}] ${item.view1} ${item.str1 ? `- ${item.str1}` : ''} [${item.gubun2}]`
              //
            } else if (type === 24) {
              // 구분: 수금 - relSugums
              //
              // 수금관리현황의 총합
              item.str2 = item.str2 ? Number(item.str2) : 0
              this.sugumTotal += item.str2

              // * [2021.6.28] 완료(3)+포기(4) / 청구(2) / 미청구(1) / 그외 별로 합산
              // * [2022.5.24] 완료+포기 => 완료만 남긴다. 포기는 그외로 뺀다.
              // !!주의:[2021.10.22] 카테고리상 미청구가 1, 청구가 2임. 이렇게 DB에 입력되어있다.
              // if (item.status === 3 || item.status === 4) {
              if (item.status === 3) { // 완료만 남긴다.
                // 완료
                this.scd21 += item.str2
              } else if (item.status === 2) { // !! 청구는 1
                // 청구
                this.scd22 += item.str2
              } else if (item.status === 1) { // !! 미청구는 2
                // 미청구
                this.scd23 += item.str2
              } else {
                // 그외 - 2022.5.24 포기가 이쪽으로 속하게 된다.
                this.scd24 += item.str2
              }
              // console.log('sugum: ', item.str2)

              // !! 수금지체 처리 - 중요: 미청구는 1임
              // 미청구(1) 완료(3) 포기(4) 를 제외하고 등록된 날짜가 오늘보다 과거인 경우
              item.view1 = ''
              if (!(item.status === 1 || item.status === 3 || item.status === 4)) {
                if (this.$moment().diff(item.date1, 'days') > 0) { // 오늘을 기준으로 뺐을 때 0보다 크면 지난 것이다.
                  item.view1 = '수금지체'
                }
              }

              // 클립보드에 복사할 텍스트 만들기
              let tmpTxt = (item.gubun2 === '미발행') ? '(미발행)' : `(${strDateFormat3(item.date2)} ${item.gubun2} 발행)`
              item.copyText = `${this.article.name} ${strDateFormat3(item.date1)} [${item.gubun1}] ${numberFormat(item.str2)}원 ${tmpTxt} ${item.str1 ? `- ${item.str1}` : ''} [${item.gubun3}] ${item.view1 ? `[${item.view1}]` : ''}`
              //
            } else if (type === 23) {
              // 사용안함:[2020.6.22] 비용 - relCosts
              // 총합
              // item.str2 = item.str2 ? Number(item.str2) : 0
              // this.costTotal += item.str2

              // // 클립보드에 복사할 텍스트 만들기
              // item.copyText = `${this.article.name} ${strDateFormat3(item.date1)} [${item.gubun1}] ${item.gubun2} ${numberFormat(item.str2)}원 ${item.gubun3} ${item.str1 ? `- ${item.str1}` : ''} [${costStatus(item.status)}]`
              //
            } else if (type === 25) {
              // 구분: 고문계약 - relContracts
              //
              // [2021.6.2] 기존 계약에서 -> 고문계약리스트로 변경
              //
              // 계약금액(고문료+시간보수) 총합
              item.str3 = item.str3 ? Number(item.str3) : 0
              this.contractTotal += item.str3

              // !! 연계자문 갯수를 구한다.
              // !![2021.6.7] 고문계약의 txt3 필드에는 연계된 자문 아이디가 ','로 연결된 문자열이 들어있다.
              item.adviceCnt = 0
              if (item.txt3) {
                item.adviceCnt = item.txt3.split(',').length
              }

              // !! 차트를 위한 금액합산
              if (item.txt1) {
                const amounts = JSON.parse(item.txt1)
                for (const item of amounts) {
                  this.gomunFee += Number(item.amt1) // 고문료 합산
                  this.timeFee += Number(item.amt2) // 시간보수 합산
                }
                // [2021.6.28] 수금차트 데이터 3(고문료) 생성
                this.smt3 = this.gomunFee
              }

              // 클립보드에 복사할 텍스트 만들기
              // item.copyText = `${this.article.name} ${strDateFormat3(item.date1)} [${item.gubun1}] ${numberFormat(item.str2)}원 (${strDateFormat(item.date2)} ~ ${strDateFormat(item.date3)})${item.gubun2 ? ` ${item.gubun2}` : ''}${item.str1 ? ` - ${item.str1}` : ''} [${item.status ? '유효' : '해지'}]`
              item.copyText = `${this.article.name} ${strDateFormat3(item.date1)} ${item.gubun2} (${strDateFormat(item.date2)} ~ ${strDateFormat(item.date3)}) ${item.subtype2 === 1 ? '월별정산' : '일괄정산'} ${numberFormat(item.str4)}h`
              //
            } else if (type === 26) {
              // 사용안함:[2020.6.22] 타임시트 - relTimesheets
              //
              // 총합
              // item.str2 = item.str2 ? parseInt(item.str2, 10) : 0
              // this.timesheetTotal += item.str2
              // 시간:분 처리
              // item.view1 = item.str5.replace(/^0/g, '') // 맨 앞의 0 제거
              // 클립보드에 복사할 텍스트 만들기
              // item.copyText = `${this.article.name} ${strDateFormat3(item.date1)} [${item.gubun1}] (${item.view1}) ${numberFormat(item.str2)}원 ${item.str1 ? `- ${item.str1}` : ''}`
              //
            } else if (type === 33) {
              // 구분: 연계사건 - relCases
              //
              // 주의:[2021.3.30] 연계사건 정보 패칭
              item.rInfo = JSON.parse(item.rInfo)

              // 차트용 변수 세팅
              if (item.rType === 1) {
                this.relCaseType1++
              } else {
                this.relCaseType2++
              }
              if (item.rInfo.status === 1) {
                this.relCaseStatus1++
              } else if (item.rInfo.status === 2) {
                this.relCaseStatus2++
              } else {
                this.relCaseStatus99++
              }

              // 중요:[2021.10.13] 종국일자가 있는 경우 바르게 하기위해 포맷을 맞춰준다.
              if (item.rInfo && item.rInfo.endDate) {
                if (item.rInfo.endDate.length > 10) {
                  item.rInfo.endDate = this.$moment(item.rInfo.endDate).format('YYYY-MM-DD')
                }
              }
              //
            }
          })
        }
        return list
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 중요: 서브 컨텐츠 패칭 처리 메소드
    // !![2020.6.22] 타임시트, 비용 제외됨 - 차후 수금도 제외될까?
    async getSubContents (type = null, gubun1 = null) {
      try {
        const columns = { clientId: this.articleId, type, gubun1 }
        const { data } = await this.$axios.post(`lawork/${currDir}/getSubContents`, columns)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

        let rels = [
          { type: 22, name: 'relContents' }, // 업무
          { type: 24, name: 'relSugums' }, // 수금
          // { type: 23, name: 'relCosts' }, // 비용
          { type: 25, name: 'relContracts' }, // !! 계약 -> 고문계약으로 변함
          // { type: 26, name: 'relTimesheets' }, // 타임시트
          { type: 33, name: 'relCases' }, // 연계사건
          { type: 35, name: 'relPersons' } // 관계인
        ]

        // 넘어온 타입이 있는 경우
        if (type) {
          const [ r ] = rels.filter(c => c.type === type)
          const list = data.list.filter(c => c.type === r.type)
          this[r.name] = await this.setSubContentList({ type: r.type, list })
        } else { // 넘어온 타입이 없다(전체)
          rels.forEach(async (r) => {
            const list = data.list.filter(c => c.type === r.type)
            this[r.name] = await this.setSubContentList({ type: r.type, list })
          })
        }

        // !![2021.6.14] 파이차트 생성
        const { gaIds } = data
        await this.setChartSeries(gaIds)

        // !![2021.6.22] 연계사건 아이디가 넘어온 경우
        if (data.rcIds) {
          this.rcIds = data.rcIds
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 고문계약, 연계사건 차트 생성
    async setChartSeries (gaIds) {
      try {
        // --- 구분: 고문계약 ---
        // @: 차트 1 - 보수현황 차트 완성
        this.contractChartOption1 = {
          ...pieChartOptions,
          ...{
            chart: {
              width: 300, // '90%', // 주의:%로 하면 브라우저 사이즈에 유동적으로 반응하지만 리렌더링이 안된다.
              type: 'pie'
            },
            noData: {
              text: '데이터가 없습니다.'
            },
            title: {
              text: '보수현황',
              margin: 0,
              style: {
                fontWeight: 'normal'
              }
            },
            labels: ['고문료', '시간보수']
          }
        }
        // 보수현황 차트 데이터 매칭
        this.$nextTick(() => {
          this.contractSeries1 = [ this.gomunFee, this.timeFee ] // [] //  no data 적용
        })

        // @: 차트 2 - [2021.6.9] 넘어온 자문사건 아이디가 있으면
        // 고문계약 하위의 자문사건 관리상태 차트 데이터 매칭
        this.contractChartOption2 = {
          ...pieChartOptions,
          ...{
            chart: {
              width: 280, // '90%', // 주의:%로 하면 브라우저 사이즈에 유동적으로 반응하지만 리렌더링이 안된다.
              type: 'pie'
            },
            noData: {
              text: '데이터가 없습니다.'
            },
            title: {
              text: '고문계약 하위의 자문사건 관리상태',
              margin: 0,
              style: {
                fontWeight: 'normal'
              }
            },
            labels: ['진행', '종결', '그외'],
            colors: [ '#EA80FC', '#42A5F5', '#9C27B0', '#4DB6AC', '#FFB74D', '#90A4AE', '#7E57C2' ]
          }
        }
        if (gaIds) {
          // 고문자문사건 관리상태 패칭 - 고문자문사건의 아이디를 파라미터로 보낸다.
          const { data } = await this.$axios.post(`lawork/${currDir}/getGaChartData`, { gaIds })
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          this.$nextTick(() => {
            this.contractSeries2 = data.chartSeries2
          })
        } else {
          this.$nextTick(() => {
            this.contractSeries2 = [0, 0, 0] // [] //  no data 적용
          })
        }

        // --- 구분: 연계사건 ---
        // @: 차트 1
        this.relCaseChartOption1 = {
          ...pieChartOptions,
          ...{
            chart: {
              width: 290,
              type: 'pie'
            },
            noData: {
              text: '데이터가 없습니다.'
            },
            title: {
              text: '연계현황',
              margin: 0,
              style: {
                fontWeight: 'normal'
              }
            },
            labels: ['소송', '자문']
          }
        }
        // 데이터 매칭
        this.$nextTick(() => {
          this.relCaseSeries1 = [ this.relCaseType1, this.relCaseType2 ]
        })

        // @: 차트 2
        this.relCaseChartOption2 = {
          ...pieChartOptions,
          ...{
            chart: {
              width: 290,
              type: 'pie'
            },
            noData: {
              text: '데이터가 없습니다.'
            },
            title: {
              text: '사건관리상태',
              margin: 0,
              style: {
                fontWeight: 'normal'
              }
            },
            labels: ['진행', '종결', '그외'],
            colors: [ '#EA80FC', '#42A5F5', '#9C27B0', '#4DB6AC', '#FFB74D', '#90A4AE', '#7E57C2' ]
          }
        }
        // 데이터 매칭
        this.$nextTick(() => {
          this.relCaseSeries2 = [ this.relCaseStatus1, this.relCaseStatus2, this.relCaseStatus99 ]
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 업무(진행내용) 정렬 메소드 - 이벤트나 재정렬시 쓰인다
    // !![2021.6.25] 고문계약하위자문상태 차트가 나타나지 않으므로 고문계약도 리프레시 추가
    // !![2021.6.25] 계약갱신은 이제 기한(21)로 처리된다. 기존에는 업무의 일환이었다..
    async changeUpmuSelect () {
      try {
        if (this.upmuSelect === '전체') {
          //
          await this.getSubContents()
          //
        } else if (this.upmuSelect === '업무') {
          //
          await this.getSubContents(22, '업무') // type, gubun1 을 넘긴다
          await this.getSubContents(25) // 고문계약 리프레시
          //
        } else if (this.upmuSelect === '계약갱신') {
          //
          await this.getSubContents(22, '계약갱신')
          await this.getSubContents(25) // 고문계약 리프레시
          //
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 사용안함:[2021.6.1] 중요: 연계사건들의 수금,비용,타임시트,계약 패칭
    // async getRelCaseContents () {
    //   try {
    //     const params = {
    //       teamId: this.article.teamId,
    //       depth: 2,
    //       rId: this.article.id,
    //       rType: 3
    //     }
    //     const { data } = await this.$axios.post(`lawork/${currDir}/getRelCaseContents`, params)
    //     if (!data.success) throw new Error(`list error: ${data.message}`)
    //     const { t23, t24, t25, t26 } = data.list
    //     // 비용
    //     this.t23Lst = await this.setRelCaseContentList(t23)
    //     // 수금
    //     this.t24Lst = await this.setRelCaseContentList(t24)
    //     // 계약
    //     this.t25Lst = await this.setRelCaseContentList(t25)
    //     // 타임시트
    //     this.t26Lst = await this.setRelCaseContentList(t26)
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // 사용안함:[2021.6.1] 유틸함수 --- getRelCaseContents() 에서 쓰임. 리스트의 합산액과 간단 리스트
    // async setRelCaseContentList (list) {
    //   try {
    //     if (list.length <= 0) return []
    //     let resLst = []
    //     // 중복을 제거한 pId 배열
    //     const pIds = Array.from(new Set(list.map(m => m.pId)))
    //     for (let pId of pIds) {
    //       // str2 합산액 구하기
    //       let sum = 0
    //       const tmp = list.filter(c => c.pId === pId).map(item => {
    //         sum += Number(item.str2)
    //         return item
    //       })
    //       resLst.push({
    //         type: tmp[0].type,
    //         pId: tmp[0].pId,
    //         pType: tmp[0].pType,
    //         pInfo: JSON.parse(tmp[0].pInfo) || { error: 'JSON_PARSE_ERROR' }, // !![2021.3.31]
    //         str1: tmp[0].str1, // 가장 최근 내용을 보여주기 위해서..
    //         sum
    //       })
    //     }

    //     return resLst
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // --------------------------------------------------
    // 구분: 연관된 전자결재 목록 패칭
    async getRelEaByIdType () {
      try {
        const rId = this.articleId
        const rType = 3 // 주의: 인명부의 타입은 3
        const { data } = await this.$axios.get(`lawork/eapprove/getRelEaByIdType/${rId}/${rType}`)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        this.relElApprovals = data.list
        //
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 전자결재 등록하기
    // !![2021.7.15] rInfo: JSON.stringify(this.article.pInfo) 로 넘긴다.
    async addEapproval () {
      try {
        // !! confirm 창으로 전자결재에 등록하겠냐고 물어봐야겠지?
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `전자결재를 작성 하시겠습니까?`
        if (await pop.open('확인', msg, { color: 'info', width: 400 })) {
          // !![2021.7.14] 기존 문자열은 변경시 대응이 안되므로 폐기함.
          // const rInfo = `${this.article.name}` // [2021.7.14] 불필요데이터라고 하여 제거
          const params = {
            id: 'new', // '/eawriting/new' 로 이동시킨다.. 전자결재를 작성한다!
            rType: 3, // 인명부 타입
            rId: this.article.id,
            rInfo: JSON.stringify(this.article.pInfo),
            rTid: this.article.teamId
          }
          // console.log(params)
          this.$router.push({ name: 'eawriting', params })
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 연관된 전자결재로 바로가기
    async goRelEapproval (approval) {
      try {
        if (!approval) throw new Error('결재로 이동할 수 없습니다.')

        if (approval.status === 1) {
          // 임시저장인 경우 작성함으로
          this.$router.push(`/eawriting/${approval.id}`)
        } else {
          // 임시저장 이외에는 결재함으로 간다.
          this.$router.push(`/ealist/${approval.id}`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 고문계약하위의 자문사건 등록 버튼 이벤트 핸들러
    // 파라미터는 고문계약이 넘어온다. 이 계약이 자문사건의 근간이 된다!
    async addGomunAdvice (contract) {
      try {
        // #1 확인 팝업
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = '고문계약과 무관한 일반자문사건의 경우에는<br>[연계사건]에서 등록하십시오.'
        if (await pop.open('주의', msg, { color: 'warning', width: 380 })) {
          // #2 자문등록팝업
          const gomun = { // 넘어온 고문계약 정보 간추린것
            id: contract.id,
            teamId: contract.teamId,
            type: contract.type,
            pId: contract.pId,
            pType: contract.pType,
            subtype: contract.subtype,
            isStar: contract.isStar,
            gubun2: contract.gubun2,
            str2: contract.str2,
            str3: contract.str3,
            str4: contract.str4,
            txt3: contract.txt3 // 중요:!!하위 자문 아이디의 문자열
          }
          const insertId = await this.$refs.addGomunAdviceDialog.open('자문', { width: 700, height: 670 }, { client: this.article, gomun })
          if (insertId) { // 성공적으로 자문이 등록되었다면
            // 주의: 연계사건 -> 고문계약 순으로 패칭해야 한다. 아니면 차트가 제대로 그려지지 않는구려..
            // await this.getArticle(this.articleId)
            await this.getSubContents(33) // 연계사건 리프레시
            await this.getSubContents(25) // 고문계약 리프레시
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 고문료 등 내부 컴포넌트를 삭제하고 고문계약리스트를 리프레시한다.
    // expandContractAmount.vue 에서 사용
    async parentRefresh () {
      try {
        // await this.getArticle(this.articleId) // 전체 리프레시

        // await this.getSubContents(25) // 고문계약 리프레시
        // await this.getSubContents(24) // 수금 리프레시

        // 서브 컨텐츠 리프레시
        await this.getSubContents()
        await this.drawSugumChart() // 수금차트 다시 그리기
      } catch (e) {
        this.sbpop(e)
      }
    },
    // --------------------------------------------------
    // 구분: 클립보드에 복사하기
    // 주의: async 를 붙여선 안된다. 매뉴얼 참고
    doCopy (copyText) {
      try {
        if (!copyText) return
        let store = this.$store
        this.$copyText(copyText)
          .then(function (e) {
            store.commit('SB_POP', { msg: '클립보드에 복사되었습니다.', color: 'success' })
          }, function (e) {
            throw new Error(`복사하지 못했습니다. ${e}`)
          })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 이메일 쓰기(지메일) 바로가기
    async writeEmail (email) {
      try {
        const gmailUrl = `https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&source=mailto&to=${email}` // 쓰기로 바로간다.
        // const gmailUrl = `https://mail.google.com/mail/u/0/#inbox?compose=new` // 쓰기로 바로간다.
        const link = document.createElement('a')
        link.href = gmailUrl
        link.target = '_blank'
        link.click()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 선택한 소송/자문 상세화면으로 가기
    async goCaseDetail (caseId, caseType) {
      try {
        const targetUrl = `/${caseType === 1 ? 'case' : 'advice'}/${caseId}`
        this.$router.push(targetUrl)
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 댓글 처리 ----
    // 댓글 팝업 열기
    async showCommentPop (evt) {
      try {
        // dataset 에 저장한 index, item 을 처리한다
        const index = evt.target.dataset.index
        const item = JSON.parse(evt.target.dataset.item) // 객체로 변환
        if (!index || !item) throw new Error('잘못된 인자 형식입니다.')

        // !! 안열린 상태에서만 연다. - 열린상태에서 다시 열지 못하도록
        if (!this.$refs.addCommentPop.showMenu) {
          await this.$refs.addCommentPop.show(evt) // 일단 열고

          // 나머지 인자를 보낸다
          const result = await this.$refs.addCommentPop.setData(item)
          if (result) {
            if (result.isDelParent) {
              // 부모(기일, 업무 등 2depth 데이터)가 삭제된 경우
              this.$store.commit('SB_POP', { msg: '이미 삭제된 부모 데이터입니다.', color: 'error', timeout: 2500, shaped: true })
              // !! 경고를 뿌리고 페이지 리프레시
              // 주의: 접수,자문요청등이 삭제되었을 수 있기 때문에 페이지를 리프레시해야 한다.
              await this.changeUpmuSelect()
              this.refreshList(`${this.articleId}-E`)
            } else if (result.isDeleted) {
              // 조부모(소송/자문/인명부 등)이 삭제된 경우
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) 이미 삭제되었습니다.`)
            } else if (result.teamError) {
              // 팀오류가 발생한 경우 - 실시간 팀이 변경되었다.
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
            } else {
              // 정상적으로 등록되었으면 진행내용 리프레시
              await this.changeUpmuSelect()
            }
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 댓글 삭제
    async deleteComment (item) {
      try {
        if (!item) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          // !!2021.2.18 - POST 방식으로 변경하며 필요 데이터만 파라미터로 넘긴다!
          const params = {
            id: item.id,
            teamId: item.teamId,
            pId: item.pId,
            ppId: item.ppId,
            ppInfo: this.article.name // item.ppInfo !![2021.3.24] ppInfo 는
          }
          const { data } = await this.$axios.post(`lawork/client/removeLwc3d`, params)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.isDelParent) {
            // 부모(기일, 업무 등 2depth 데이터)가 삭제된 경우
            this.$store.commit('SB_POP', { msg: '이미 삭제된 부모 데이터입니다.', color: 'error', timeout: 2500, shaped: true })
            // !! 경고를 뿌리고 페이지 리프레시
            // 주의: 접수,자문요청등이 삭제되었을 수 있기 때문에 페이지를 리프레시해야 한다.
            await this.changeUpmuSelect()
            this.refreshList(`${this.articleId}-E`)
          } else if (data.isDeleted) {
            // 조부모(소송/자문/인명부 등)이 삭제된 경우
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) 이미 삭제되었습니다.`)
          } else if (data.teamError) {
            // 팀오류가 발생한 경우 - 실시간 팀이 변경되었다.
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${data.chInfo.info1})</b>에 속한 데이터입니다.`)
          } else {
            // 정상적으로 삭제된 경우 리프레시
            await this.changeUpmuSelect()
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 첨부파일 처리 ---
    async showFilePop (evt) {
      try {
        // dataset 에 저장한 index, item 을 처리한다
        const index = evt.target.dataset.index
        const item = JSON.parse(evt.target.dataset.item) // 객체로 변환
        if (!index || !item) throw new Error('잘못된 인자 형식입니다.')

        // !! 안열린 상태에서만 연다. - 열린상태에서 다시 열지 못하도록
        if (!this.$refs.addFilePop.showMenu) {
          await this.$refs.addFilePop.show(evt) // 일단 열고

          // 나머지 인자를 보낸다
          const result = await this.$refs.addFilePop.setData(item)
          if (result) {
            if (result.isDelParent) {
              // 부모(기일, 업무 등 2depth 데이터)가 삭제된 경우
              this.$store.commit('SB_POP', { msg: '이미 삭제된 부모 데이터입니다.', color: 'error', timeout: 2500, shaped: true })
              // !! 경고를 뿌리고 페이지 리프레시
              // 주의: 접수,자문요청등이 삭제되었을 수 있기 때문에 페이지를 리프레시해야 한다.
              await this.changeUpmuSelect()
              this.refreshList(`${this.articleId}-E`)
            } else if (result.isDeleted) {
              // 조부모(소송/자문/인명부 등)이 삭제된 경우
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) 이미 삭제되었습니다.`)
            } else if (result.teamError) {
              // 팀오류가 발생한 경우 - 실시간 팀이 변경되었다.
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
            } else {
              // 정상적으로 등록되었으면 진행내용 리프레시
              await this.changeUpmuSelect()
            }
          }
          //
        }
      } catch (err) {
        this.sbpop(err)
      }
    },
    // 첨부파일 삭제
    async deleteFile (item) {
      try {
        if (!item) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>관리자의 구글 드라이브에는 유지됩니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          // !!2021.2.18 - POST 방식으로 변경하며 필요 데이터만 파라미터로 넘긴다!
          const params = {
            id: item.id,
            teamId: item.teamId,
            pId: item.pId,
            ppId: item.ppId,
            ppInfo: this.article.name // item.ppInfo !![2021.3.24] ppInfo 는
          }
          const { data } = await this.$axios.post(`lawork/${currDir}/removeLwc3d`, params)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.isDelParent) {
            // 부모(기일, 업무 등 2depth 데이터)가 삭제된 경우
            this.$store.commit('SB_POP', { msg: '이미 삭제된 부모 데이터입니다.', color: 'error', timeout: 2500, shaped: true })
            // !! 경고를 뿌리고 페이지 리프레시
            // 주의: 접수,자문요청등이 삭제되었을 수 있기 때문에 페이지를 리프레시해야 한다.
            await this.changeUpmuSelect()
            this.refreshList(`${this.articleId}-E`)
          } else if (data.isDeleted) {
            // 조부모(소송/자문/인명부 등)이 삭제된 경우
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) 이미 삭제되었습니다.`)
          } else if (data.teamError) {
            // 팀오류가 발생한 경우 - 실시간 팀이 변경되었다.
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${data.chInfo.info1})</b>에 속한 데이터입니다.`)
          } else {
            // 정상적으로 삭제된 경우 리프레시
            await this.changeUpmuSelect()
          }
          //
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 첨부파일 다운로드
    async fileDownload (file) {
      try {
        if (!file.str5) throw new Error(`유효한 파일이 아닙니다.`)
        this.overlay = true // 로딩 오버레이 on
        const webContentLink = file.str5 // 이건 다운로드

        if (this.$store.state.ui.lv === 'M') {
          // !! 관리자인 경우는 그냥 다운로드
          document.location.href = webContentLink // 다운로드 시작!
          // 2초간 로딩 딜레이
          setTimeout(() => {
            this.overlay = false // 로딩 오버레이 off
          }, 2000)
        } else {
          // !! 관리자가 아닌 경우 .. 공유요청
          const formData = {
            fileId: file.str2 // 구글 드라이브 파일 아이디
          }
          const { data } = await this.$axios.post(`lawork/${currDir}/requestGdFileShare`, formData)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.permessionId) {
            document.location.href = webContentLink // 다운로드 시작!
            // 2초간 로딩 딜레이
            setTimeout(() => {
              this.overlay = false // 로딩 오버레이 off
            }, 2000)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 첨부파일 바로보기 - 브라우저에서 바로보기
    async fileDirectView (file) {
      try {
        if (!file.str6) throw new Error(`유효한 파일이 아닙니다.`)
        const webViewLink = file.str6 // 이건 브라우저에서 바로보기

        if (this.$store.state.ui.lv === 'M') {
          // !! 관리자인 경우
          const win = window.open(webViewLink, '_blank')
          win.focus()
        } else {
          // !! 관리자가 아닌 경우 .. 공유요청
          const formData = {
            fileId: file.str2 // 구글 드라이브 파일 아이디
          }
          const { data } = await this.$axios.post(`lawork/case/requestGdFileShare`, formData)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.permessionId) {
            const win = window.open(webViewLink, '_blank')
            win.focus()
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 공유링크 처리 ---
    async showShareLinkPop (evt) {
      try {
        // dataset 에 저장한 index, item 을 처리한다
        const index = evt.target.dataset.index
        const item = JSON.parse(evt.target.dataset.item) // 객체로 변환
        if (!index || !item) throw new Error('잘못된 인자 형식입니다.')

        // !! 안열린 상태에서만 연다. - 열린상태에서 다시 열지 못하도록
        if (!this.$refs.addShareLinkPop.showMenu) {
          await this.$refs.addShareLinkPop.show(evt) // 일단 열고

          // 나머지 인자를 보낸다
          const result = await this.$refs.addShareLinkPop.setData(item)
          if (result) {
            if (result.isDelParent) {
              // 부모(기일, 업무 등 2depth 데이터)가 삭제된 경우
              this.$store.commit('SB_POP', { msg: '이미 삭제된 부모 데이터입니다.', color: 'error', timeout: 2500, shaped: true })
              // !! 경고를 뿌리고 페이지 리프레시
              // 주의: 접수,자문요청등이 삭제되었을 수 있기 때문에 페이지를 리프레시해야 한다.
              await this.changeUpmuSelect()
              this.refreshList(`${this.articleId}-E`)
            } else if (result.isDeleted) {
              // 조부모(소송/자문/인명부 등)이 삭제된 경우
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) 이미 삭제되었습니다.`)
            } else if (result.teamError) {
              // 팀오류가 발생한 경우 - 실시간 팀이 변경되었다.
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
            } else {
              // 정상적으로 등록되었으면 진행내용 리프레시
              await this.changeUpmuSelect()
            }
          }
          //
        }
      } catch (err) {
        this.sbpop(err)
      }
    },
    // 구분: 공유링크 바로가기 처리
    async shareLinkDirectGo (item) {
      try {
        if (!item.str2) throw new Error(`유효한 공유주소가 아닙니다.`)

        // * [2022.6.7 수정] http(s):// 가 없는 경우 자동으로 붙여준다.
        let sLink = item.str2
        let httpRegex = /^(http(s)?:\/\/)/
        if (!httpRegex.test(String(item.str2).toLowerCase())) {
          sLink = 'https://' + sLink
        }

        // 다른 탭에서 링크 열기
        const link = document.createElement('a')
        link.href = sLink
        link.target = '_blank'
        link.click()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 공유링크 삭제
    // !! [2021.2.19] id 만 받던것에서 모든 객체로 수정함
    async deleteShareLink (item) {
      try {
        if (!item) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          // !!2021.2.18 - POST 방식으로 변경하며 필요 데이터만 파라미터로 넘긴다!
          const params = {
            id: item.id,
            teamId: item.teamId,
            pId: item.pId,
            ppId: item.ppId,
            ppInfo: this.article.name // item.ppInfo !![2021.3.24] ppInfo 는
          }
          const { data } = await this.$axios.post(`lawork/${currDir}/removeLwc3d`, params)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.isDelParent) {
            // 부모(기일, 업무 등 2depth 데이터)가 삭제된 경우
            this.$store.commit('SB_POP', { msg: '이미 삭제된 부모 데이터입니다.', color: 'error', timeout: 2500, shaped: true })
            // !! 경고를 뿌리고 페이지 리프레시
            // 주의: 접수,자문요청등이 삭제되었을 수 있기 때문에 페이지를 리프레시해야 한다.
            await this.changeUpmuSelect()
            this.refreshList(`${this.articleId}-E`)
          } else if (data.isDeleted) {
            // 조부모(소송/자문/인명부 등)이 삭제된 경우
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) 이미 삭제되었습니다.`)
          } else if (data.teamError) {
            // 팀오류가 발생한 경우 - 실시간 팀이 변경되었다.
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${data.chInfo.info1})</b>에 속한 데이터입니다.`)
          } else {
            // 정상적으로 삭제된 경우 리프레시
            await this.changeUpmuSelect()
          }
          //
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 상세페이지 바로가기
    gotoDetail (type, id) {
      if (type === 1) { // 소송
        this.$router.push(`/case/${id}`)
      } else { // 자문
        this.$router.push(`/advice/${id}`)
      }
    },
    // 구분: pdf 상세뷰 제너레이터
    // 중요: /src/lib/pdfmakeView.js 파일에서 공통부분을 처리했고 아래 함수에서는 가변적인 부분만 적용하면된다
    async pdfgen () {
      try {
        // !! pdf 파일정보 - 제목만 넣는다 작성자(author)는 제외
        this.pdfDoc.info = {
          title: '인명부 상세뷰',
          subject: '인명부 상세뷰'
        }
        // !! 공통스타일 적용 - 따로 하려면 따로 지정하면 된다.
        this.pdfDoc.styles = pdfViewStyle
        this.pdfDoc.defaultStyle = { font: 'Nanum' }

        // @: 헤더 타이틀
        const pdfHeaderTitle = `${this.article.isStar ? '★ ' : ''}${this.article.name} ${this.relContracts.length > 0 ? ` [고문계약]` : ''}`

        // @: 작성일
        const workDate = `작성: ${this.$moment().format('YYYY.MM.DD HH:mm')}`

        // !! #1) 기본내용
        let basicContentBody = [
          {
            stack: [
              {
                ul: [
                  `유형|구분: ${this.article.gubun1}|${this.article.gubun2}`,
                  `국적: ${this.article.national}`,
                  `주소: ${this.article.address}`,
                  `휴대전화: ${this.article.hp}`,
                  `일반전화: ${this.article.tel}`,
                  `팩스: ${this.article.fax}`,
                  `이메일: ${this.article.email}`,
                  `관리그룹: ${this.article.mgroupStr}`,
                  `담당부서: ${this.article.teamName}`,
                  `등록일: ${strDateFormat(this.article.createdAt)}`
                ]
              }
            ],
            border: [false, false, false, false]
          }
        ]

        // 중요: 인명부에서 기본정보, 관계인, 추가정보, 업무, 전자결재 는 공통 항목
        // 나머지 항목은 주체유형의 종류 => 의뢰인/상대방/기타 에 따라 달라짐.

        // !! 추가정보
        let rTxt1 = rmTagReturnArray(this.article.txt1)

        // !! 관계인
        let rpersonContent = []
        this.relPersons.forEach(item => {
          let text = ''
          text = `${item.str1} [${item.str2}]${item.str3 ? ` M:${item.str3}` : ''}${item.str4 ? ` T:${item.str4}` : ''}${item.str5 ? ` F:${item.str5}` : ''}${item.str6 ? ` E:${item.str6}` : ''} `
          rpersonContent.push([ { text, border: [false, false, false, false] } ])
        })

        // !! 업무 :: 내부 테이블 body 에 tr > td 를 만든다. 이때 border 를 모두 없앤 스타일을 적용한다.
        let juContent = []
        this.relContents.forEach(item => {
          let text = ''
          let border = [false, false, false, false]

          // text = `${item.isStar ? '★ ' : ''}${strDateFormat3(item.date1)} [${item.gubun1}] ${item.view1}`
          text = `${item.isStar ? '★ ' : ''}${strDateFormat3(item.date1)} [${item.gubun1}] ${item.view1}${item.view2}`
          if (item.subtype === 1) text += ` #${item.gubun2}`

          // 댓글처리
          if (item.subs && item.subs.length > 0) {
            item.subs.forEach(cmtf => {
              text += `\n${cmtf.isAnotherWrite ? ' └▷ ' : ' ┖▶ '} ${cmtf.str1} (${strDateFormat2(cmtf.createdAt)} ${cmtf.wname})`
            })
          }

          juContent.push([ { text, border } ])
        })

        // !! 전자결재
        let reaContent = []
        this.relElApprovals.forEach(item => {
          let text = ''
          text = `${item.isStar ? '★ ' : ''}${strDateFormat3(item.updatedAt)} ${item.gubun1}${item.str1 ? `\n${item.subject} ` : ''}`
          reaContent.push([ { text, border: [false, false, false, false] } ])
        })

        // 중요: 위 항목이외는 주체유형에 따라 포함시켜야 한다!
        // t1show = true 면 의뢰인, t2show = true 면 상대방, 둘다 false 면 기타

        // !! 의뢰인+상대방만 보이는 항목
        // 연계사건
        let rCasesContent = []
        if (this.t1show || this.t2show) { // 의뢰인이나 상대방인 경우만 보여줌
          this.relCases.forEach(item => {
            let text = ''
            // !![2021.3.30] 연계사건정보 패칭 - item.rInfo 는 이미 객체로 파싱된 상태임
            const { rInfo } = item
            if (item.rType === 1) {
              text = `${rInfo.caseName} ${rInfo.courtName} ${rInfo.caseNum}${rInfo.manageNum ? `/${rInfo.manageNum}` : ''} `
            } else {
              text = `${rInfo.caseName} ${rInfo.manageNum} `
            }
            text += `${rInfo.clName ? `${rInfo.clType ? `[${rInfo.clType}]` : ''}${rInfo.clName}` : ''}`
            text += `${rInfo.opName ? `/${rInfo.opType ? `[${rInfo.opType}]` : ''}${rInfo.opName}` : ''}`
            text += ` #${rInfo.status2}`
            rCasesContent.push([ { text, border: [false, false, false, false] } ])
          })
        }

        // !! 의뢰인만 보이는 항목
        // 수금, 연계수금, 비용, 연계비용, 계약, 연계계약, 타임시트, 연계타임시트
        let rsugumContent = [] // 수금
        let rcostContent = [] // 비용
        let rcontractContent = [] // 계약
        let rtContent = [] // 타임시트

        if (this.t1show) { // 의뢰인만 보여줌
          // 수금
          this.relSugums.forEach(item => {
            let text = ''
            text = `${item.isStar ? '★ ' : ''}${strDateFormat3(item.date1)} [${item.gubun1}] ￦${numberFormat(item.str2)} ${item.gubun2 === '미발행' ? item.gubun2 : ''}${item.view1 ? ` ${item.view1}` : ''} #${item.gubun3}${item.str1 ? `\n${item.str1} ` : ''}`
            rsugumContent.push([ { text, border: [false, false, false, false] } ])
          })

          // 비용
          this.relCosts.forEach(item => {
            let text = ''
            text = `${item.isStar ? '★ ' : ''}${strDateFormat3(item.date1)} [${item.gubun1}]${item.str3 ? ` ${item.str3} ` : ''}￦${numberFormat(item.str2)}${item.rInfo ? ` - ${item.rInfo}` : ''}${item.view1 ? ` ${item.view1}` : ''}${item.str1 ? `\n${item.str1} ` : ''}`
            rcostContent.push([ { text, border: [false, false, false, false] } ])
          })

          // 고문계약 - !![2021.6.3]기존계약은 이제 고문계약으로 변경되었다.
          this.relContracts.forEach(item => {
            let text = ''
            text = `${item.isStar ? '★ ' : ''}${strDateFormat3(item.date1)} [${item.gubun1}] (${strDateFormat(item.date2)} ~ ${strDateFormat(item.date3)}}) ${item.view1 ? `${item.view1}` : ''} ￦${numberFormat(item.str2)} ${item.str1 ? `\n${item.str1} ` : ''}`
            rcontractContent.push([ { text, border: [false, false, false, false] } ])
          })

          // 개별사건계약 - !![2021.6.3 신설]
          // this.caseContracts.forEach(item => {
          //   let text = ''
          // })

          // 타임시트
          this.relTimesheets.forEach(item => {
            let text = ''
            text = `${item.isStar ? '★ ' : ''}${strDateFormat3(item.date1)}${item.str3 ? ` ${item.str3} ` : ''} (${item.str5.replace(/^0/g, '')}/￦${numberFormat(item.str2)}) ${item.str1 ? `\n${item.str1} ` : ''}`
            rtContent.push([ { text, border: [false, false, false, false] } ])
          })
        }

        let content = [
          { text: pdfHeaderTitle, style: 'header' },
          { text: workDate, style: 'subheader' },
          menuTitle('기본내용'),
          viewType1(basicContentBody)
        ]
        content.push(menuTitle('관계인'))
        content.push(viewType2(rpersonContent))
        // 의뢰인 + 상대방
        if (this.t1show || this.t2show) {
          content.push(menuTitle('연계사건'))
          content.push(viewType2(rCasesContent))
        }
        // 의뢰인만 보여줌
        if (this.t1show) {
          content.push(menuTitle('수금'))
          content.push(viewType2(rsugumContent))
          content.push(menuTitle('비용'))
          content.push(viewType2(rcostContent))
          content.push(menuTitle('계약'))
          content.push(viewType2(rcontractContent))
          content.push(menuTitle('타임시트'))
          content.push(viewType2(rtContent))
        }
        content.push(menuTitle('추가정보'))
        content.push(viewType1(rTxt1))
        content.push(menuTitle('업무'))
        content.push(viewType2(juContent))
        content.push(menuTitle('전자결재'))
        content.push(viewType2(reaContent))

        this.pdfDoc.content = content

        // !! 주어진 설정과 함께 pdf 파일을 다른탭에 열기
        pdfMake.createPdf(this.pdfDoc).open()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 수금 리스트 pdf 다운로드
    async pdfSugumList () {
      try {
        // !! 헤더 타이틀
        const pdfHeaderTitle = `수금목록 (${this.article.name})`

        // !! pdf 파일정보 - 제목만 넣는다 작성자(author)는 제외
        this.pdfLst.info = {
          title: pdfHeaderTitle,
          subject: pdfHeaderTitle
        }
        // !! 공통스타일 적용 - 따로 하려면 따로 지정하면 된다.
        this.pdfLst.styles = pdfListStyle
        this.pdfLst.defaultStyle = { font: 'Nanum' }

        // !! table body array
        let tBody = []

        // !! 헤더 row 징의
        tBody.push([
          { text: '일자', style: 'tableHeader', alignment: 'center' },
          { text: '항목', style: 'tableHeader', alignment: 'center' },
          { text: '금액(원)', style: 'tableHeader', alignment: 'center' },
          { text: '상태', style: 'tableHeader', alignment: 'center' },
          { text: '발행', style: 'tableHeader', alignment: 'center' }
        ])

        this.relSugums.forEach(d => {
          tBody.push(
            [
              { rowSpan: 2, text: `${d.isStar ? '★ ' : ''}${strDateFormat(d.date1)}` },
              d.gubun1,
              numberFormat(d.str2),
              d.gubun3,
              d.gubun2
            ],
            ['', { colSpan: 4, text: d.str1, alignment: 'left' }]
          )
        })

        let content = [
          { text: pdfHeaderTitle, style: 'header' },
          { text: `${this.$moment().format('YYYY.MM.DD HH:mm')}`, style: 'subheader' },
          {
            style: 'tableBody',
            // layout: 'lightHorizontalLines',
            table: {
              widths: [60, '*', '*', '*', '*'],
              // headerRows: 1, // 다음 페이지로 넘길때 table row 를 반복한다.
              dontBreakRows: true, // !! 다음 페이지로 넘어걸때 row 를 분리하지 않는다.
              body: tBody
            }
          }
        ]
        this.pdfLst.content = content

        // !! 주어진 설정과 함께 pdf 파일을 다른탭에 열기
        pdfMake.createPdf(this.pdfLst).open()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 고문계약 리스트 pdf 다운로드
    async pdfGomunContractList () {
      try {
        //
        // !! 헤더 타이틀
        const pdfHeaderTitle = `고문계약 (${this.article.name})`

        // !! pdf 파일정보 - 제목만 넣는다 작성자(author)는 제외
        this.pdfLst.info = {
          title: pdfHeaderTitle,
          subject: pdfHeaderTitle
        }
        // !! 공통스타일 적용 - 따로 하려면 따로 지정하면 된다.
        this.pdfLst.styles = pdfListStyle
        this.pdfLst.defaultStyle = { font: 'Nanum' }

        // !! table body array
        let tBody = []

        // !! 헤더 row 징의
        tBody.push([
          { text: '일자', style: 'tableHeader', alignment: 'center' },
          { text: '계약번호', style: 'tableHeader', alignment: 'center' },
          { text: '계약기간', style: 'tableHeader', alignment: 'center' },
          { text: '약정업무시간', style: 'tableHeader', alignment: 'center' },
          { text: '고문료(원)', style: 'tableHeader', alignment: 'center' },
          { text: '시간보수(원)', style: 'tableHeader', alignment: 'center' },
          { text: '소계(원)', style: 'tableHeader', alignment: 'center' },
          { text: '하위자문사건', style: 'tableHeader', alignment: 'center' }
        ])

        this.relContracts.forEach(d => {
          // 고문료/시간보수 합산 구하기
          let gomunFeeSumm = 0
          let timeFeeSum = 0
          if (d.txt1) {
            const amounts = JSON.parse(d.txt1)
            for (const item of amounts) {
              gomunFeeSumm += Number(item.amt1) // 고문료 합산
              timeFeeSum += Number(item.amt2) // 시간보수 합산
            }
          }
          // 하위자문사건 갯수 구하기
          let txt3length = 0
          if (d.txt3) {
            txt3length = d.txt3.split(',').length
          }
          tBody.push(
            [
              { rowSpan: 2, text: `${d.isStar ? '★ ' : ''}${strDateFormat(d.date1)}` },
              d.gubun2,
              `${strDateFormat(d.date2)} ~ ${strDateFormat(d.date3)}`,
              `${d.subtype2 === 1 ? '월별정산' : '일괄정산'} ${d.str4}`,
              numberFormat('' + gomunFeeSumm),
              numberFormat('' + timeFeeSum),
              numberFormat(d.str3),
              numberFormat('' + txt3length)
            ],
            ['', { colSpan: 7, text: d.txt2, alignment: 'left' }]
          )
        })

        let content = [
          { text: pdfHeaderTitle, style: 'header' },
          { text: `${this.$moment().format('YYYY.MM.DD HH:mm')}`, style: 'subheader' },
          {
            style: 'tableBody',
            // layout: 'lightHorizontalLines',
            table: {
              widths: [60, 'auto', '*', '*', 'auto', 'auto', 'auto', 'auto'],
              // headerRows: 1, // 다음 페이지로 넘길때 table row 를 반복한다.
              dontBreakRows: true, // !! 다음 페이지로 넘어걸때 row 를 분리하지 않는다.
              body: tBody
            }
          }
        ]
        this.pdfLst.content = content

        // !! 주어진 설정과 함께 pdf 파일을 다른탭에 열기
        pdfMake.createPdf(this.pdfLst).open()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 개별사건계약 리스트 pdf 다운로드
    async pdfCaseContractList () {
      try {
        //
        // !! 헤더 타이틀
        const pdfHeaderTitle = `개별사건계약 (${this.article.name})`

        // !! pdf 파일정보 - 제목만 넣는다 작성자(author)는 제외
        this.pdfLst.info = {
          title: pdfHeaderTitle,
          subject: pdfHeaderTitle
        }
        // !! 공통스타일 적용 - 따로 하려면 따로 지정하면 된다.
        this.pdfLst.styles = pdfListStyle
        this.pdfLst.defaultStyle = { font: 'Nanum' }

        // !! table body array
        let tBody = []

        // !! 헤더 row 징의
        tBody.push([
          { text: '일자', style: 'tableHeader', alignment: 'center' },
          { text: '계약번호', style: 'tableHeader', alignment: 'center' },
          { text: '착수금(원)', style: 'tableHeader', alignment: 'center' },
          { text: '성공보수(원)', style: 'tableHeader', alignment: 'center' },
          { text: '시간보수(원)', style: 'tableHeader', alignment: 'center' },
          { text: '소계(원)', style: 'tableHeader', alignment: 'center' },
          { text: '구분', style: 'tableHeader', alignment: 'center' },
          { text: '정보위치', style: 'tableHeader', alignment: 'center' },
          { text: '사건관리상태', style: 'tableHeader', alignment: 'center' },
          { text: '담당부서', style: 'tableHeader', alignment: 'center' }
        ])

        this.caseContracts.forEach(d => {
          // 착수금/성공보수/시간보수 합산 구하기
          let cccd1 = 0
          let cccd2 = 0
          let cccd3 = 0
          let sum = 0
          // 개별사건계약은 1row 만 있으니 루프를 돌지 않아도 된다.
          if (d.txt1) {
            const amounts = JSON.parse(d.txt1)
            cccd1 = Number(amounts[0].amt1) // 착수금
            cccd2 = Number(amounts[0].amt2) // 성공보수
            cccd3 = Number(amounts[0].amt3) // 시간보수
            sum = cccd1 + cccd2 + cccd3
          }

          let caseType = ''
          let caseNum = '' // 사건번호
          if (d.pType === 1) {
            caseType = '소송'
            caseNum = `${d.pInfo.courtName} ${d.pInfo.caseNum}${d.pInfo.manageNum ? `/${d.pInfo.manageNum}` : ''}`
          } else {
            caseType = '자문'
            caseNum = `${d.pInfo.manageNum}`
          }
          // console.log(d.pInfo)
          tBody.push(
            [
              { rowSpan: 2, text: `${d.isStar ? '★ ' : ''}${strDateFormat(d.date1)}` },
              d.gubun2,
              numberFormat('' + cccd1),
              numberFormat('' + cccd2),
              numberFormat('' + cccd3),
              numberFormat('' + sum),
              numberFormat(caseType),
              caseNum,
              d.pInfo.status2,
              this.getTeamNameByTid(d.pInfo.teamId)
            ],
            ['', { colSpan: 9, text: d.str1, alignment: 'left' }]
          )
        })

        let content = [
          { text: pdfHeaderTitle, style: 'header' },
          { text: `${this.$moment().format('YYYY.MM.DD HH:mm')}`, style: 'subheader' },
          {
            style: 'tableBody',
            // layout: 'lightHorizontalLines',
            table: {
              widths: [60, '*', '*', '*', '*', '*', '*', 'auto', '*', '*'],
              // headerRows: 1, // 다음 페이지로 넘길때 table row 를 반복한다.
              dontBreakRows: true, // !! 다음 페이지로 넘어걸때 row 를 분리하지 않는다.
              body: tBody
            }
          }
        ]
        this.pdfLst.content = content

        // !! 주어진 설정과 함께 pdf 파일을 다른탭에 열기
        pdfMake.createPdf(this.pdfLst).open()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 연계사건 리스트 pdf 다운로드
    async pdfRelCaseList () {
      try {
        // !! 헤더 타이틀
        const pdfHeaderTitle = `연계사건 (${this.article.name})`

        // !! pdf 파일정보 - 제목만 넣는다 작성자(author)는 제외
        this.pdfLst.info = {
          title: pdfHeaderTitle,
          subject: pdfHeaderTitle
        }
        // !! 공통스타일 적용 - 따로 하려면 따로 지정하면 된다.
        this.pdfLst.styles = pdfListStyle
        this.pdfLst.defaultStyle = { font: 'Nanum' }

        // !! table body array
        let tBody = []

        // !! 헤더 row 징의
        tBody.push([
          { text: '구분', style: 'tableHeader', alignment: 'center' },
          { text: '사건번호', style: 'tableHeader', alignment: 'center' },
          { text: '사건명', style: 'tableHeader', alignment: 'center' },
          { text: '의뢰인', style: 'tableHeader', alignment: 'center' },
          { text: '상대방', style: 'tableHeader', alignment: 'center' },
          { text: '분야|유형', style: 'tableHeader', alignment: 'center' },
          { text: '종국', style: 'tableHeader', alignment: 'center' },
          { text: '관리그룹', style: 'tableHeader', alignment: 'center' },
          { text: '상태', style: 'tableHeader', alignment: 'center' },
          { text: '담당부서', style: 'tableHeader', alignment: 'center' }
        ])

        this.relCases.forEach(d => {
          // 주의: 아래 push 에 넣는 배열항목을 제거하거나 늘린 후 아래 content 의 table.widths 배열도 맞춰야 함
          let caseInfo = {}
          if (d.rInfo) {
            caseInfo = { ...d.rInfo }
          }

          let gubun = '' // 구분
          let caseNum = '' // 사건번호
          let mgroupStr = '' // 관리그룹
          if (caseInfo.type1 === 1) {
            gubun = '소송'
            caseNum = `${caseInfo.courtName} ${caseInfo.caseNum}${caseInfo.manageNum ? `/${caseInfo.manageNum}` : ''}`
          } else {
            caseNum = `${caseInfo.manageNum}`
            if (caseInfo.type3 === 1) {
              gubun = '고문자문'
            } else {
              gubun = '개별자문'
            }
          }
          mgroupStr = [caseInfo.mgroup1, caseInfo.mgroup2].filter(g => g).join('|')

          tBody.push([
            gubun,
            caseNum,
            caseInfo.caseName,
            `[${caseInfo.clType}]${caseInfo.clName}`,
            `[${caseInfo.opType}]${caseInfo.opName}`,
            `${caseInfo.gubun1}${caseInfo.gubun2 ? `|${caseInfo.gubun2}` : ''}`,
            caseInfo.endType,
            mgroupStr,
            caseInfo.status2,
            this.getTeamNameByTid(d.teamId)
          ])
        })

        let content = [
          { text: pdfHeaderTitle, style: 'header' },
          { text: `${this.$moment().format('YYYY.MM.DD HH:mm')}`, style: 'subheader' },
          {
            style: 'tableBody',
            // layout: 'lightHorizontalLines',
            table: {
              widths: [60, 'auto', '*', '*', '*', '*', '*', '*', '*', '*'],
              // headerRows: 1, // 다음 페이지로 넘길때 table row 를 반복한다.
              dontBreakRows: true, // !! 다음 페이지로 넘어걸때 row 를 분리하지 않는다.
              body: tBody
            }
          }
        ]
        this.pdfLst.content = content

        // !! 주어진 설정과 함께 pdf 파일을 다른탭에 열기
        pdfMake.createPdf(this.pdfLst).open()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 연계 타임시트 리스트 pdf 다운로드
    async pdfTimesheetList () {
      try {
        // !! 헤더 타이틀
        const pdfHeaderTitle = `시간보수청구 (${this.article.name})`

        // !! pdf 파일정보 - 제목만 넣는다 작성자(author)는 제외
        this.pdfLst.info = {
          title: pdfHeaderTitle,
          subject: pdfHeaderTitle
        }
        // !! 공통스타일 적용 - 따로 하려면 따로 지정하면 된다.
        this.pdfLst.styles = pdfListStyle
        this.pdfLst.defaultStyle = { font: 'Nanum' }

        // !! table body array
        let tBody = []

        // !! 헤더 row 징의
        tBody.push([
          { text: '일자', style: 'tableHeader', alignment: 'center' },
          { text: '업무자', style: 'tableHeader', alignment: 'center' },
          { text: '시간당업무보수(원)', style: 'tableHeader', alignment: 'center' },
          { text: '업무시간', style: 'tableHeader', alignment: 'center' },
          { text: '시간보수(원)', style: 'tableHeader', alignment: 'center' },
          { text: '구분', style: 'tableHeader', alignment: 'center' },
          { text: '사건번호', style: 'tableHeader', alignment: 'center' },
          { text: '사건관리상태', style: 'tableHeader', alignment: 'center' }
        ])

        this.relTimesheets.forEach(d => {
          let gubun = '' // 구분
          let caseNum = '' // 사건번호
          // let mgroupStr = '' // 관리그룹
          if (d.pType === 1) {
            gubun = '소송'
            caseNum = `${d.pInfo.courtName} ${d.pInfo.caseNum}${d.pInfo.manageNum ? `/${d.pInfo.manageNum}` : ''}`
          } else {
            caseNum = `${d.pInfo.manageNum}`
            if (d.pInfo.type3 === 1) {
              gubun = '고문자문'
            } else {
              gubun = '개별자문'
            }
          }

          tBody.push(
            [
              { rowSpan: 2, text: `${d.isStar ? '★ ' : ''}${strDateFormat(d.date1)}` },
              d.str3,
              numberFormat(d.str4),
              d.str5,
              numberFormat(d.str2),
              gubun,
              caseNum,
              d.pInfo.status2
            ],
            ['', { colSpan: 7, text: d.str1, alignment: 'left' }]
          )
        })

        let content = [
          { text: pdfHeaderTitle, style: 'header' },
          { text: `${this.$moment().format('YYYY.MM.DD HH:mm')}`, style: 'subheader' },
          {
            style: 'tableBody',
            // layout: 'lightHorizontalLines',
            table: {
              widths: [60, '*', '*', '*', '*', '*', '*', '*'],
              // headerRows: 1, // 다음 페이지로 넘길때 table row 를 반복한다.
              dontBreakRows: true, // !! 다음 페이지로 넘어걸때 row 를 분리하지 않는다.
              body: tBody
            }
          }
        ]
        this.pdfLst.content = content

        // !! 주어진 설정과 함께 pdf 파일을 다른탭에 열기
        pdfMake.createPdf(this.pdfLst).open()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 연계 비용 리스트 pdf 다운로드
    async pdfCostList () {
      try {
        // !! 헤더 타이틀
        const pdfHeaderTitle = `비용청구 (${this.article.name})`

        // !! pdf 파일정보 - 제목만 넣는다 작성자(author)는 제외
        this.pdfLst.info = {
          title: pdfHeaderTitle,
          subject: pdfHeaderTitle
        }
        // !! 공통스타일 적용 - 따로 하려면 따로 지정하면 된다.
        this.pdfLst.styles = pdfListStyle
        this.pdfLst.defaultStyle = { font: 'Nanum' }

        // !! table body array
        let tBody = []

        // !! 헤더 row 징의
        tBody.push([
          { text: '일자', style: 'tableHeader', alignment: 'center' },
          { text: '항목', style: 'tableHeader', alignment: 'center' },
          { text: '납부처', style: 'tableHeader', alignment: 'center' },
          { text: '금액(원)', style: 'tableHeader', alignment: 'center' },
          { text: '구분', style: 'tableHeader', alignment: 'center' },
          { text: '사건번호', style: 'tableHeader', alignment: 'center' },
          { text: '사건관리상태', style: 'tableHeader', alignment: 'center' }
        ])

        this.relCosts.forEach(d => {
          let gubun = '' // 구분
          let caseNum = '' // 사건번호
          // let mgroupStr = '' // 관리그룹
          if (d.pType === 1) {
            gubun = '소송'
            caseNum = `${d.pInfo.courtName} ${d.pInfo.caseNum}${d.pInfo.manageNum ? `/${d.pInfo.manageNum}` : ''}`
          } else {
            caseNum = `${d.pInfo.manageNum}`
            if (d.pInfo.type3 === 1) {
              gubun = '고문자문'
            } else {
              gubun = '개별자문'
            }
          }

          tBody.push(
            [
              { rowSpan: 2, text: `${d.isStar ? '★ ' : ''}${strDateFormat(d.date1)}` },
              d.gubun1,
              d.str3,
              numberFormat(d.str2),
              gubun,
              caseNum,
              d.pInfo.status2
            ],
            ['', { colSpan: 6, text: d.str1, alignment: 'left' }]
          )
        })

        let content = [
          { text: pdfHeaderTitle, style: 'header' },
          { text: `${this.$moment().format('YYYY.MM.DD HH:mm')}`, style: 'subheader' },
          {
            style: 'tableBody',
            // layout: 'lightHorizontalLines',
            table: {
              widths: [60, '*', '*', '*', '*', '*', '*'],
              // headerRows: 1, // 다음 페이지로 넘길때 table row 를 반복한다.
              dontBreakRows: true, // !! 다음 페이지로 넘어걸때 row 를 분리하지 않는다.
              body: tBody
            }
          }
        ]
        this.pdfLst.content = content

        // !! 주어진 설정과 함께 pdf 파일을 다른탭에 열기
        pdfMake.createPdf(this.pdfLst).open()
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style>
/*
  !!참고: 모든 테이블에 공통적용되는 코드지만 각 파일에 있어야 한다.
  새로고침하면 적용이 안되고 적용된 페이지를 들러야 한다.
  v-data-talbe td 의 왼쪽,오른쪽 패딩 제거. 단 style 태그의 scoped 속성을 지워야 적용됨
*/
/* .v-data-table td{ 2020.6.15 변경됨*/
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 0;
  padding-right: 0;
}
.v-overflow-btn .v-select__selection--comma:first-child {
  margin-left: 5px;
  margin-right: 0px;
}
.v-overflow-btn .v-input__append-inner {
  width: 30px;
}
/* 중요: 모바일에서 테이블의 기본 값은 justify-content: space between 이다. 이걸 start 로 변경한다! */
.v-data-table__mobile-row {
  justify-content: start;
}
.v-application--is-ltr .v-data-table__mobile-row__cell {
    text-align: left;
}
/*
  참고: 모든 vue2editor 뷰어에 공통적용
*/
#vue2editorViewer p {
  margin-bottom: 0px !important;
}
#vue2editorViewer2 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
#vue2editorViewer3 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
</style>
