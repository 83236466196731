<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <v-btn
        v-if="index > -1"
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">삭제</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-btn
        slot="star-area"
        icon
        @click.native="form.isStar = !form.isStar"
      >
        <v-icon
          v-if="!form.isStar"
          color="grey lighten-1"
        >
          mdi-star-outline
        </v-icon>
        <v-icon
          v-else
          color="yellow"
        >
          mdi-star
        </v-icon>
      </v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <!-- <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto py-2 px-3"
        :max-height="options.height"
      >
        <v-card-text> -->

      <!-- 상단 툴바를 고정시키지 않으려면 아래 주석을 푼다. 하단의 태그 끝에도 주석을 풀어야 한다. -->
      <v-card-text
        slot="content"
        class="pb-0"
      >
        <v-container fluid class="pb-0"><!-- 여기까지 적용 -->

          <v-row no-gutters>

            <v-col cols="12" xs="12" sm="6" class="pr-2">
              <v-text-field
                v-model.trim="form.str2"
                v-validate="'required|max:20'"
                maxlength="20"
                counter="20"
                :error-messages="errors.collect('str2')"
                data-vv-name="str2"
                required
                label="관계*"
                placeholder="예)법정대리인"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <v-text-field
                v-model.trim="form.str1"
                v-validate="'required|max:30'"
                maxlength="30"
                counter="30"
                :error-messages="errors.collect('str1')"
                data-vv-name="str1"
                required
                label="이름*"
                placeholder="예)홍길동"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="6" class="pr-2">
              <v-text-field
                v-model.trim="form.str3"
                label="휴대전화"
                maxlength="30"
                hint="010-1234-5678"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <v-text-field
                v-model.trim="form.str4"
                label="일반전화"
                maxlength="30"
                hint="02-1234-5678"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="6" class="pr-2">
              <v-text-field
                v-model.trim="form.str5"
                label="팩스"
                maxlength="30"
                hint="02-1234-5678"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <v-text-field
                v-model.trim="form.str6"
                label="이메일"
                maxlength="50"
                placeholder="입력하세요"
                hint="lawork.info@gmail.com"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <div class="mb-8"></div>
            </v-col>

          </v-row>

        <!-- 상단 타이틀 고정을 풀때 사용 -->
        </v-container>
      </v-card-text>

        <!-- 상단 타이틀 고정시 사용 -->
        <!-- </v-card-text>
      </v-card> -->

    </baseModal>

  </v-dialog>
</template>

<script>
import ko from 'vee-validate/dist/locale/ko'
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

export default {
  components: {
    baseModal
  },

  props: [ 'article' ],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 770,
      height: 750,
      zIndex: 200
    },
    // 구분: Form 관련
    form: {
      id: 0,
      teamId: 1, // 기본팀이 초기값
      type: 35, // 유형(관계인)
      depth: 2,
      pId: 0,
      pType: 3, // 인명부
      pInfo: '', // 정보위치(부모인 인명부의 정보 중 보여줄 정보 이름)
      isStar: 0,
      str1: '', // 관계인 이름
      str2: '', // 관계 항목
      str3: '', // 휴대전화
      str4: '', // 일반전화
      str5: '', // 팩스
      str6: '', // 이메일
      parentInfo: '' // [2021.3.23] 부모정보 문자열
    },
    // 구분:
    index: -1, // !! 수정 인덱스
    viewWname: '', // 처리자를 보여주기만 하는 변수
    viewUpdatedAt: '', // 업데이트 일자를 보여주기 위한 변수
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        str1: '이름',
        str2: '관계'
      }
    }
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async initDialog () {
      // validation 초기화
      this.$validator.reset()

      this.form.id = 0
      this.form.teamId = 1 // 기본팀을 기본값으로
      this.form.pId = 0 // 부모 아이디
      this.form.pType = 3 // 부모 타입(인명부)
      this.form.pInfo = '' // 정보위치(부모인 인명부의 정보 중 보여줄 정보 이름)
      this.form.isStar = 0
      this.form.str1 = '' // 관계인 이름
      this.form.str2 = '' // 관계 항목
      this.form.str3 = '' // 휴대전화
      this.form.str4 = '' // 일반전화
      this.form.str5 = '' // 팩스
      this.form.str6 = '' // 이메일
      this.form.parentInfo = ''

      this.index = -1 // 수정용 인덱스 초기화
    },
    async open (title, options, index = -1, obj = {}) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        // console.log(this.article) // !! props 로 넘어온 부모 데이터
        // 초기화
        await this.initDialog()

        // !! 아래 3개는 부모로부터 넘겨받은 데이터로 매칭하자(뭐 이렇게 하지 않아도 되는데)
        this.form.teamId = this.article.teamId
        this.form.pId = this.article.id

        // 중요:[2021.3.23] 부모정보를 parentInfo 에 넣는다
        this.form.parentInfo = this.article.name

        // 중요:[2021.3.23] 부모정보의 객체를 문자열로 변환해서 this.form.pInfo 에 담는다.
        this.form.pInfo = JSON.stringify(this.article.pInfo) // this.article.name

        // 참고: 편집 모드인 경우 - 넘어온 객체를 this.form 에 복사
        if (index > -1) {
          this.index = index

          this.form.id = obj.id

          this.form.isStar = obj.isStar

          this.form.str1 = obj.str1
          this.form.str2 = obj.str2
          this.form.str3 = obj.str3
          this.form.str4 = obj.str4
          this.form.str5 = obj.str5
          this.form.str6 = obj.str6
        }

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 등록
    async agree () {
      try {
        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        // 리턴객체
        let resolve = {
          isDeleted: false, // 부모가 삭제되었는지 여부
          teamError: false, // 부모의 팀에러
          chInfo: null, // 부모의 에러에 대한 메시지
          rsMsg: '' // resolve 메시지
        }

        // !! 실제 DB 입력 처리 (등록/수정 동일하게 처리)
        const { data } = await this.$axios.post(`lawork/client/setRelPerson`, this.form)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        // 리턴값으로 resolve 채우기
        resolve.isDeleted = data.isDeleted
        resolve.teamError = data.teamError
        resolve.chInfo = data.chInfo
        resolve.rsMsg = 'success'

        await this.initDialog()
        this.resolve(resolve) // this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 취소
    async cancel () {
      try {
        await this.initDialog() // 초기화
        this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 삭제
    async remove () {
      try {
        if (!this.form.id) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          // 리턴객체
          let resolve = {
            isDeleted: false, // 부모가 삭제되었는지 여부
            teamError: false, // 부모의 팀에러
            chInfo: null, // 부모의 에러에 대한 메시지
            rsMsg: '' // resolve 메시지
          }
          // 삭제할 lwcommon id, 부모인 인명부 아이디(pId)를 넘긴다
          const columns = {
            id: this.form.id,
            pId: this.form.pId,
            teamId: this.form.teamId,
            type: this.form.type,
            depth: this.form.depth,
            pType: this.form.pType,
            pInfo: this.form.parentInfo // !![2021.3.24] parentInfo 을 보내는 것으로 변경됨
          }
          const { data } = await this.$axios.post(`lawork/client/deleteRelPerson`, columns)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          // 리턴값으로 resolve 채우기
          resolve.isDeleted = data.isDeleted
          resolve.teamError = data.teamError
          resolve.chInfo = data.chInfo
          resolve.rsMsg = 'removed'

          await this.initDialog() // 초기화
          this.resolve(resolve) // this.resolve(true)
          this.dialog = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
