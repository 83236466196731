<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="btnConfirmClick"
    v-bind:style="{zIndex: options.zIndex}"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="btnConfirmClick">연계하기</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto ma-0 pa-0"
        :min-height="options.height"
        :max-height="options.height + 150"
      >
        <v-card-text class="ma-0 pa-0">
          <v-row no-gutters>
            <v-col cols="12" xs="12">
              <v-row
                no-gutters
                align="center"
                justfy="center"
              >
                <v-col cols="12" class="ma-0 pa-1">
                  <v-alert
                    outlined
                    border="left"
                    type="warning"
                    icon="mdi-alert"
                    dense
                    class="text-subtitle-1 mb-n1"
                  >
                    동일 부서에서 관리되는 정보만 연계가능합니다.
                  </v-alert>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                  <v-toolbar dense flat>
                    <v-toolbar-title class="text-subtitle-2 font-weight-bold">
                      <v-icon small left>mdi-format-list-bulleted-square</v-icon>
                      {{ subTitle2 }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn small color="primary" depressed
                      @click="Type1 === 1 ? addNewCase() : addNewAdvice()"
                    >
                      신규등록
                    </v-btn>
                  </v-toolbar>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                  <v-toolbar dense flat>
                    <v-toolbar-title class="text-subtitle-2 font-weight-bold">
                      <div style="width: 120px;">
                        <v-select
                          v-model="searchForm2.sField"
                          :items="caseTypes"
                          item-value="value"
                          item-text="text"
                          label=""
                          dense
                          style="font-size: 0.785rem !important"
                          class="mt-3 ml-2"
                        ></v-select>
                      </div>
                    </v-toolbar-title>
                    <v-text-field
                      v-model="searchForm2.sValue"
                      label=""
                      maxlength="20"
                      clearble
                      class="mt-3 mx-2"
                      @keypress.enter="getCases"
                    ></v-text-field>
                    <v-btn small outlined text color="primary"
                      class="ma-0"
                      @click="getCases"
                    >
                      <v-icon small left>
                        mdi-magnify
                      </v-icon>
                      검색
                    </v-btn>
                  </v-toolbar>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                  <v-card
                    outlined
                    elevation="0"
                    min-height="180"
                    max-height="180"
                    class="overflow-auto text-body-2 pa-1 ma-2"
                  >
                    <v-alert v-show="cases.length <= 0"
                      text color="blue-grey" class="text-body-2"
                    >
                      해당 정보가 없습니다.(먼저 검색해 주시기 바랍니다.)
                    </v-alert>
                    <v-row
                      v-for="(item, i) in cases"
                      :key="i"
                      no-gutters
                    >
                      <v-col cols="12">
                        <v-chip
                          :disabled="item.isConnected"
                          :color="item.selected ? 'primary' : 'white'"
                          class="ma-0"
                          @click="selectCase2(item)"
                        >
                          <span v-if="Type1 === 1" class="text-body-2 font-weight-bold mr-2">
                            {{ item.courtName }} {{ item.caseNum }}{{ item.manageNum ? `/${item.manageNum}` : '' }}
                          </span>
                          <span v-else class="text-body-2 font-weight-bold mr-2">
                            {{ item.manageNum }}
                          </span>
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                  <v-card
                    tile
                    elevation="0"
                    min-height="280"
                    class="pa-2"
                  >
                    <v-row
                      no-gutters
                      align="center"
                      justfy="center"
                    >
                      <v-col cols="12" xs="12">
                        <v-card
                          outlined
                          elevation="0"
                          class="mt-0 pa-0"
                        >
                          <v-card-text class="text-body-2 text--primary pa-2">
                            <v-card-subtitle class="px-1 pt-1 pb-2">
                              <v-icon small left class="mb-1">mdi-briefcase</v-icon>기본정보
                            </v-card-subtitle>
                            <div
                              v-show="cInfo.isStar || cInfo.courtName || cInfo.caseNum || cInfo.manageNum"
                              class="pl-0 pb-1"
                            >
                              <v-icon small left
                                v-show="cInfo.isStar"
                                color="amber darken-1"
                                class="mb-1 mr-1"
                              >mdi-star</v-icon>
                              <v-chip
                                label x-small
                                :color="cInfo.status === 1 ? 'primary' : ''"
                                class="mb-1 mr-1 px-1" outlined
                              >
                                <span class="text-caption font-weight-bold">{{ cInfo.status2 }}</span>
                              </v-chip>
                              <span
                                v-if="Type1 === 1"
                                class="text-subtitle-2 blue--text text--darken-2"
                              >
                                {{ cInfo.courtName }} {{ cInfo.caseNum }}{{ cInfo.manageNum ? `/${cInfo.manageNum}` : '' }}
                              </span>
                              <span
                                v-else
                                class="text-subtitle-2 blue--text text--darken-2"
                              >
                                {{ cInfo.manageNum }}
                              </span>
                              <v-icon small
                                v-show="cInfo.id"
                                color="primary"
                                @click="goCaseDetail(cInfo.id)"
                                class="mx-1"
                              >mdi-launch</v-icon>
                            </div>
                            <div
                              v-show="cInfo.caseName"
                              class="pl-1 pb-1"
                            >
                              <span class="text-body-2 grey--text text--darken-1 mr-1">사건명:</span>
                              {{ cInfo.caseName }}
                            </div>
                            <div
                              v-show="cInfo.clName || cInfo.opName"
                              class="pl-1 pb-1"
                            >
                              <span class="text-body-2 grey--text text--darken-1 mr-1">당사자:</span>
                              <span class="text-body-2 font-weight-bold mr-1">{{ cInfo.clType ? `(${cInfo.clType})` : '' }}{{ cInfo.clName }}</span>
                              {{ cInfo.opName ? '/' : '' }}
                              {{ cInfo.opType ? `(${cInfo.opType})` : '' }}{{ cInfo.opName }}
                            </div>
                            <div
                              v-show="cInfo.endDate || cInfo.endType || cInfo.endMemo"
                              class="pl-1 pb-1"
                            >
                              <span class="text-body-2 grey--text text--darken-1 mr-1">종국:</span>
                              <span class="text-body-2 error--text font-weight-medium">
                                <v-icon
                                  v-show="cInfo.endDate && cInfo.endType"
                                  small
                                  color="warning"
                                  class="mr-1"
                                >mdi-gavel</v-icon>
                                  {{ strDateFormat(cInfo.endDate) }} {{ cInfo.endType }}
                              </span>
                            </div>
                            <div
                              v-show="cInfo.jepanbu"
                              class="pl-1 pb-1"
                            >
                              <span class="text-body-2 grey--text text--darken-1 mr-1">재판(관리)부:</span>
                              {{ cInfo.jepanbu }}
                            </div>
                            <div
                              v-show="cInfo.mgroupStr || cInfo.teamName"
                              class="pl-1 pb-1"
                            >
                              <span class="text-body-2 grey--text text--darken-1 mr-1">관리그룹:</span>
                              {{ cInfo.mgroupStr }}
                            </div>
                            <div
                              v-show="cInfo.gubun1 || cInfo.gubun2 || cInfo.gubun3 || cInfo.gubun4"
                              class="pl-1 pb-1"
                            >
                              <span class="text-body-2 grey--text text--darken-1 mr-1">구분:</span>
                              {{ cInfo.gubun1 ? `${cInfo.gubun1}` : '' }}{{ cInfo.gubun2 ? `/${cInfo.gubun2}` : '' }}
                              {{ cInfo.gubun3 ? `&nbsp; [${cInfo.gubun3}]` : '' }}
                              {{ cInfo.gubun4 ? `&nbsp; [${cInfo.gubun4}]` : '' }}
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>

    </baseModal>

  </v-dialog>
</template>

<script>
import ko from 'vee-validate/dist/locale/ko'
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

// @: filters
import strDateFormat from '@/filters/strDateFormat'

export default {
  components: {
    baseModal
  },

  props: [ 'article' ],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 960,
      height: 700,
      zIndex: 200
    },
    // 구분: 연계의뢰인 등록 폼
    form: {
      id: 0,
      teamId: 0, // 팀 아이디
      caseId: 0, // 소송 아이디
      caseType: 1, // 소송 타입
      caseInfo: null, // 소송 정보
      clientId: 0, // 의뢰인/상대방 아이디
      clientType: 1, // 의뢰인/상대방 타입(의뢰인 - 1, 상대방 - 2)
      clientInfo: null, // 의뢰인 정보
      dangGubun: '', // 당사자 구분 !! 연계사건에선 필요없지만 있어야 DB입력시 에러가 안난다!
      gusok: '' // 구속여부
    },
    // 구분: 우측 연계 소송/자문 검색폼
    searchForm2: {
      sField: 1,
      sValue: ''
    },
    // !!구분: 연계 사건 타입(33)
    lwcType: 33,
    // !!구분: 팝업을 열면서 넘어온 type1 을 매칭시켜야 한다!
    Type1: 1,
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        // str1: '기일명'
      }
    },
    // 구분: 우측 소송/자문 목록
    cases: [],
    // 구분: 소송/자문 검색 셀렉트
    caseTypes: [
      { text: '사건당사자', value: 1 },
      { text: '사건(관리)번호', value: 2 },
      { text: '사건명', value: 3 },
      { text: '관할', value: 4 }
    ],
    // 구분: 선택한 소송/자문 정보
    cInfo: {
      id: 0,
      teamId: 1,
      type1: 1,
      gubun1: '',
      gubun2: '',
      gubun3: '',
      gubun4: '',
      gubun5: '',
      courtName: '',
      caseNum: '',
      caseName: '',
      manageNum: '',
      clType: '',
      opType: '',
      clName: '',
      opName: '',
      jepanbu: '',
      status: 1,
      status2: '',
      mgroupStr: '',
      teamColor: '',
      teamName: '',
      isStar: 0,
      endDate: '',
      endType: '',
      endMemo: ''
    },
    // 구분: 소송/자문 타이틀
    subTitle2: '소송'
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  methods: {
    strDateFormat,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async initDialog () {
      // validation 초기화
      this.$validator.reset()

      // 사건 배열 초기화
      this.cases = []

      // 연계 의뢰인 등록폼 초기화
      this.form.id = 0
      this.form.teamId = 0 // 팀 아이디
      this.form.caseId = 0 // 소송 아이디
      this.form.caseType = 1 // 소송/자문 타입 (소송 = 1, 자문 = 2)
      this.form.caseInfo = null // 소송 정보
      this.form.clientId = 0 // 의뢰인 아이디
      this.form.clientType = 1 // 의뢰인/상대방 타입(의뢰인 = 1, 상대방 = 2)
      this.form.clientInfo = null // 의뢰인 정보

      // 선택 소송/자문 정보 초기화
      await this.initCInfo()

      // 검색폼1 초기화
      // this.searchForm1.sValue = ''

      // 검색폼2 초기화
      this.searchForm2.sField = 1
      this.searchForm2.sValue = ''
    },
    // 선택 소송/자문 정보 초기화
    async initCInfo () {
      this.cInfo.id = 0
      this.cInfo.teamId = 1
      this.cInfo.gubun1 = ''
      this.cInfo.gubun2 = ''
      this.cInfo.gubun3 = ''
      this.cInfo.gubun4 = ''
      this.cInfo.gubun5 = ''
      this.cInfo.courtName = ''
      this.cInfo.caseNum = ''
      this.cInfo.caseName = ''
      this.cInfo.manageNum = ''
      this.cInfo.clType = ''
      this.cInfo.opType = ''
      this.cInfo.clName = ''
      this.cInfo.opName = ''
      this.cInfo.jepanbu = ''
      this.cInfo.status = 1
      this.cInfo.status2 = ''
      this.cInfo.mgroupStr = ''
      this.cInfo.teamColor = ''
      this.cInfo.teamName = ''
      this.cInfo.isStar = 0
      this.cInfo.endDate = ''
      this.cInfo.endType = ''
      this.cInfo.endMemo = ''
    },
    // !! 제목, 옵션, type1=[1 소송 / 2 자문], 선택한 연계 소송/자문 객체
    // !! 상세페이지에서 특정 연계 소송/자문을 선택하고 팝업을 열었으면 obj 객체에 의뢰인 정보가 담겨온다!
    // !! this.article 에는 부모인 인명부의 정보가 담겨온다.
    async open (title, options, type1, obj = {}) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        // 중요: 파라미터로 넘어온 type1 에 따라 소송/자문이 결정되므로 중요하다.
        // type1 은 cases.type1 에 매칭한다
        this.Type1 = type1
        // console.log(this.article) // !! props 로 넘어온 부모(인명부) 데이터

        // 초기화
        await this.initDialog()

        // 소송/자문에 맞춰 타이틀 변환
        if (this.Type1 === 1) {
          this.subTitle2 = '소송'
        } else {
          this.subTitle2 = '자문'
        }

        // [폐기]의뢰인 목록 얻기(소송/자문과 같은 팀의 의뢰인 목록 패칭)
        // await this.getCases()

        // 연계 소송/자문 등록/수정 폼 - 팀과 인명부 정보는 넘어온 부모정보로 픽스된다.
        // 소송/자문 정보는 소송/자문 선택시 매칭된다.
        this.form.teamId = this.article.teamId // 팀 아이디
        this.form.clientId = this.article.id // 인명부 아이디
        this.form.clientType = this.article.type // 의뢰인/상대방 타입: 의뢰인 = 1, 상대방 = 2
        this.form.caseType = this.Type1 // 소송 = 1, 자문 = 2 : cases.type1 과 매칭

        // 인명부 정보 매칭
        this.form.clientInfo = {
          name: this.article.name,
          hp: this.article.hp,
          tel: this.article.tel,
          fax: this.article.fax,
          email: this.article.email
        }

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 연계하기 버튼으로 용도가 변경됨
    // 기존 연계하기 기능 + 닫기 기능
    // setRelation() 함수를 변형함
    async btnConfirmClick () {
      try {
        // !! 연계사건의 경우 등록만 존재한다.
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `연계 하시겠습니까?`
        if (await pop.open('연계', msg, { color: 'info', width: 400 })) {
          // 리턴객체
          let resolve = {
            isDeleted: false, // 부모가 삭제되었는지 여부
            teamError: false, // 부모의 팀에러
            chInfo: null, // 부모의 에러에 대한 메시지
            rsMsg: '' // resolve 메시지
          }
          const { data } = await this.$axios.post(`lawork/case/setRelCaseClient/`, this.form)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          // 리턴값으로 resolve 채우기
          resolve.isDeleted = data.isDeleted
          resolve.teamError = data.teamError
          resolve.chInfo = data.chInfo
          resolve.rsMsg = 'success'

          // !! 기존에는 팝업이 안닫혔기 때문에 목록을 리프레시 하고 선택시켜주고 했지만 이제는 깔끔하게 닫으면 된다.
          await this.initDialog() // 초기화
          this.resolve(resolve) // this.resolve(true)
          this.dialog = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 소송 등록 다이얼로그 호출 메서드
    // !! 인명부 에서의 새로운 소송 등록폼이므로 components/client/addNewCaseDialog.vue 파일을 호출한다.
    // !! 해당 인명부의 팀아이디를 넘겨야 한다.
    // [2021.2.26] -- addNewCaseDailog.vue 의 리턴값이 변경되었다..
    async addNewCase () {
      try {
        // console.log(this.article)
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'addNewCaseDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 현 인명부의 팀정보를 가공한 후 소송 등록 팝업을 연다. 팀정보는 수정이 불가능하게 고정된다.
        const teamInfo = { text: this.article.teamName, value: this.article.teamId }
        const insertId = await pop.open('소송', { width: 700, height: 670 }, teamInfo)
        if (insertId) {
          // 소송 목록 리프레시 하고
          await this.getCases()

          // 방금 등록한 소송을 찾은 후 자동선택
          const ca = this.cases.find(c => c.id === insertId)
          await this.selectCase2(ca)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 자문 등록 다이얼로그 호출 메서드
    // !! 인명부 에서의 새로운 자문 등록폼이므로 components/client/addNewAdviceDialog.vue 파일을 호출한다.
    // !! 해당 인명부의 팀아이디를 넘겨야 한다.
    async addNewAdvice () {
      try {
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'addNewAdviceDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 현 인명부의 팀정보를 가공한 후 자문 등록 팝업을 연다. 팀정보는 수정이 불가능하게 고정된다.
        const teamInfo = { text: this.article.teamName, value: this.article.teamId }
        const insertId = await pop.open('자문', { width: 700, height: 670 }, teamInfo)
        if (insertId) {
          // 자문 목록 리프레시 하고
          await this.getCases()

          // 방금 등록한 자문을 찾은 후 자동선택
          const ca = this.cases.find(c => c.id === insertId)
          await this.selectCase2(ca)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 우측 - 해당팀의 소송/자문 목록 패칭하기
    // 초기로딩시와 검색시 모두 사용됨
    async getCases () {
      try {
        // 소송/자문 타입과 팀아이디, searchForm2 의 정보를 넘긴다!
        const params = {
          teamId: this.article.teamId,
          type1: this.Type1, // 넘어온 소송(1),자문(2) 타입
          lwcType: this.lwcType, // lwcommon.type
          pId: this.article.id, // 부모인 인명부의 lwcommon.pId
          pType: 3, // 부모인 인명부의 lwcommon.pType
          subtype: this.article.type, // 부모인 인명부의 type. 의뢰인(1), 상대방(2) - lwcommon의 subtype 검색시 사용됨
          sField: this.searchForm2.sField,
          sValue: this.searchForm2.sValue
        }
        const { data } = await this.$axios.get(`lawork/case/getCasesWhere`, { params })
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
        this.cases = data.list
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 선택한 소송/자문 상세화면으로 가기
    async goCaseDetail (cId) {
      try {
        if (this.Type1 === 1) { // 소송
          this.$router.push(`/case/${cId}`)
        } else { // 자문
          this.$router.push(`/advice/${cId}`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 우측 소송/자문 목록 선택 이벤트
    async selectCase2 (item) {
      try {
        // 우측 소송/자문 목록 색상도 모두 없앤다
        this.cases.forEach(c => { c.selected = false })
        // 선택한 넘의 색상만 강조
        item.selected = true

        // 선택한 소송/자문 정보를 매칭하자!
        this.cInfo = item

        // !! this.form 값 만들기
        this.form.id = 0 // 등록해야 하므로 0
        this.form.caseId = item.id // 소송/자문 아이디

        let viewInfo = ''
        if (this.Type1 === 1) {
          // 소송
          viewInfo = `${item.caseNum}${item.manageNum ? `/${item.manageNum}` : ''}`
        } else {
          // 자문
          viewInfo = `${item.manageNum}`
        }

        // 소송/자문 정보를 만든다
        this.form.caseInfo = {
          viewInfo, // 보여줄 정보
          gubun1: item.gubun1,
          gubun2: item.gubun2,
          gubun3: item.gubun3,
          gubun4: item.gubun4,
          gubun5: item.gubun5,
          courtName: item.courtName,
          caseNum: item.caseNum,
          caseName: item.caseName,
          manageNum: item.manageNum,
          clType: item.clType,
          opType: item.opType,
          clName: item.clName,
          opName: item.opName,
          jepanbu: item.jepanbu,
          status: item.status,
          status2: item.status2
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 취소 버튼 이벤트 핸들러 - 닫는 역할만 한다
    async cancel () {
      try {
        let resolve = {
          isDeleted: false, // 부모가 삭제되었는지 여부
          teamError: false, // 부모의 팀에러
          chInfo: null, // 부모의 에러에 대한 메시지
          rsMsg: 'success' // resolve 메시지
        }
        await this.initDialog() // 초기화
        this.resolve(resolve) // this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    }
    // 사용안함:[2021.6.19] 연계 소송/자문 삭제
    // 같이 등록한 연계 인명부도 같이 삭제된다!
    // async deleteRelCase (item) {
    //   try {
    //     // console.log(item)
    //     if (!item) throw new Error(`연계사건을 삭제 할 수 없습니다. 잘못된 데이터형식입니다.`)

    //     // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
    //     const target = 'confirmDialog'
    //     const pop = await this.findParentRefs(this.$parent, target)
    //     if (!pop) throw new Error('팝업창을 열 수 없습니다.')
    //     // 찾았으면 팝업을 연다
    //     // const msg = `의뢰인과의 연결을 해제하며, 해당 계약 및 수금 정보를 삭제합니다.<br>단, 비용, 타임시트의 대상자는 [구분안함]으로 변경됩니다.<br>그러나, 의뢰인 정보 자체가 삭제되는 것은 아닙니다.`
    //     const msg = `사건 연결을 해제합니다.`
    //     if (await pop.open('연계 해제', msg, { color: 'error', width: 420 })) {
    //       const { data } = await this.$axios.post(`lawork/case/deleteRelCaseClient/`, item)
    //       if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
    //       await this.getCases() // 우측 소송/자문 목록 리프레시
    //       await this.initCInfo() // 선택 소송/자문 정보만 초기화
    //     }
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // }
    // 사용안함:[2021.6.16] 연계하기 버튼 클릭 이벤트 - 선택한 의뢰인과 양방향 관계를 맺는다!
    // async setRelation () {
    //   try {
    //     // console.log(this.form)
    //     // !! 연계사건의 경우 등록만 존재한다.
    //     // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
    //     const target = 'confirmDialog'
    //     const pop = await this.findParentRefs(this.$parent, target)
    //     if (!pop) throw new Error('팝업창을 열 수 없습니다.')
    //     // 찾았으면 팝업을 연다
    //     const msg = `사건을 연계 하시겠습니까?`
    //     if (await pop.open('사건연계', msg, { color: 'info', width: 400 })) {
    //       const { data } = await this.$axios.post(`lawork/case/setRelCaseClient/`, this.form)
    //       if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
    //       await this.getRelCases() // 좌측 연계 소송/자문 목록 리프레시
    //       await this.getCases() // 우측 소송/자문 목록 리프레시

    //       // !! 연계의뢰인이 등록되면 data.type32Id 로 아이디가 넘어온다.
    //       // !! 반대로 연계사건은 data.type33Id 로 넘어온다.
    //       const insertId = data.type33Id // 막 입력한 연계 사건 아이디

    //       // 연계 소송/자문 목록에서 방금 입력한 넘을 찾아서 표시
    //       const item = this.relCases.find(c => c.id === insertId)
    //       if (item) {
    //         await this.selectCase(item)
    //         this.$store.commit('SB_POP', { msg: '적용되었습니다.', color: 'info' })
    //       }
    //     }
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // }
  }
}
</script>
