<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <baseModal>

     <v-btn
        v-show="cInfo.type3 !== 1"
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">해제</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto ma-0 pa-0"
        :min-height="options.height"
        :max-height="options.height + 150"
      >
        <v-card-text class="ma-0 pa-0">

          <v-row no-gutters>
            <v-col cols="12" xs="12">
              <v-row
                no-gutters
                align="center"
                justfy="center"
              >
                <v-col cols="12" class="ma-0 pa-0">
                  <v-toolbar dense flat>
                    <v-toolbar-title class="text-subtitle-2 font-weight-bold">
                      <v-icon small left>mdi-format-list-bulleted-square</v-icon>
                      {{ subTitle2 }}
                    </v-toolbar-title>
                  </v-toolbar>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                  <v-card
                    elevation="0"
                    outlined
                    min-height="280"
                    class="ma-2 pa-0"
                  >
                    <v-card-text class="text-body-2 text--primary pa-2">
                      <v-card-subtitle class="px-1 pt-1 pb-2">
                        <v-icon small left class="mb-1">mdi-briefcase</v-icon>기본정보
                      </v-card-subtitle>
                      <div
                        v-show="cInfo.isStar || cInfo.courtName || cInfo.caseNum || cInfo.manageNum"
                        class="pl-1 pb-1"
                      >
                        <!-- <span class="text-body-2 grey--text text--darken-1 mr-1">관리상태/사건(관리)번호:</span> -->
                        <v-icon small left
                          v-show="cInfo.isStar"
                          color="amber darken-1"
                          class="mb-1 mr-1"
                        >mdi-star</v-icon>
                        <v-chip
                          label
                          x-small
                          :color="cInfo.status === 1 ? 'primary' : ''"
                          class="mb-1 mr-1 px-1" outlined
                        >
                          <span class="text-caption font-weight-bold">{{ cInfo.status2 }}</span>
                        </v-chip>
                        <span
                          v-if="Type1 === 1"
                          class="text-subtitle-2 blue--text text--darken-2"
                        >
                          {{ cInfo.courtName }} {{ cInfo.caseNum }}{{ cInfo.manageNum ? `/${cInfo.manageNum}` : '' }}
                        </span>
                        <span
                          v-else
                          class="text-subtitle-2 blue--text text--darken-2"
                        >
                          {{ cInfo.manageNum }}
                        </span>
                        <v-icon small
                          v-show="cInfo.id"
                          color="primary"
                          @click="goCaseDetail(cInfo.id)"
                          class="mx-1"
                        >mdi-launch</v-icon>
                      </div>
                      <div
                        v-show="cInfo.caseName"
                        class="pl-1 pb-1"
                      >
                        <span class="text-body-2 grey--text text--darken-1 mr-1">사건명:</span>
                        {{ cInfo.caseName }}
                      </div>
                      <div
                        v-show="cInfo.clName || cInfo.opName"
                        class="pl-1 pb-1"
                      >
                        <span class="text-body-2 grey--text text--darken-1 mr-1">당사자:</span>
                        <span class="text-body-2 font-weight-bold mr-1">{{ cInfo.clType ? `(${cInfo.clType})` : '' }}{{ cInfo.clName }}</span>
                        {{ cInfo.opName ? '/' : '' }}
                        {{ cInfo.opType ? `(${cInfo.opType})` : '' }}{{ cInfo.opName }}
                      </div>
                      <div
                        v-show="cInfo.endDate || cInfo.endType || cInfo.endMemo"
                        class="pl-1 pb-1"
                      >
                        <span class="text-body-2 grey--text text--darken-1 mr-1">종국:</span>
                        <span class="text-body-2 error--text font-weight-medium">
                          <v-icon
                            v-show="cInfo.endDate && cInfo.endType"
                            small
                            color="warning"
                            class="mr-1"
                          >mdi-gavel</v-icon>
                            {{ strDateFormat(cInfo.endDate) }} {{ cInfo.endType }}
                        </span>
                      </div>
                      <div
                        v-show="cInfo.jepanbu"
                        class="pl-1 pb-1"
                      >
                        <span class="text-body-2 grey--text text--darken-1 mr-1">재판(관리)부:</span>
                        {{ cInfo.jepanbu }}
                      </div>
                      <div
                        v-show="cInfo.mgroupStr || cInfo.teamName"
                        class="pl-1 pb-1"
                      >
                        <span class="text-body-2 grey--text text--darken-1 mr-1">관리그룹:</span>
                        {{ cInfo.mgroupStr }}
                      </div>
                      <div
                        v-show="cInfo.gubun1 || cInfo.gubun2 || cInfo.gubun3 || cInfo.gubun4"
                        class="pl-1 pb-1"
                      >
                        <span class="text-body-2 grey--text text--darken-1 mr-1">구분:</span>
                        {{ cInfo.gubun1 ? `${cInfo.gubun1}` : '' }}{{ cInfo.gubun2 ? `/${cInfo.gubun2}` : '' }}
                        {{ cInfo.gubun3 ? `&nbsp; [${cInfo.gubun3}]` : '' }}
                        {{ cInfo.gubun4 ? `&nbsp; [${cInfo.gubun4}]` : '' }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>

    </baseModal>

  </v-dialog>
</template>

<script>
import ko from 'vee-validate/dist/locale/ko'
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

// @: filters
import strDateFormat from '@/filters/strDateFormat'

export default {
  components: {
    baseModal
  },

  props: [ 'article' ],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 960,
      height: 700,
      zIndex: 200
    },
    // 구분: 연계의뢰인 등록 폼
    form: {
      id: 0,
      teamId: 0, // 팀 아이디
      caseId: 0, // 소송 아이디
      caseType: 1, // 소송 타입
      caseInfo: null, // 소송 정보
      clientId: 0, // 의뢰인/상대방 아이디
      clientType: 1, // 의뢰인/상대방 타입(의뢰인 - 1, 상대방 - 2)
      clientInfo: null, // 의뢰인 정보
      dangGubun: '', // 당사자 구분 !! 연계사건에선 필요없지만 있어야 DB입력시 에러가 안난다!
      gusok: '' // 구속여부
    },
    // !!구분: 연계 사건 타입(33)
    lwcType: 33,
    // !!구분: 팝업을 열면서 넘어온 type1 을 매칭시켜야 한다!
    Type1: 1,
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        // str1: '기일명'
      }
    },
    // 구분: 선택한 소송/자문 정보
    cInfo: {
      id: 0,
      teamId: 1,
      type1: 1,
      type2: 1, // 기관(법원/헌재/검찰등.. ) 소송전용
      type3: 0, // 자문전용 1이면 고문자문
      gubun1: '',
      gubun2: '',
      gubun3: '',
      gubun4: '',
      gubun5: '',
      courtName: '',
      caseNum: '',
      caseName: '',
      manageNum: '',
      clType: '',
      opType: '',
      clName: '',
      opName: '',
      jepanbu: '',
      status: 1,
      status2: '',
      mgroupStr: '',
      teamColor: '',
      teamName: '',
      isStar: 0,
      endDate: '',
      endType: '',
      endMemo: ''
    },
    // 구분: 소송/자문 타이틀
    subTitle2: '소송'
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  methods: {
    strDateFormat,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async initDialog () {
      // validation 초기화
      this.$validator.reset()

      // 연계 의뢰인 등록폼 초기화
      this.form.id = 0
      this.form.teamId = 0 // 팀 아이디
      this.form.caseId = 0 // 소송 아이디
      this.form.caseType = 1 // 소송/자문 타입 (소송 = 1, 자문 = 2)
      this.form.caseInfo = null // 소송 정보
      this.form.clientId = 0 // 의뢰인 아이디
      this.form.clientType = 1 // 의뢰인/상대방 타입(의뢰인 = 1, 상대방 = 2)
      this.form.clientInfo = null // 의뢰인 정보

      // 선택 소송/자문 정보 초기화
      await this.initCInfo()
    },
    // 선택 소송/자문 정보 초기화
    async initCInfo () {
      this.cInfo.id = 0
      this.cInfo.teamId = 1
      this.cInfo.type1 = 1
      this.cInfo.type2 = 1
      this.cInfo.type3 = 0
      this.cInfo.gubun1 = ''
      this.cInfo.gubun2 = ''
      this.cInfo.gubun3 = ''
      this.cInfo.gubun4 = ''
      this.cInfo.gubun5 = ''
      this.cInfo.courtName = ''
      this.cInfo.caseNum = ''
      this.cInfo.caseName = ''
      this.cInfo.manageNum = ''
      this.cInfo.clType = ''
      this.cInfo.opType = ''
      this.cInfo.clName = ''
      this.cInfo.opName = ''
      this.cInfo.jepanbu = ''
      this.cInfo.status = 1
      this.cInfo.status2 = ''
      this.cInfo.mgroupStr = ''
      this.cInfo.teamColor = ''
      this.cInfo.teamName = ''
      this.cInfo.isStar = 0
      this.cInfo.endDate = ''
      this.cInfo.endType = ''
      this.cInfo.endMemo = ''
    },
    // !! 제목, 옵션, type1=[1 소송 / 2 자문], 선택한 연계 소송/자문 객체
    // !! 상세페이지에서 특정 연계 소송/자문을 선택하고 팝업을 열었으면 obj 객체에 의뢰인 정보가 담겨온다!
    // !! this.article 에는 부모인 인명부의 정보가 담겨온다.
    async open (title, options, type1, lwcommon) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        // 중요: 파라미터로 넘어온 type1 에 따라 소송/자문이 결정되므로 중요하다.
        // type1 은 cases.type1 에 매칭한다
        this.Type1 = type1
        // console.log(this.article) // !! props 로 넘어온 부모(인명부) 데이터

        // 초기화
        await this.initDialog()

        // 소송/자문에 맞춰 타이틀 변환
        if (this.Type1 === 1) {
          this.subTitle2 = '소송'
        } else {
          this.subTitle2 = '자문'
        }

        // !! 소송자문 값 만들기
        this.cInfo.id = lwcommon.rInfo.d
        this.cInfo.teamId = lwcommon.rInfo.teamId
        this.cInfo.type1 = lwcommon.rInfo.type1
        this.cInfo.type2 = lwcommon.rInfo.type2
        this.cInfo.type3 = lwcommon.rInfo.type3
        this.cInfo.gubun1 = lwcommon.rInfo.gubun1
        this.cInfo.gubun2 = lwcommon.rInfo.gubun2
        this.cInfo.gubun3 = lwcommon.rInfo.gubun3
        this.cInfo.gubun4 = lwcommon.rInfo.gubun4
        this.cInfo.gubun5 = lwcommon.rInfo.gubun5
        this.cInfo.courtName = lwcommon.rInfo.courtName
        this.cInfo.caseNum = lwcommon.rInfo.caseNum
        this.cInfo.caseName = lwcommon.rInfo.caseName
        this.cInfo.manageNum = lwcommon.rInfo.manageNum
        this.cInfo.clType = lwcommon.rInfo.clType
        this.cInfo.opType = lwcommon.rInfo.opType
        this.cInfo.clName = lwcommon.rInfo.clName
        this.cInfo.opName = lwcommon.rInfo.opName
        this.cInfo.jepanbu = lwcommon.rInfo.jepanbu
        this.cInfo.status = lwcommon.rInfo.status
        this.cInfo.status2 = lwcommon.rInfo.status2
        this.cInfo.mgroupStr = lwcommon.rInfo.mgroupStr
        this.cInfo.teamColor = lwcommon.rInfo.teamColor
        this.cInfo.teamName = lwcommon.rInfo.teamName
        this.cInfo.isStar = lwcommon.rInfo.isStar
        this.cInfo.endDate = lwcommon.rInfo.endDate
        this.cInfo.endType = lwcommon.rInfo.endType
        this.cInfo.endMemo = lwcommon.rInfo.endMemo

        //
        this.form.id = lwcommon.id
        this.form.caseId = lwcommon.rId

        this.form.type = lwcommon.type
        this.form.depth = lwcommon.depth
        this.form.pId = lwcommon.pId
        this.form.pType = lwcommon.pType
        this.form.rId = lwcommon.rId
        this.form.rType = lwcommon.rType
        this.form.subtype = lwcommon.subtype

        this.form.teamId = this.article.teamId // 팀 아이디
        this.form.clientId = this.article.id // 인명부 아이디
        this.form.clientType = this.article.type // 의뢰인/상대방 타입: 의뢰인 = 1, 상대방 = 2
        this.form.caseType = this.Type1 // 소송 = 1, 자문 = 2 : cases.type1 과 매칭

        // 인명부 정보 매칭
        this.form.clientInfo = {
          name: this.article.name,
          hp: this.article.hp,
          tel: this.article.tel,
          fax: this.article.fax,
          email: this.article.email
        }

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 선택한 소송/자문 상세화면으로 가기
    async goCaseDetail (cId) {
      try {
        if (this.Type1 === 1) { // 소송
          this.$router.push(`/case/${cId}`)
        } else { // 자문
          this.$router.push(`/advice/${cId}`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 연계 의뢰인/상대방 해제
    // 주의: 같이 등록한 연계 사건도 같이 삭제된다!
    async remove () {
      try {
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = this.form.clientType === 1 ? '해제시 개별사건계약은 자동삭제되며,<br>동 사건의 비용, 타임시트 정보를 의뢰인 페이지에서 볼 수 없습니다.' : '상대방 정보와의 연결을 해제합니다'
        if (await pop.open('해제', msg, { color: 'error', width: 420 })) {
          // 리턴객체
          let resolve = {
            isDeleted: false, // 부모가 삭제되었는지 여부
            teamError: false, // 부모의 팀에러
            chInfo: null, // 부모의 에러에 대한 메시지
            rsMsg: '' // resolve 메시지
          }
          const { data } = await this.$axios.post('lawork/case/deleteRelCaseClient/', this.form)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          // 리턴값으로 resolve 채우기
          resolve.isDeleted = data.isDeleted
          resolve.teamError = data.teamError
          resolve.chInfo = data.chInfo
          resolve.rsMsg = 'removed' // 메시지를 해제로 준다

          await this.initDialog() // 초기화
          this.resolve(resolve) // this.resolve(true)
          this.dialog = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 취소 버튼 이벤트 핸들러 - 닫는 역할만 한다
    async cancel () {
      try {
        let resolve = {
          isDeleted: false, // 부모가 삭제되었는지 여부
          teamError: false, // 부모의 팀에러
          chInfo: null, // 부모의 에러에 대한 메시지
          rsMsg: 'success' // resolve 메시지
        }
        // await this.initDialog() // 초기화
        this.resolve(resolve) // this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
