<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <v-card-text
        slot="content"
      >
        <v-container fluid class="pb-0">
          <v-form ref="form">
            <v-row
              align="center"
              justify="center"
              no-gutters
            >
              <v-col cols="12" xs="12">
                <!-- vue2editor -->
                <vue-editor
                  v-model="form.txt1"
                  :editor-toolbar="customToolbar"
                  style="height:550px;"
                  class="mb-12"
                ></vue-editor>
              </v-col>
              <!-- <v-col cols="12" class="py-0">
                <v-card
                  tile
                  elevation="0"
                  class="ma-0 pa-0"
                >
                  <v-card-actions class="mt-2 mx-0 pa-0">
                    <v-btn small text icon color="primary" disabled>
                      <v-icon small>print</v-icon>
                    </v-btn>
                    <v-btn small text icon color="primary" disabled>
                      <v-icon small>get_app</v-icon>
                    </v-btn>
                    <v-btn small text icon color="primary" disabled>
                      <v-icon small>phone_iphone</v-icon>
                    </v-btn>
                    <v-btn small text icon color="primary" disabled>
                      <v-icon small>local_post_office</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <span class="text-caption mr-2">{{ article.wname }}</span><span class="text-caption">{{ article.updatedAt }}</span>
                  </v-card-actions>
                </v-card>
              </v-col> -->
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

    </baseModal>
  </v-dialog>
</template>

<script>
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

// !! vue2editor
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    baseModal,
    VueEditor
  },

  props: [ 'article' ],

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 500,
      zIndex: 200
    },
    // 참고: for Form
    form: {
      id: 0,
      txt1: '',
      oriTeamId: 0
    },
    // 참고: 에디터 용
    customToolbar: [
      [ { 'header': [ false, 1, 2, 3, 4, 5, 6 ] } ],
      [ 'bold', 'italic', 'underline', 'strike' ],
      [ { 'header': 1 }, { 'header': 2 } ],
      [ { 'list': 'ordered' }, { 'list': 'bullet' } ],
      [ { 'color': [] }, { 'background': [] } ],
      [ 'link' ]
    ]
  }),

  mounted () {
    // this.$validator.localize('ko', this.dictionary)
  },

  methods: {
    dummy () {
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // 초기화 함수
    initDialog () {
      // form validation 초기화
      // this.$validator.reset()

      this.form.id = 0
      this.form.txt1 = ''
      this.form.oriTeamId = 0
    },
    open (title, options, index = -1) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        this.initDialog()

        this.form.id = this.article.id
        this.form.oriTeamId = this.article.teamId // 팀아이디 매칭

        // 중요: 여기서 내용을 매칭해야 제대로 보인다!!
        this.form.txt1 = this.article.txt1

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.ddㅁㅁ
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 등록 버튼
    async agree () {
      try {
        if (!this.form.id) throw new Error('정상적인 데이터가 아닙니다.')

        // 리턴객체
        let resolve = {
          isDeleted: false, // 부모가 삭제되었는지 여부
          teamError: false, // 부모의 팀에러
          chInfo: null, // 부모의 에러에 대한 메시지
          rsMsg: '' // resolve 메시지
        }

        const isChanged = await this.isChangedForm(this.form.txt1)
        if (isChanged) {
          // 변경된게 있으면 업데이트 한다.
          const { data } = await this.$axios.post('lawork/client/editClientAddInfo', this.form)
          if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
          // 리턴값으로 resolve 채우기
          resolve.isDeleted = data.isDeleted
          resolve.teamError = data.teamError
          resolve.chInfo = data.chInfo
          resolve.rsMsg = 'success'

          this.article.txt1 = this.form.txt1
        } else {
          // 변경이 없으면 .. 참고: 원래 경고창을 띄우려 했지만 제대로 캐치가 안되서 주석처리
          // throw new Error('변경된 내용이 없습니다.')
        }

        this.initDialog()
        this.resolve(resolve) // this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 취소 버튼
    cancel () {
      // 다이얼로그를 닫으면서 초기화 해야 한다!
      this.initDialog()
      this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
      this.dialog = false
    },
    // 참고: form 과 넘겨받은 article 중 달라진게 있는지 검사한다.
    async isChangedForm (value) {
      try {
        if (this.article.txt1 !== value) {
          return true
        } else {
          return false
        }
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
