// cutString.js
// 한글은 2바이트로 잘라준다
export default (val, len = 50) => {
  if (!val) return ''
  let s = 0
  for (let i = 0; i < val.length; i++) {
    s += (val.charCodeAt(i) > 128) ? 2 : 1
    if (s > len) return val.substring(0, i) + '...'
  }
  return val
}
