<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <v-btn
        v-if="index > -1"
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">삭제</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-btn
        slot="star-area"
        icon
        @click.native="form.isStar = !form.isStar"
      >
        <v-icon
          v-if="!form.isStar"
          color="grey lighten-1"
        >
          mdi-star-outline
        </v-icon>
        <v-icon
          v-else
          color="yellow"
        >
          mdi-star
        </v-icon>
      </v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <!-- <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto py-0 px-3"
        :max-height="options.height"
      >
        <v-card-text> -->

      <!-- 상단 툴바를 고정시키지 않으려면 아래 주석을 푼다. 하단의 태그 끝에도 주석을 풀어야 한다. -->
      <v-card-text
        slot="content"
        class="pb-0"
      >
        <v-container fluid class="pb-0"><!-- 여기까지 적용 -->

          <v-row no-gutters>
            <!-- 담당부서 -->
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="article.teamName"
                label="담당부서"
                readonly
                class="mr-2"
              >
                <template v-slot:prepend>
                  <v-icon
                    :color="article.teamColorOrigin"
                  >mdi-account-group</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <!-- 위임인 -->
            <v-col cols="12" xs="12" class="py-2">
              <v-text-field
                v-model.trim="article.name"
                label="위임인"
                prepend-icon="mdi-account-circle"
                readonly
                class="text-input-font-color__primary mr-2"
              >
                <template v-slot:prepend>
                  <v-icon color="primary">mdi-account-circle</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <!-- 계약번호 -->
            <v-col cols="12" xs="12" sm="6">
              <v-row
                no-gutters
                align="center"
                justify="start"
              >
                <div style="width:230px">
                  <v-text-field
                    ref="txtContractNum"
                    v-model.trim="form.gubun2"
                    label="계약번호"
                    placeholder="조직내부계약번호"
                    maxlength="30"
                    counter="30"
                    :error="isOverlabed"
                  ></v-text-field>
                </div>
                <v-btn small text class="ma-2"
                  :color="mustChk ? 'error' : 'indigo'"
                  @click="chkContractOverlap"
                >
                  <v-icon small class="text-left">
                    {{ chkMulti ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                  </v-icon>
                  중복체크
                </v-btn>
              </v-row>
            </v-col>
            <!-- 수임일 -->
            <v-col cols="12" xs="12" sm="6" class="pl-5">
              <v-menu
                ref="date1Selector"
                v-model="date1Selector"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model.trim="form.date1"
                    label="수임일"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.date1"
                  locale="ko"
                  @change="saveDate1"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12">
              <v-row no-gutter>
                <!-- 계약기간 -->
                <v-col cols="12" xs="12" sm="6">
                  <v-row no-gutters>
                    <v-col cols="12" xs="12" class="mt-0">
                      <span class="text-subtitle-2 mt-0 mb-2 pb-0 grey--text">계약기간</span>
                    </v-col>
                    <v-col cols="12" xs="12">
                      <v-row no-gutters justify="space-around">
                        <v-menu
                          ref="date2Selector"
                          v-model="date2Selector"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model.trim="form.date2"
                              label="시작일"
                              prepend-icon="event"
                              readonly
                              v-on="on"
                              hide-details
                              style="max-width: 160px;"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="form.date2"
                            locale="ko"
                            @change="saveDate2"
                          ></v-date-picker>
                        </v-menu>
                        <v-menu
                          ref="date3Selector"
                          v-model="date3Selector"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model.trim="form.date3"
                              label="만료일"
                              prepend-icon="event"
                              readonly
                              v-on="on"
                              hide-details
                              style="max-width: 160px;"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="form.date3"
                            locale="ko"
                            @change="saveDate3"
                          ></v-date-picker>
                        </v-menu>
                        <!-- <v-tooltip bottom color="primary">
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                              small class="mt-4 ml-n4" color="primary" style="cursor:pointer;"
                            >mdi-help-circle</v-icon>
                          </template>
                          <span class="text-body-2">최장 계약기간은 1년입니다.</span>
                        </v-tooltip> -->
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- 약정업무시간 -->
                <v-col cols="12" xs="12" sm="6">
                  <v-row no-gutters>
                    <v-col cols="12" xs="12" class="mt-0">
                      <span class="text-subtitle-2 mt-0 mb-2 pb-0 grey--text">약정업무시간</span>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" class="text-right">
                      <v-select
                        v-model="form.subtype2"
                        :items="selSubtype2"
                        dense
                        menu-props="auto"
                        style="width: 120px"
                        class="ml-8 pt-4"
                        hide-details
                      ></v-select>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6">
                      <v-text-field
                        ref="numStr4"
                        v-model.trim="form.str4"
                        maxlength="5"
                        suffix="h"
                        label="약정업무시간"
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <!-- <v-col cols="12" xs="12">
              <v-alert type="info" dense text class="text-body-2 mt-2 mb-0">
                기등록된 계약기간 및 정산방식은 수정할 수 없으므로 주의하십시오.
              </v-alert>
            </v-col> -->
            <!-- 특약사항 -->
            <v-col cols="12" xs="12">
              <v-textarea
                ref="refTxt2"
                v-model="form.txt2"
                outlined
                label="특약사항"
                placeholder="입력하세요"
                no-resize
                rows="7"
                @update:error="chkValidate = false"
                class="mt-5"
              ></v-textarea>
            </v-col>
            <v-col cols="12" xs="12">
              <v-alert
                border="left"
                outlined
                type="info"
                dense
                class="text-body-2"
              >
                {{ article.name }}
                <v-tooltip color="info" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      small color="primary lighten-2"
                      @click="gotoDetail"
                      class="ml-1"
                    >mdi-launch</v-icon>
                  </template>
                  <span>바로가기</span>
                </v-tooltip>
              </v-alert>
            </v-col>
            <!-- <v-col cols="12" v-show="index > -1" class="py-0">
              <v-card
                tile
                elevation="0"
                class="ma-0 pa-0"
              >
                <v-card-actions class="ma-0 pa-0">
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>print</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>get_app</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>phone_iphone</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>local_post_office</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <span class="text-caption mr-2">{{ viewWname }}</span><span class="text-caption">{{ viewUpdatedAt }}</span>
                </v-card-actions>
              </v-card>
            </v-col> -->
            <v-col cols="12">
              <div class="mb-8"></div>
            </v-col>
          </v-row>

        <!-- 상단 타이틀 고정을 풀때 사용 -->
        </v-container>
      </v-card-text>

        <!-- 상단 타이틀 고정시 사용 -->
        <!-- </v-card-text>
      </v-card> -->

    </baseModal>

  </v-dialog>
</template>

<script>
import ko from 'vee-validate/dist/locale/ko'
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

// @: filters
import numberFormat from '@/filters/numberFormat' // number-format
import numberFormatR from '@/filters/numberFormatR' // reverse number-format
import preventComma from '@/filters/preventComma' // keypress event 에서 쉼표막기
import strDateFormat from '@/filters/strDateFormat'
import strDateFormat3 from '@/filters/strDateFormat3'
import isNumber from '@/filters/isNumber'
// import isNumberDot from '@/filters/isNumberDot'
import isSpKey from '@/filters/isSpecialCharacter'

// @: dialogs

export default {
  components: {
    baseModal
  },

  props: [ 'article' ],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 아이콘 컬러
    mdiPlusBoxOutlineButtonColor: 'info darken-1',
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 770,
      height: 750,
      zIndex: 200
    },
    // 구분: Form 관련
    form: {
      id: 0,
      teamId: 1, // 기본팀이 초기값
      type: 25, // 유형(계약)
      depth: 2,
      pId: 0,
      pType: 3, // 인명부
      pInfo: '', // 정보위치(부모인 인명부의 정보 중 보여줄 정보 이름)
      rId: 0, // !![2021.3.29] 고문계약 위임인정보 추가 - pInfo 가 변경되어서 rInfo 에 저장한다.
      rType: 3,
      rInfo: '',
      isStar: 0,
      isSchedule: 0, // !! 계약은 일정표시 안됨
      subtype: 2, // 중요:[2021.6.1] 고문계약인 경우 2
      subtype2: 1, // [2021.6.1 신설] 초기값은 월별정산 - 월별정산(1)/일괄정산(2)
      gubun1: '고문계약', // !! 인명부의 경우 '고문계약'만 있다.
      gubun2: '', // !! 계약번호 - 계약에만 있다(전체계약과 비교해서 중복제거)
      status: 1, // !! 계약상태 - 유효=1 로 픽스
      date1: '', // 수임일
      date2: '', // 계약기간(시작일)
      date3: '', // 계약기간(종료일)
      // str1: '', // 특약사항 - !![2021.6.3]고문계약에서는 txt2를 사용
      str2: '0', // 수금대상(금액)
      str3: '0', // 계약금액(금액) - [2021.6.1 수정]
      str4: '0', // 약정업무시간(고문계약 전용)
      txt1: '[]', // !! 고문료,시간보수,비용청구 - json 타입 [2021.6.1 수정]
      txt2: '', // !![2021.6.3] 특약사항
      parentInfo: '' // [2021.3.23] 부모정보 문자열
    },
    // 구분: 약정업무시간 셀렉트 - form.subtype2
    selSubtype2: [
      { text: '월별정산', value: 1 },
      { text: '일괄정산', value: 2 }
    ],
    // 구분:
    index: -1, // !! 수정 인덱스
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        // str2: '수금대상' // 계약은 필수값이 없구나
      }
    },
    // 구분: 입력 검증
    rules: {
      txtCounter: v => v.length <= 200 || '200자 이하의 내용을 입력해 주세요'
    },
    // 구분: 특약사항(txt2) 유효성 체크
    chkValidate: true,
    // 구분: 계약번호 중복체크용 변수들
    mustChk: false, // 중복체크를 해야 하는지(계약번호에 입력된 경우)
    chkMulti: false, // 참고: 중복 확인했는지 여부
    isOverlabed: false, // 참고: 중복 되었는지 여부
    // 구분: 일자 - 시간
    date1Selector: false, // 수임일 셀렉터
    date2Selector: false, // 계약기간(시작일)
    date3Selector: false, // 계약기간(종료일)
    // 구분: 수정시 처리자와 최종업데이트 정보
    viewWname: '',
    viewUpdatedAt: ''
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  watch: {
    'form.gubun2': { // !! 계약번호 자동감지 - 관리번호가 입력되면 중복체크를 하도록 하는 로직의 핵심임
      handler: function (val, oldVal) {
        if (val.length > 0) { // 값이 있으면
          if (isSpKey(val)) { // 특수문자 필터링
            this.form.gubun2 = oldVal
            this.$refs.txtContractNum.lazyValue = oldVal
          }
          this.mustChk = true // 중복체크 해야 함
          this.chkMulti = false // 중복확인을 안했다
          this.isOverlabed = false // 중복 아니다.
        } else { // 내용이 아예 없으면 중복 체크하지 않아도 됨
          this.mustChk = false // 중복체크 안해도 됨
          this.chkMulti = false // 중복확인을 안했다
          this.isOverlabed = false // 중복 아니다.
        }
      }
    },
    // 'form.str3': { // 사용안함: 약정업무량 시간은 숫자와 . 만 오도록 처리해야 한다!
    //   handler: function (val, oldVal) {
    //     if (val.length > 0) { // 이게 없으면 무한루프 걸릴 수 있다.
    //       if (isNumberDot(val)) {
    //         this.form.str3 = oldVal
    //         this.$refs.numStr3.lazyValue = oldVal
    //       }
    //     }
    //   }
    // },
    // !![2021.6.1] 약정업무시간 으로 수정
    'form.str4': {
      handler: function (val, oldVal) {
        if (!val) {
          this.form.str4 = '0'
          this.$refs.numStr4.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.form.str4 = val.replace(/(^0+)/, '')
          }
          this.form.str4 = numberFormatR(this.form.str4)
          this.form.str4 = numberFormat(this.form.str4)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.form.str4)) {
            this.form.str4 = oldVal
            this.$refs.numStr4.lazyValue = oldVal
          }
        }
      }
    }
  },

  methods: {
    isSpKey, // 특수문자 필터
    isNumber, // 숫자만 인지 체크하는 필터
    numberFormat,
    numberFormatR,
    preventComma,
    strDateFormat,
    strDateFormat3,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 초기화
    // 주의: type, pType, rType, subtype, status, txt1 같은 변수는 픽스되어있기 때문에 다시 초기화하지 않는다.
    async initDialog () {
      // validation 초기화
      this.$validator.reset()

      this.form.id = 0
      this.form.teamId = 1 // 기본팀을 기본값으로
      this.form.pId = 0 // 부모 아이디
      this.form.pInfo = '' // 정보위치(부모인 인명부의 정보 중 보여줄 정보 이름)

      // !![2021.3.29] 고문계약 위임인 정보 추가
      // 중요: 편하자고 이렇게 쓰는것뿐임 - 고문계약에서 위임인은 부모(의뢰인) 자체지만 리스트에서 rInfo를 가져다 쓰므로 걍 이렇게 함
      this.form.rId = 0 // 부모 아이디
      this.form.rInfo = '' // 정보위치(부모인 인명부의 정보 중 보여줄 정보 이름)

      this.form.isStar = 0
      this.form.gubun2 = '' // !! 계약번호 - 계약에만 있다(전체계약과 비교해서 중복제거)

      // !! 일자 초기화 - clone() 으로 복사해서 써야한다!
      const curDate = this.$moment() // 오늘
      const startDate = curDate.clone() // 수임일 초기값(오늘) - 복사
      const endDate = curDate.clone().add(1, 'years').subtract(1, 'days') // 계약기간(종료일) = 초일불산입(시작일 + 1년 - 1일)

      this.form.date1 = curDate.format('YYYY-MM-DD') // 수임일 초기값(오늘)
      this.form.date2 = startDate.format('YYYY-MM-DD') // 계약기간(시작일)
      this.form.date3 = endDate.format('YYYY-MM-DD') // 계약기간(종료일)

      this.form.txt2 = '' // 특약사항
      this.form.str4 = '0' // 약정업무시간(금액과 동일하게 처리)

      this.form.parentInfo = ''

      this.index = -1 // 수정용 인덱스 초기화

      // 중요: 폼값 뿐만이 아니라 중복체크와 상태값에 따른 초기화도 같이해야 한다.
      // !! 중복체크 초기화
      this.mustChk = false // 계약번호 중복체크를 해야 하는지 여부
      this.chkMulti = false // 계약번호 중복 확인했는지 여부
      this.isOverlabed = false // 계약번호 중복 되었는지 여부
    },
    // 구분:
    async open (title, options, index = -1, obj = {}) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        // console.log(this.article) // !! props 로 넘어온 부모 데이터
        // 초기화
        await this.initDialog()

        // !! 아래 3개는 부모로부터 넘겨받은 데이터로 매칭하자(뭐 이렇게 하지 않아도 되는데)
        this.form.teamId = this.article.teamId
        this.form.pId = this.article.id
        this.form.parentInfo = this.article.name

        // 중요:[2021.3.19] 부모정보의 객체를 문자열로 변환해서 this.form.pInfo 에 담는다.
        this.form.pInfo = JSON.stringify(this.article.pInfo) // this.article.name

        // !![2021.3.29] 고문계약 위임인정보
        // 중요: 편하자고 이렇게 쓰는것뿐임 - 고문계약에서 위임인은 부모(의뢰인) 자체지만 리스트에서 rInfo를 가져다 쓰므로 걍 이렇게 함
        this.form.rId = this.article.id
        this.form.rInfo = this.form.pInfo // !![2021.6.1]객체형식으로 저장하는 것으로 수정됨. this.article.name

        // 참고: 편집 모드인 경우 - 넘어온 객체를 this.form 에 복사
        if (index > -1) {
          // console.log(obj) // 파라미터로 넘어온 업무
          this.index = index

          this.form.id = obj.id

          this.form.isStar = obj.isStar
          this.form.gubun2 = obj.gubun2 // 계약번호 - !! 여기서 watch 가 발동될것임

          // 일자 처리
          const curDate = this.$moment() // 오늘
          const startDate = curDate.clone() // 수임일 초기값(오늘) - 복사
          const endDate = curDate.clone().add(1, 'years').subtract(1, 'days') // 계약기간(종료일) = 초일불산입(시작일 + 1년 - 1일)

          this.form.date1 = (obj.date1) ? obj.date1.substr(0, 10) : curDate.format('YYYY-MM-DD') // 수임일
          this.form.date2 = (obj.date2) ? obj.date2.substr(0, 10) : startDate.format('YYYY-MM-DD') // 계약기간(시작일)
          this.form.date3 = (obj.date3) ? obj.date3.substr(0, 10) : endDate.format('YYYY-MM-DD') // 계약기간(만료일)

          this.form.txt2 = obj.txt2 // 특약사항
          this.form.str4 = obj.str4 // 약정업무시간(금액과 동일하게 처리)

          this.form.subtype2 = obj.subtype2 // 약정업무시간 셀렉트값

          // 참고: 편집일때는 처리자와 업데이트 일자를 보여준다.
          this.viewWname = obj.wname
          this.viewUpdatedAt = strDateFormat(obj.updatedAt) // 필터로 쓰진 않고 함수로 사용

          // !! 관리번호가 등록되어있는 경우 - 시간상 지연을 주기위해 nextTick()을 사용해야 한다!
          this.$nextTick(function () {
            if (obj.gubun2) {
              this.mustChk = false // 중복체크 필요없다
              this.chkMulti = true // 중복체크를 했다
              this.isOverlabed = false // 중복되지 않았다
            } else {
              this.mustChk = false
              this.chkMulti = false
              this.isOverlabed = false
            }
          })
        }

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 등록
    async agree () {
      try {
        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        // 사용안함: 수임내용 textarea 의 글자수가 200 이하로 조정되면 여기서 체크해서 처리한다.
        // if (this.rules.txtCounter(this.$refs.refTxt2.value) === true) {
        //   this.chkValidate = true
        // }
        // if (!this.chkValidate) throw new Error('저장할 수 없습니다. 내용 항목의 글자수를 줄여주세요.')

        // !! 계약번호 중복체크 확인
        if (this.mustChk && !this.chkMulti) { // 중복체크를 해야 하는데 하지 않았다
          this.isOverlabed = true // 중복여부를 가져오진 않았지만 수동으로 에러를 만든다.
          this.$refs.txtContractNum.focus()
          throw new Error('중복체크를 하셔야 합니다.')
        }

        // 일자 처리 - 시분초를 붙여준다.
        this.form.date1 = `${this.form.date1.substr(0, 10)} 00:17:01` // !![2021.4.8] 계약일은 00:17:01 로 수정함
        this.form.date2 = `${this.form.date2.substr(0, 10)} 00:00:01`
        this.form.date3 = `${this.form.date3.substr(0, 10)} 00:00:01`

        // 리턴객체
        let resolve = {
          isDeleted: false, // 부모가 삭제되었는지 여부
          teamError: false, // 부모의 팀에러
          chInfo: null, // 부모의 에러에 대한 메시지
          rsMsg: '' // resolve 메시지
        }

        // !! DB 입력 처리 (등록/수정 동일하게 처리)
        // const { data } = await this.$axios.post(`lawork/client/setRelContract`, this.form)
        const { data } = await this.$axios.post(`lawork/client/setGomunContract`, this.form)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        // 리턴값으로 resolve 채우기
        resolve.isDeleted = data.isDeleted
        resolve.teamError = data.teamError
        resolve.chInfo = data.chInfo
        resolve.rsMsg = 'success'

        // 등록 후
        await this.initDialog()
        this.resolve(resolve) // this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 취소
    async cancel () {
      try {
        await this.initDialog() // 초기화
        this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 삭제
    async remove () {
      try {
        if (!this.form.id) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          // 리턴객체
          let resolve = {
            isDeleted: false, // 부모가 삭제되었는지 여부
            teamError: false, // 부모의 팀에러
            chInfo: null, // 부모의 에러에 대한 메시지
            rsMsg: '' // resolve 메시지
          }
          // const { data } = await this.$axios.get(`lawork/client/deleteContract/${this.form.id}/${this.form.pId}`)
          const params = {
            id: this.form.id,
            teamId: this.form.teamId,
            type: this.form.type,
            depth: this.form.depth,
            pId: this.form.pId,
            pType: this.form.pType,
            pInfo: this.form.parentInfo,
            rInfo: this.form.rInfo // *[2022.5.30 추가]
          }
          // const { data } = await this.$axios.post(`lawork/client/removeLwc`, params)
          const { data } = await this.$axios.post(`lawork/client/removeGomunContract`, params)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          // 리턴값으로 resolve 채우기
          resolve.isDeleted = data.isDeleted
          resolve.teamError = data.teamError
          resolve.chInfo = data.chInfo
          resolve.rsMsg = 'removed'

          await this.initDialog() // 초기화
          this.resolve(resolve) // this.resolve(true)
          this.dialog = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 계약번호 중복체크 함수
    // !! 모든 팀을 대상으로 중복체크를 해야한다
    // !! 중복인 경우 등록이 불가능 하다
    async chkContractOverlap () {
      try {
        // !! this.mustChk = false 면 중복체크가 필요없으므로 DB 쿼리를 하지 않는다.
        if (this.form.gubun2.length > 0 && this.mustChk) {
          const { data } = await this.$axios.get(`lawork/client/chkContractNumOverlap/${this.form.gubun2}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

          if (data.chk) { // 중복 되었다
            this.mustChk = true // 중복되었으니 다시 체크해야 한다
            this.chkMulti = false // 중복확인 여부 > 중복체크를 다시 해야 한다
            this.isOverlabed = true // 중복되었다.
            this.$refs.txtContractNum.focus()
            // throw new Error(`이미 등록된 계약번호 입니다.`)
            const _msg_ = '이미 등록된 계약번호 입니다.'
            this.$store.commit('SB_POP', { msg: _msg_, color: 'warning', shaped: true })
          } else { // 중복되지 않았다
            this.mustChk = false // 중복체크 할 필요없다.
            this.chkMulti = true // 중복확인 여부 > 중복체크를 하지 않아도 된다.
            this.isOverlabed = false // 중복되지 않았다
            this.$store.commit('SB_POP', { msg: '등록할 수 있는 계약번호입니다.', color: 'success', timeout: 1000 })
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 일자 셀렉트 선택시 저장 함수
    saveDate1 (date) { // 수임일
      this.$refs.date1Selector.save(date)
    },
    saveDate2 (date) { // 시작일
      this.$refs.date2Selector.save(date)
    },
    saveDate3 (date) { // 만료일
      this.$refs.date3Selector.save(date)
    },
    // !! 인명부 상세페이지 바로가기
    gotoDetail () {
      this.dialog = false
      // !! 현재 경로가 client 디렉토리가 아닌 경우만 client/ 로 이동시킨다
      if (!(this.$router.history.current.path.indexOf('client') !== -1)) {
        this.$router.push(`/client/${this.article.id}`)
      }
    }
  }
}
</script>

<style>
/** 인명부 이름색을 지정한다 */
.text-input-font-color__primary .v-text-field__slot input {
  color: #1976D2 !important;
}
</style>
