<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      :outlined="expand"
      elevation="0"
    >
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="light-blue"
      ></v-progress-linear>
      <v-alert
        dense
        color="grey lighten-5"
        class="text-body-2 ma-0 px-2 py-1"
        @click.stop="toogleExpand"
        style="cursor: pointer;"
      >
        <v-row no-gutters>
          <v-icon :color="hover ? 'orange' : 'grey'" class="mr-1">
            {{ expand ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
          <span :class="hover ? 'blue--text text--darken-3' : 'grey--text text--darken-2'">
            하위자문사건목록(<span :class="hover ? 'orange--text text--darken-1' : 'primary--text'">{{ pData.cnt }}</span>)
          </span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-expand-x-transition>
                <v-btn
                  v-show="expand"
                  v-on="on"
                  x-small icon class="mx-2 ma-0 pa-0" color="error"
                  @click.stop="addGAdviceWithContract"
                >
                  <v-icon small class="ma-0 pa-0">mdi-plus-box</v-icon>
                </v-btn>
              </v-expand-x-transition>
            </template>
            <span>하위자문사건등록</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <!-- <v-expand-transition>
            <v-btn
              v-show="expand"
              x-small depressed color="info"
              class="withe--text"
              @click.stop="addGAdviceWithContract"
            >
              <v-icon small left class="mr-1">mdi-plus-box</v-icon>
              <span
                style="font-size: 0.75rem !important:font-weight: 500;"
              >자문등록</span>
            </v-btn>
          </v-expand-transition> -->
        </v-row>
      </v-alert>
      <v-expand-transition>
        <div v-show="expand">
          <v-divider></v-divider>
          <v-card
            elevation="0"
            min-height="70"
            max-height="240"
            class="overflow-y-auto ma-0 px-0 pt-0 pb-2"
          >
            <template v-for="(item, i) in items">
              <v-row
                :key="i"
                no-gutters
              >
                <v-col cols="12" class="my-1">
                  <v-card elevation="0">
                    <v-card-text class="py-0 text--primary">
                      <div class="px-1">
                        <v-icon
                          v-show="item.isStar"
                          x-small
                          class="mr-1 pb-1"
                          color="amber darken-1"
                        >mdi-star</v-icon>
                        <!-- !![2021.5.27] 종국인 경우 아이콘 -->
                        <v-icon
                          v-show="item.endDate && item.endType"
                          x-small
                          color="warning"
                          class="mr-1 pb-1"
                        >mdi-gavel</v-icon>
                        <v-chip
                          label outlined x-small class="mx-1 px-1"
                          :color="item.status2 === '진행' ? 'primary' : 'blue-grey darken-2'"
                          style="font-size: 0.70rem;font-weight: 600;letter-spacing: 0.08em"
                        >{{ item.status2 }}</v-chip>
                        <span class="text-body-2 grey--text text--darken-2" style="font-size: 0.8rem;font-weight: 400;">{{ item.caseName }}</span>
                        <template v-if="!isMyTeam(item.teamId)">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <span v-on="on" class="grey--text" style="font-size: 0.825rem;font-weight: 450;">
                                {{ item.manageNum }}
                              </span>
                            </template>
                            <span>접근권한이 없습니다. ({{ getTeamNameByTid(item.teamId) }} 데이터 입니다.)</span>
                          </v-tooltip>
                        </template>
                        <template v-else>
                          <span class="text--primary" style="font-size: 0.825rem;font-weight: 450;">
                            {{ item.manageNum }}
                          </span>
                        </template>
                        <span class="primary--text" style="font-size: 0.825rem;">
                          {{ item.opName ? `${item.opType ? `[${item.opType}]` : ''}${item.opName}` : '' }}
                        </span>
                        <span
                          v-show="item.endDate && item.endType"
                          class="ml-1 text-body-2 error--text font-weight-medium">
                          {{ strDateFormat(item.endDate) }} {{ item.endType }}
                        </span>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-show="isMyTeam(item.teamId)"
                              v-on="on"
                              small
                              color="info darken-1"
                              class="ml-1 mb-1"
                              @click.stop="gotoDetail(item.id)"
                            >mdi-launch</v-icon>
                          </template>
                          <span>바로가기</span>
                        </v-tooltip>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>
            <v-row
              v-if="items.length <= 0"
              no-gutters
            >
              <v-col cols="12" xs="12" sm="12">
                <v-card
                  elevation="0"
                  class="ma-1"
                >
                  <v-card-text class="text--center pa-3">
                    <p class="text-subtitle-2 grey--text">
                      데이터가 없습니다. 먼저 하위자문사건등록 버튼을 눌러 등록하셔야 합니다.
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-expand-transition>
    </v-card>
  </v-hover>
</template>

<script>
// @: filters
import strDateFormat from '@/filters/strDateFormat'

// !![2021.6.8]lwc vuex 모듈 불러오기
import { createNamespacedHelpers } from 'vuex'

// !! lwc vuex 모듈
const _Lwc_ = createNamespacedHelpers('lwc')

export default {
  props: [ 'pData', 'contract' ],

  data: () => ({
    expand: false,
    items: [],
    loading: false
  }),

  computed: {
    // 중요: lwc 모듈 게터
    ..._Lwc_.mapGetters([
      'isMyTeam',
      'getTeamNameByTid'
    ])
  },

  methods: {
    strDateFormat,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 고문자문 리스트 영역 펼치기
    async toogleExpand () {
      try {
        if (this.pData.cnt > 0 && !this.expand) {
          this.loading = true

          const { data } = await this.$axios.post('lawork/advice/getGomunAdvices', this.pData)
          if (!data.success) throw new Error(`list error: ${data.message}`)
          this.items = data.list

          setTimeout(() => {
            this.loading = false
          }, 500 - Math.floor(Math.random() * 200))
        }
        this.$nextTick(() => {
          this.expand = !this.expand
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 고문자문사건 등록 - 부모에게 토스한다.
    async addGAdviceWithContract () {
      try {
        // 부모 view.vue 페이지에서 고문자문등록 후 리프레시
        this.expand = false
        this.$emit('parentAddGomun', this.contract)
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 상세페이지 바로가기
    gotoDetail (id) {
      this.$router.push(`/advice/${id}`)
    }
  }
}
</script>
