<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      :outlined="expand"
      elevation="0"
    >
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="light-blue"
      ></v-progress-linear>
      <v-alert
        dense
        color="grey lighten-5"
        class="text-body-2 ma-0 px-2 py-1"
        @click.stop="toogleExpand"
        style="cursor: pointer;"
      >
        <v-icon :color="hover ? 'orange' : 'grey'" class="mr-1">
          {{ expand ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
        <span
          :class="hover ? 'blue--text text--darken-3' : 'grey--text text--darken-2'"
        >
          계약금액 <span :class="hover ? 'orange--text text--darken-1' : 'primary--text'">{{ numberFormat(totalSum1) }}</span>원 /
          수금대상 <span :class="hover ? 'orange--text text--darken-1' : 'primary--text'">{{ numberFormat(totalSum2) }}</span>원
        </span>
      </v-alert>
      <v-expand-transition>
        <div v-show="expand">
          <v-row no-gutters>
            <v-col cols="2">
              <v-card
                elevation="2"
                color="grey lighten-2"
                class="pa-1 text-center"
              >
                <v-icon small class="mb-0" color="blue-grey darken-2">
                  mdi-calendar-month
                </v-icon>
                기간
              </v-card>
            </v-col>
            <v-col cols="8">
              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <v-card
                    elevation="2"
                    tile color="grey lighten-2"
                    class="pa-1 text-center"
                  >
                    고문료
                  </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card
                    elevation="2"
                    tile color="grey lighten-2"
                    class="pa-1 text-center"
                  >
                    시간보수
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          small class="ml-2 mb-1" color="indigo"
                          @click.stop="pdfgenTimeSheet"
                        >mdi-file-pdf</v-icon>
                      </template>
                      <span>pdf</span>
                    </v-tooltip>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card
                    elevation="2"
                    tile color="grey lighten-2"
                    class="pa-1 text-center"
                  >
                    비용청구
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          small class="ml-2 mb-1" color="indigo"
                          @click.stop="pdfgenCost"
                        >mdi-file-pdf</v-icon>
                      </template>
                      <span>pdf</span>
                    </v-tooltip>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2">
              <v-card
                elevation="2"
                tile color="grey lighten-2"
                class="pa-1 text-center"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-card>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card
            elevation="0"
            min-height="70"
            max-height="240"
            class="overflow-y-auto ma-0 pa-0"
          >
            <v-overlay
              absolute
              :value="overlay"
            >
              <v-progress-circular
                color="purple"
                indeterminate
                size="50"
              ></v-progress-circular>
            </v-overlay>
            <template
              v-for="(item, n) in amounts"
            >
              <v-row
                :key="n"
                no-gutters
                class="ma-0 pa-0"
              >
                <!-- 중요:등록 컴포넌트 -->
                <input-contract-amount
                  :amts="item"
                  :order="n"
                  @regRow="regRow"
                  @rmRow="rmRow"
                ></input-contract-amount>
              </v-row>
            </template>
            <v-row no-gutters>
              <v-col cols="12" sm="12">
                <v-card
                  class="pa-2 text-center"
                  elevation="0"
                >
                  <v-btn
                    block depressed color="info"
                    class="white--text"
                    @click.stop="addRow"
                  >
                    <v-icon small>mdi-table-row-plus-after</v-icon>
                    추가
                  </v-btn>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-expand-transition>
    </v-card>
  </v-hover>
</template>

<script>
// 구분: 고문계약 고문료,,, 등등 등록 컴포넌트
import inputContractAmount from '@/components/client/inputContractAmount'

// @: filters
import numberFormat from '@/filters/numberFormat' // number-format
import strDateFormat from '@/filters/strDateFormat'

// @: pdfmake list
import { pdfMake, pdfListStyle } from '@/lib/pdfmakeList'

export default {
  components: {
    inputContractAmount
  },

  props: [ 'cData' ],

  data: () => ({
    expand: false,
    // 구분: 입력되는 기간, 고문료, 시간보수, 비용청구
    amounts: [
      // { amt1: 0, amt2: 0, amt3: 0, amt4: 0 } // !!주의: 초기값을 주면 안되네?
    ],
    loading: false,
    overlay: false,
    // 구분: [2021.6.11] computed 에서 여기로 옮김
    totalSum1: 0,
    totalSum2: 0,
    // 구분: 타임시트와 비용배열
    timeSheets: [],
    costs: [],
    // 구분: 타임시트와 비용 합계 구하는 용도의 변수
    timeSheetSum: 0, // 타임시트 합계
    timeMinutes: '', // 타임시트 - 시간:분 합산
    costSum: 0, // 비용 합계
    // 구분: pdf 리스트
    pdfLst: {
      styles: null,
      defaultStyle: null,
      // pageOrientation: 'landscape', // !! 리스트는 가로로
      pageSize: 'A4',
      pageMargins: [ 20, 20, 20, 20 ],
      content: []
    }
  }),

  computed: {
    // 사용안함:!![2021.6.11] 폐기 - 자동계산이 상황에 따라 맞지 않는다.
    // totalSum1 () { // 총계약금액 - 고문료+시간보수
    //   let rv = 0
    //   for (const item of this.amounts) {
    //     rv += item.amt1 + item.amt2
    //   }
    //   return rv
    // },
    // totalSum2 () { // 총수금대상 - 계약금액+비용
    //   let rv = 0
    //   for (const item of this.amounts) {
    //     rv += item.amt1 + item.amt2 + item.amt3
    //   }
    //   return rv
    // }
  },

  mounted () {
    // !![2021.6.11]
    this.totalSum1 = Number(this.cData.str3) // 계약금액
    this.totalSum2 = Number(this.cData.str2) // 수금대상
  },

  methods: {
    numberFormat,
    strDateFormat,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 펼치기
    async toogleExpand () {
      try {
        if (!this.expand) this.loading = true

        // 넘어온 등록값배열을 JSON 파싱
        this.amounts = JSON.parse(this.cData.amounts)

        // 비어있으면 초기화 시켜야 한다.
        if (this.amounts.length <= 0) {
          this.amounts = [
            { date: this.$moment().format('YYYY-MM'), amt1: 0, amt2: 0, amt3: 0 }
          ]
        }

        // 시간보수, 비용 초기화
        this.timeSheetSum = 0
        this.costSum = 0
        this.timeMinutes = '00:00'

        // !! 고문계약에 매칭된 고문자문에 묶인 타임시트와 비용을 패칭한다
        const params = {
          clientId: this.cData.clientId,
          adviceIds: this.cData.adviceIds
        }
        const { data } = await this.$axios.post('lawork/client/getGomunTmCost', params)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

        // pdf 를 위해 배열에 데이터 매칭
        this.timeSheets = data.timeSheets
        this.costs = data.costs

        // !! 타임시트 금액 합산 & 시:분 합산 작업
        let _tts = 0
        let _mms = 0
        this.timeSheets.forEach(c => {
          this.timeSheetSum += Number(c.str2)

          const tmp = c.str5.split(':') // 시간,분으로 나눈다.
          _tts += Number(tmp[0])
          _mms += Number(tmp[1])
        })

        // 타임시트의 시간:분 합산 작업
        _tts = `${_tts + parseInt(_mms / 60)}`
        _mms = `${_mms % 60}`
        if (_tts.length === 1) _tts = `0${_tts}`
        if (_mms.length === 1) _mms = `0${_mms}`
        this.timeMinutes = `${_tts}:${_mms}`

        // !! 비용 금액합산 작업
        this.costs.forEach(c => {
          this.costSum += Number(c.str2)
        })

        setTimeout(() => {
          this.loading = false
        }, 500 - Math.floor(Math.random() * 200))

        this.$nextTick(() => {
          this.expand = !this.expand
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 1row 를 추가한다.
    async addRow () {
      try {
        // 배열에 추가
        this.amounts.push({ date: this.$moment().format('YYYY-MM'), amt1: 0, amt2: 0, amt3: 0 })

        // const options = {
        //   duration: 300,
        //   offset: 200,
        //   easing: 'easeInOutCubic'
        // }
        // this.$vuetify.goTo(2500, options)

        // !! 추가 아이콘으로 스크롤을 이동시키는 코드 -- 안되는데? 당연히 #btnIcon 이 복수다
        // this.$nextTick(() => {
        //   const el = this.$el.querySelector('#btnIcon')
        //   el.scrollIntoView()
        // })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: this.amounts 에 요소를 넣는다. 실제 DB에 저장하는 작업
    async regRow () {
      try {
        this.overlay = true

        // 계약금액/수금대상 계산
        let calcSum1 = 0 // 계약금액
        let calcSum2 = 0 // 수금대상
        for (const item of this.amounts) {
          const tmpSum = Number(item.amt1) + Number(item.amt2)
          calcSum1 += tmpSum
          calcSum2 += tmpSum + Number(item.amt3)
        }

        const params = {
          gomunId: this.cData.gomunId,
          amounts: this.amounts,
          str2: calcSum2, // 수금대상
          str3: calcSum1 // 계약금액
        }
        const { data } = await this.$axios.post(`lawork/client/registGomunAmount`, params)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

        this.totalSum1 = calcSum1 // 계약금액
        this.totalSum2 = calcSum2 // 수금대상

        setTimeout(() => {
          this.overlay = false
        }, 500 - Math.floor(Math.random() * 200))

        // 부모 리프레시
        this.$emit('parentRefresh')
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: this.amounts 의 마지막 요소를 삭제한다. 1row 는 남긴다.
    async rmRow (order) {
      try {
        // 1 row 만 있으면 삭제하지 않는다.
        if (this.amounts.length === 1) return

        const rmRow = this.amounts[order] // 지워야할 현재 row 복사본

        this.overlay = true
        const tmpAmounts = [ ...this.amounts ] // 복사본을 사용한다.
        this.amounts = [{ date: '', amt1: 0, amt2: 0, amt3: 0 }] // 초기화하고

        // 계약금액/수금대상 계산
        const tmpSum = Number(rmRow.amt1) + Number(rmRow.amt2)
        this.totalSum1 = this.totalSum1 - tmpSum // 계약금액
        this.totalSum2 = this.totalSum2 - tmpSum - Number(rmRow.amt3) // 수금대상

        const params = {
          gomunId: this.cData.gomunId,
          amounts: tmpAmounts,
          order,
          str2: this.totalSum2, // 수금대상(금액)
          str3: this.totalSum1 // 계약금액(금액)
        }
        const { data } = await this.$axios.post(`lawork/client/removeGomunAmount`, params)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

        this.$nextTick(() => {
          this.amounts = data.amounts
        })

        setTimeout(() => {
          this.overlay = false
        }, 500 - Math.floor(Math.random() * 200))

        // 부모 리프레시
        this.$emit('parentRefresh')
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: pdf 리스트 제너레이터 - 타임시트
    // 중요: /src/lib/pdfmakeList.js 파일에서 공통부분을 처리했고 아래 함수에서는 가변적인 부분만 적용하면된다
    async pdfgenTimeSheet () {
      try {
        // !! 헤더 타이틀
        const pdfHeaderTitle = `시간보수 > ${this.cData.clientName} - ${this.cData.contractNum}`

        // !! pdf 파일정보 - 제목만 넣는다 작성자(author)는 제외
        this.pdfLst.info = {
          title: pdfHeaderTitle,
          subject: pdfHeaderTitle
        }
        // !! 공통스타일 적용 - 따로 하려면 따로 지정하면 된다.
        this.pdfLst.styles = pdfListStyle
        this.pdfLst.defaultStyle = { font: 'Nanum' }

        // !! table body array
        let tBody = []

        // !! 헤더 row 징의
        tBody.push([
          { text: '일자', style: 'tableHeader', alignment: 'center' },
          { text: '업무자', style: 'tableHeader', alignment: 'center' },
          { text: '시간당보수', style: 'tableHeader', alignment: 'center' },
          { text: '업무시간', style: 'tableHeader', alignment: 'center' },
          { text: '시간보수', style: 'tableHeader', alignment: 'center' },
          { text: '사건번호', style: 'tableHeader', alignment: 'center' }
        ])

        this.timeSheets.forEach(d => {
          // 주의: 아래 push 에 넣는 배열항목을 제거하거나 늘린 후 아래 content 의 table.widths 배열도 맞춰야 함
          const pInfo = JSON.parse(d.pInfo)
          tBody.push([
            `${this.$moment(d.date1).format('YYYY.MM.DD')}`,
            `${d.str3}`,
            `${numberFormat(d.str4)}`,
            `${d.str5}`,
            `${numberFormat(d.str2)}`,
            `${pInfo.manageNum}`
          ])
        })
        // 중요:!! colSpan 을 해주더라도 원래 컬럼갯수만큼 빈칸을 넣어줘야 한다
        tBody.push([{ colSpan: 3, text: '합계', alignment: 'center' }, '', '', `${this.timeMinutes}`, `${numberFormat(this.timeSheetSum)}`, `${this.timeSheets.length}`])

        let content = [
          { text: pdfHeaderTitle, style: 'header' },
          { text: `${this.$moment().format('YYYY.MM.DD HH:mm')}`, style: 'subheader' },
          {
            style: 'tableBody',
            // layout: 'lightHorizontalLines',
            table: {
              widths: ['*', '*', '*', '*', '*', '*'],
              // headerRows: 1, // 다음 페이지로 넘길때 table row 를 반복한다.
              dontBreakRows: true, // !! 다음 페이지로 넘어걸때 row 를 분리하지 않는다.
              body: tBody
            }
          }
        ]
        this.pdfLst.content = content

        // !! 주어진 설정과 함께 pdf 파일을 다른탭에 열기
        pdfMake.createPdf(this.pdfLst).open()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: pdf 리스트 제너레이터 - 비용
    // 중요: /src/lib/pdfmakeList.js 파일에서 공통부분을 처리했고 아래 함수에서는 가변적인 부분만 적용하면된다
    async pdfgenCost () {
      try {
        // !! 헤더 타이틀
        const pdfHeaderTitle = `비용청구 > ${this.cData.clientName} - ${this.cData.contractNum}`

        // !! pdf 파일정보 - 제목만 넣는다 작성자(author)는 제외
        this.pdfLst.info = {
          title: pdfHeaderTitle,
          subject: pdfHeaderTitle
        }
        // !! 공통스타일 적용 - 따로 하려면 따로 지정하면 된다.
        this.pdfLst.styles = pdfListStyle
        this.pdfLst.defaultStyle = { font: 'Nanum' }

        // !! table body array
        let tBody = []

        // !! 헤더 row 징의
        tBody.push([
          { text: '일자', style: 'tableHeader', alignment: 'center' },
          { text: '항목', style: 'tableHeader', alignment: 'center' },
          { text: '납부처', style: 'tableHeader', alignment: 'center' },
          { text: '금액', style: 'tableHeader', alignment: 'center' },
          { text: '사건번호', style: 'tableHeader', alignment: 'center' }
        ])

        this.costs.forEach(d => {
          // 주의: 아래 push 에 넣는 배열항목을 제거하거나 늘린 후 아래 content 의 table.widths 배열도 맞춰야 함
          const pInfo = JSON.parse(d.pInfo)
          tBody.push([
            `${this.$moment(d.date1).format('YYYY.MM.DD')}`,
            `${d.gubun1}`,
            `${d.str3}`,
            `${numberFormat(d.str2)}`,
            `${pInfo.manageNum}`
          ])
        })
        // 중요:!! colSpan 을 해주더라도 원래 컬럼갯수만큼 빈칸을 넣어줘야 한다
        tBody.push([{ colSpan: 3, text: '합계', alignment: 'center' }, '', '', `${numberFormat(this.costSum)}`, `${this.costs.length}`])

        let content = [
          { text: pdfHeaderTitle, style: 'header' },
          { text: `${this.$moment().format('YYYY.MM.DD HH:mm')}`, style: 'subheader' },
          {
            style: 'tableBody',
            // layout: 'lightHorizontalLines',
            table: {
              widths: ['*', '*', '*', '*', '*'],
              // headerRows: 1, // 다음 페이지로 넘길때 table row 를 반복한다.
              dontBreakRows: true, // !! 다음 페이지로 넘어걸때 row 를 분리하지 않는다.
              body: tBody
            }
          }
        ]
        this.pdfLst.content = content

        // !! 주어진 설정과 함께 pdf 파일을 다른탭에 열기
        pdfMake.createPdf(this.pdfLst).open()
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
